<ng-container *ngIf="!loading">
  <div class="header" *ngIf="mayViewEvents()">
    <section class="status-picker-wrapper">
      <div class="status-picker">
        <div
          class="status-picker-container"
          (click)="activePageChange('overview')"
          [class.activeStatus]="activePage === 'overview'"
        >
          <span i18n="@@viewRefund.tabs.overview">Overview</span>
        </div>
        <div
          class="status-picker-container"
          (click)="activePageChange('events')"
          [class.activeStatus]="activePage === 'events'"
        >
          <span i18n="@@viewRefund.tabs.events">Events</span>
        </div>
      </div>
    </section>
  </div>

  <div
    *ngIf="activePage === 'overview'"
    class="refund-container spacing-container"
  >
    <div>
      <div class="info-box">
        <div class="info-heading">
          <span i18n="@@viewRefund.infoBox.reported">Reported refund:</span
          ><span class="info-text cerise-text">{{
            refundValue | value: localeService.getCurrentLocale()
          }}</span>
        </div>
        <div class="info-heading">
          <span i18n="@@viewRefund.infoBox.reason">Stated reason:</span
          ><span class="info-text reason-text">{{
            getReasonText(refund.reason)
          }}</span>
        </div>
        <ng-container [ngSwitch]="refund.status">
          <div class="info-heading" *ngSwitchCase="'PENDING_MERCHANT_APPROVAL'">
            <span i18n="@@viewRefund.infoBox.initiatedBy">Initiated by:</span
            ><a
              class="info-text link"
              href="mailto:{{ refund.initializingRepresentativeEmail }}"
              >{{ refund.initializingRepresentativeName }}</a
            >
          </div>
          <div class="info-heading" *ngSwitchCase="'PENDING_EXECUTION'">
            <span i18n="@@viewRefund.infoBox.approvedBy">Approved by:</span
            ><a
              class="info-text link"
              href="mailto:{{ refund.approvingRepresentativeEmail }}"
              >{{ refund.approvingRepresentativeName }}</a
            >
          </div>
          <div class="info-heading" *ngSwitchCase="'EXECUTED'">
            <span i18n="@@viewRefund.infoBox.approvedBy">Approved by:</span
            ><a
              class="info-text link"
              href="mailto:{{ refund.approvingRepresentativeEmail }}"
              >{{ refund.approvingRepresentativeName }}</a
            >
          </div>
          <div class="info-heading" *ngSwitchCase="'CANCELLED'">
            <span i18n="@@viewRefund.infoBox.approvedBy">Approved by:</span
            ><a
              class="info-text link"
              href="mailto:{{ refund.cancellingRepresentativeEmail }}"
              >{{ refund.cancellingRepresentativeName }}</a
            >
          </div>
        </ng-container>
      </div>
      <div class="list-container">
        <div class="list-row">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-User-Single-Black-No-Circle.svg"
              alt=""
            />
            <span class="medium2" i18n="@@viewRefund.list.customer">Customer</span>
          </div>
          <div class="medium">
            <ng-container *ngIf="!!paymentRequest.to">{{
              paymentRequest.to | nameofperson
            }}</ng-container>
            <ng-container *ngIf="!paymentRequest.to"
              ><a
                href="tel:{{ paymentRequest.phone }}"
                class="link hide-long-texts"
                >{{ paymentRequest.phone }}</a
              ></ng-container
            >
          </div>
        </div>
        <div class="list-row">
          <div class="icon-and-text">
            <img
              class="icons"
              src="assets/icons/Icon-Money-Wallet.svg"
              alt=""
            />
            <span class="medium2" i18n="@@viewRefund.list.originalAmount"
              >Initial amount</span
            >
          </div>
          <div class="medium">
            <span>{{
              paymentRequest.value | value: localeService.getCurrentLocale()
            }}</span>
          </div>
        </div>
        <div class="list-row" *ngIf="!!refundedValue">
          <div class="icon-and-text">
            <img class="icons" src="assets/icons/Icon-Reset.svg" alt="" />
            <span class="medium2" i18n="@@viewRefund.list.prevRefundedAmount"
              >Former reg. refund</span
            >
          </div>
          <div class="medium cerise-text">
            <span>{{
              refundedValue | value: localeService.getCurrentLocale()
            }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="showTitleAndDescription">
        <div class="title">{{ paymentRequest.title }}</div>
        <div class="description">{{ paymentRequest.description }}</div>
      </div>
      <div
        class="show-more"
        *ngIf="showTitleAndDescription"
        (click)="toggleTitleAndDescription()"
      >
        <span
          class="link"
          i18n="@@viewRefund.paymentRequest.description.showLess"
          >Show less</span
        >
        <svg
          width="14"
          height="9"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5 2.25109L1.61785 9.69958C1.24772 10.1002 0.64765 10.1001 0.277554 9.69951C-0.0925424 9.29888 -0.0925144 8.64937 0.277617 8.24878L7.47358 0.460652C7.47366 0.460557 7.47375 0.460463 7.47384 0.460369C7.47392 0.460279 7.474 0.46019 7.47409 0.460101C7.6087 0.31433 7.76852 0.198672 7.94442 0.119728C8.12054 0.0406854 8.30934 0 8.5 0C8.69066 0 8.87946 0.0406854 9.05558 0.119728C9.23148 0.198672 9.3913 0.31433 9.52591 0.460101C9.52608 0.460284 9.52625 0.460468 9.52642 0.460652L16.7224 8.24878C17.0925 8.64937 17.0925 9.29888 16.7224 9.69951C16.3523 10.1001 15.7523 10.1002 15.3821 9.69958L8.5 2.25109ZM8.81483 1.91035C8.81466 1.91053 8.81449 1.91072 8.81432 1.9109L8.81483 1.91035Z"
            fill="#4466EE"
          />
        </svg>
      </div>
      <div
        class="show-more"
        *ngIf="!showTitleAndDescription"
        (click)="toggleTitleAndDescription()"
      >
        <span
          class="link"
          i18n="@@viewRefund.paymentRequest.description.showMore"
          >Show more details</span
        >
        <svg
          width="14"
          height="9"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.277554 0.30049C0.64765 -0.100137 1.24772 -0.100167 1.61785 0.300422L8.5 7.74891L15.3821 0.300422C15.7523 -0.100167 16.3523 -0.100137 16.7224 0.30049C17.0925 0.701117 17.0925 1.35063 16.7224 1.75122L9.52642 9.53935C9.52625 9.53953 9.52608 9.53972 9.52591 9.5399C9.3913 9.68567 9.23148 9.80133 9.05558 9.88027C8.87945 9.95932 8.69066 10 8.5 10C8.30934 10 8.12055 9.95932 7.94442 9.88027C7.7685 9.80132 7.60867 9.68565 7.47405 9.53986C7.47389 9.53969 7.47373 9.53952 7.47358 9.53935L0.277616 1.75122C-0.0925144 1.35063 -0.0925424 0.701117 0.277554 0.30049ZM8.81432 8.0891C8.81444 8.08922 8.81455 8.08935 8.81466 8.08947L8.81432 8.0891Z"
            fill="#4466EE"
          />
        </svg>
      </div>
      <div class="spacer" *ngIf="!showTitleAndDescription && refund.note"></div>

      <hr *ngIf="refund.note" />
      <div class="note-container" *ngIf="refund.note">
        <div class="note-header">
          <span class="note-date"
            >{{ refund.created | date: 'MMM':'':'sv' | slice: 0:1 | uppercase
            }}{{
              refund.created | date: 'MMM d.y, kl HH.mm':'':'sv' | slice: 1
            }}</span
          >
          <div class="note-type-pill">
            <span i18n="@@viewRefund.note.type">Note</span>
          </div>
        </div>
        <span class="note-text">{{ refund.note }}</span>
      </div>
    </div>
    <div class="buttons-container" *ngIf="showButtons()">
      <zaver-primary-button
        text="Approve refund"
        i18n-text="@@viewRefund.button.approve"
        [disabled]="approveButtonDisabled"
        (click)="approve.emit(refund)"
      >
      </zaver-primary-button>

      <zaver-secondary-button-subtle
        text="Deny"
        i18n-text="@@viewRefund.button.deny"
        [disabled]="cancelButtonDisabled"
        (click)="decline.emit(refund)"
      ></zaver-secondary-button-subtle>
    </div>
  </div>
  <app-payment-request-events
    *ngIf="activePage === 'events'"
    [paymentRequest]="paymentRequest"
  >
  </app-payment-request-events>
</ng-container>
<app-page-error *ngIf="errorMessage" [errorMessage]="errorMessage">
</app-page-error>
