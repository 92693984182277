import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyCode, Currency } from '@core/dto/CurrencyCode';
import { Market } from '@core/dto/Market';
import { PaymentRequestRequest } from '@core/dto/PaymentRequestRequest';
import { availableCountryCodes, setCountryFlag } from '@core/select-country';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { LoggingService } from '@core/service/logging.service';
import {
  PaymentRequestCreationResponse,
  PaymentRequestService,
} from '@core/service/payment-request.service';
import { FormValidators } from '@shared/validators/validators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';
import { Item } from '../../../../../../../web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-create-payment-request-consumer',
  styleUrls: ['./create-payment-request-consumer.component.scss'],
  templateUrl: './create-payment-request-consumer.component.html',
})
export class CreatePaymentRequestConsumerComponent implements OnInit {
  form: FormGroup;
  formError: string = '';
  showError: boolean = false;
  processing: boolean = false;
  copyLinkSelected: boolean = false;
  defaultPhoneCode: string;

  showInstallmentsToggle: boolean = false;
  // Possible alias for title in the PR creation form. Potentially sent from IDAU as part of the guiCustomizations
  // field on the UserDetails from a call to /user/whoami. Defaults to 'Title'
  prFormTitleAlias = this.getAliasForTitleInForm();
  prFormDescriptionAlias = this.getAliasForDescriptionInForm();

  merchantHasB2B: boolean;

  @Input() defaultCurrency: CurrencyCode;

  @Output() onDone = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();
  @Output() returnToSelection = new EventEmitter();

  maxAmount: number;

  market: Market;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item;
  formSubscription: Subscription;

  constructor(
    private paymentRequestService: PaymentRequestService,
    private appStateService: AppStateService,
    private loggingService: LoggingService,
    private auth: AuthenticationService,
    public deviceService: DeviceDetectorService
  ) {
    switch (this.appStateService.getDefaultCurrency()) {
      case CurrencyCode.SEK:
      case CurrencyCode.DKK:
      case CurrencyCode.NOK:
        this.maxAmount = 999999;
        break;
      case CurrencyCode.EUR:
        this.maxAmount = 99999;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.market = this.appStateService.getMarket();
    this.selectedcountry = this.getSelectedCountry();
    this.merchantHasB2B = this.appStateService.merchantHasB2bPayments();
    this.showInstallmentsToggle = this.shouldShowInstallmentsToggle();
    this.setup();
  }

  private shouldShowInstallmentsToggle(): boolean {
    if (this.market !== 'SE') {
      return false;
    }

    return this.appStateService.getCurrentUser().activeRepresentation.company
      .canHideInstallmentOptions;
  }

  setup() {
    this.form = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(50000),
      ]),
      price: new FormControl('', [
        Validators.required,
        FormValidators.number(1, this.maxAmount),
      ]),
      phone: new FormControl(this.defaultPhoneCode, [
        Validators.required,
        phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
      ]),
    });

    this.formSubscription = this.form.controls.phone.valueChanges.subscribe(
      (value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      }
    );

    if (this.showInstallmentsToggle) {
      this.form.addControl('toggleInstallmentOptions', new FormControl(false));
    }
  }

  setCountry(selected) {
    this.form.controls.phone.setValue(selected.value);
  }

  submit() {
    this.formError = '';
    if (this.form.valid) {
      this.processing = true;
      const hideInstallmentOptions = this.shouldHideInstallments(
        this.form.value.toggleInstallmentOptions
      );
      let title: string = this.form.controls['title'].value;
      title = title.trim();
      let description: string = this.form.controls['description'].value;
      description = description.trim();

      const value: Currency = {
        amount: this.form.value.price.replace(/\s/g, '').replace(',', '.'),
        currencyCode: this.defaultCurrency,
      };

      const paymentRequest: PaymentRequestRequest = {
        title,
        description,
        value,
        phone: formatPhoneNumber(this.form.value.phone),
        hideInstallmentOptions,
        skipNotifyingBuyer: this.copyLinkSelected,
      };
      this.handleRequest(this.paymentRequestService.create(paymentRequest));
    } else {
      this.showError = true;
    }
  }

  handleRequest(creationPromise: Promise<PaymentRequestCreationResponse>) {
    creationPromise
      .then((response: PaymentRequestCreationResponse) => {
        if (response && response.paymentRequestId && !this.copyLinkSelected) {
          this.onDone.emit('b2c');
        } else if (
          response &&
          response.paymentRequestId &&
          this.copyLinkSelected
        ) {
          this.paymentRequestService.paymentRequestId =
            response.paymentRequestId;
          this.onDone.emit('b2c_link');
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleError(httpError: HttpErrorResponse) {
    if (httpError.status === 0) {
      this.formError = 'Kontrollera nätverksanslutningen och försök igen.';
      this.processing = false;
      return;
    }
    if (httpError.status === 401 || httpError.status === 403) {
      this.onClose.emit();
      this.processing = false;
      this.auth.logout();
      return;
    }
    this.formError = httpError.error.message
      ? httpError.error.message
      : httpError.error;
    this.processing = false;
  }

  shouldHideInstallments(boxIschecked: boolean): boolean {
    // always show installment options for DE and AT
    if (this.market === Market.DE || this.market === Market.AT) {
      return false;
    }

    if (
      !this.appStateService.getCurrentUser().activeRepresentation.company
        .canHideInstallmentOptions
    ) {
      return false;
    }

    // the label for the checkbox can be customized per merchant --> can be set so that a checked box means either hide
    // or show installment options. This method inverts the value shown in the gui appropriately
    if (
      this.appStateService.getGuiCustomizations()?.toggleInstallmentSettings ===
      null
    ) {
      return false;
    }

    if (
      this.appStateService.getGuiCustomizations().toggleInstallmentSettings
        .trueMeansShowInstallments
    ) {
      return !boxIschecked;
    } else {
      return boxIschecked;
    }
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }

    return $localize`:@@createPaymentRequestConsumer.form.title.title:Title`;
  }

  getAliasForDescriptionInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (
      guiCustomizations !== null &&
      guiCustomizations.prFormDescriptionAlias
    ) {
      return guiCustomizations.prFormDescriptionAlias;
    }

    return $localize`:@@createPaymentRequestConsumer.form.description.title:Description`;
  }

  getAliasForToggleInstallments(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();

    if (
      guiCustomizations !== null &&
      guiCustomizations.toggleInstallmentSettings &&
      guiCustomizations.toggleInstallmentSettings.termToggleInstallments
    ) {
      return guiCustomizations.toggleInstallmentSettings.termToggleInstallments;
    }

    return $localize`:@@createPaymentRequestConsumer.toggleInstallments:Hide installment payment`;
  }

  displayAmountLimitsLink(): boolean {
    return this.appStateService.getMarket() === Market.SE;
  }

  getPhoneErrorMessage(): string {
    const errors = this.form.controls.phone.errors;
    if (!errors) {
      return null;
    }
    if (errors['required']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.required:Please enter a mobile number`;
    } else if (errors['invalidFormat']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.invalidFormat:Please check the mobile number`;
    } else if (errors['invalidNumber']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.invalidNumber:Please check the mobile number`;
    } else if (errors['unsupportedCountryCode']) {
      return $localize`:@@createPaymentRequestConsumer.form.phone.error.unsupportedCountryCode:The specified country code is not supported`;
    }
    return null;
  }

  setCopyLink(value: boolean): void {
    this.copyLinkSelected = value;
  }

  getSelectedCountry(): Item {
    switch (this.market) {
      case Market.SE:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[0].value;
        return this.AVAILABLECOUNTRYCODES[0];
      case Market.NO:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[1].value;
        return this.AVAILABLECOUNTRYCODES[1];
      default:
        this.defaultPhoneCode = this.AVAILABLECOUNTRYCODES[0].value;
        return this.AVAILABLECOUNTRYCODES[0];
    }
  }
}
