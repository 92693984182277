import { RefundApprovalError } from '@core/dto/refund/Refund';

export const RefundApprovalErrors: Map<RefundApprovalError, string> = new Map([
  [
    RefundApprovalError.SWISH_PAYMENT_TOO_OLD,
    'Returen kan inte genomföras då det har gått mer än 12 månander sedan betalningen gjordes via Swish.<br><br>Vid kvarstående problem kan returen behöva lösas manuellt utanför Zaver.',
  ],
  [
    RefundApprovalError.SWISH_MERCHANT_NUMBER_CHANGED,
    'Returen har avbrutits då det verkar som att ni inte längre har samma Swish-nummer som vid tidpunkten då betalningen genomfördes.<br><br>Vid kvarstående problem kan returen behöva lösas manuellt utanför Zaver.',
  ],
  [
    RefundApprovalError.SWISH_MERCHANT_ORG_NUMBER_CHANGED,
    'Returen har avbrutits då ert organisationsnummer har ändrats.<br><br>Vänligen <a class="link" href="mailto:merchants@zaver.se" target="_blank">kontakta&nbsposs.</a>',
  ],
  [
    RefundApprovalError.SWISH_CUSTOMER_NUMBER_CHANGED,
    'Returen har avbrutits då mottagaren inte längre har samma mobilnummer anslutet till Swish, som vid tidpunkten då betalningen genomfördes.<br><br>Vid kvarstående problem kan returen behöva lösas manuellt utanför Zaver.',
  ],
  [
    RefundApprovalError.SWISH_CUSTOMER_NOT_ACTIVE,
    'Swish-kontot som betalningen gjordes ifrån är inte längre aktivt.<br><br>Vid kvarstående problem kan returen behöva lösas manuellt utanför Zaver.',
  ],
  [
    RefundApprovalError.AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT,
    $localize`:@@refundApprovalErrors.AMOUNT_EXCEEDS_REFUNDABLE_AMOUNT:The refund exceeds the allowed amount and has been cancelled.`,
  ],
  [
    RefundApprovalError.SWISH_INSUFFICIENT_FUNDS,
    'Returen kan inte genomföras då saldot på ert Swish-konto är för lågt. Vänligen överför medel till det bankkonto ni anslutit till Swish, och försök sedan attestera returen på nytt.',
  ],
  [
    RefundApprovalError.SWISH_TEMPORARY_ERROR,
    'Något gick fel vid uppkopplingen mot Swish.<br>Vänligen försök igen lite senare.<br><br><a class="link" href="mailto:merchants@zaver.se" target="_blank">Kontakta oss</a> om problemet kvarstår.',
  ],
  [
    RefundApprovalError.OTHER,
    'En återbetalning via Swish kunde inte genomföras och returen har avbrutits. Vänligen kontrollera uppgifterna och försök skapa en ny retur.<br><br>Vid kvarstående problem kan returen behöva lösas manuellt utanför Zaver.',
  ],
]);
