<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@cancelCashoutTask.question"
      >Are you sure you want to cancel the payout? This action can't be undone. 😮</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@cancelCashoutTask.button.back"
        [disabled]="cancellationInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, cancel"
        i18n-text="@@cancelCashoutTask.button.confirm"
        [loading]="cancellationInProgress"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        i18n-text="@@cancelCashoutTask.button.close"
        [disabled]="cancellationInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Try again"
        i18n-text="@@cancelCashoutTask.button.tryAgain"
        [loading]="cancellationInProgress"
        [color]="'black'"
        (click)="cancel()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
