<div class="header-container">
  <div class="title-text-container">
    <h1 class="title" i18n="@@precheck.de.header">Test purchasing power</h1>
    <span class="heading-text" i18n="@@precheck.de.intro">
      By assessing the customer's creditworthiness, you can make a preliminary
      determination of their eligibility for invoice or installment payments through
      Zaver. The customer must provide consent beforehand, as a credit report will
      be conducted and its result sent to them via SMS.
    </span>
  </div>
  <zaver-zfb-add-button
    id="main-create-button"
    text="Send link"
    i18n-text="@@precheck.de.create.button.text"
    (click)="create()"
  ></zaver-zfb-add-button>
</div>

<ng-container *ngIf="fetchingPrechecks">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>

<ng-container *ngIf="!fetchingPrechecks">
  <app-table
    [columns]="columns"
    [values]="prechecks"
    (filterChange)="onFilterChange($event)"
  >
  </app-table>

  <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
  </app-empty-state>

  <app-table-pagination
    *ngIf="!displayEmptyState"
    [paginationState]="paginationState"
    (pageChanged)="onPageChange($event)"
    (pageSizeChanged)="onPageSizeChange($event)"
  >
  </app-table-pagination>

  <zaver-zfb-add-button
    class="mobile-cta"
    text="Send link"
    i18n-text="@@precheck.de.create.button.text"
    (click)="create()"
  ></zaver-zfb-add-button>
</ng-container>

<!-- Modal -->
<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
  [ngSwitch]="activeModalComponent"
>
  <app-create-precheck
    *ngSwitchCase="Modal.CREATE"
    [merchantPrecheckLimits]="merchantPrecheckLimits"
    (created)="handleCreatedPrecheck()"
  >
  </app-create-precheck>
  <app-view-precheck
    *ngSwitchCase="Modal.VIEW"
    [precheck]="selectedPrecheck"
    (resendSms)="resendSms($event)"
  >
  </app-view-precheck>
</zaver-form-modal>
