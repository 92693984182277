<div class="roles-selection spacing-container" *ngIf="initialRoleSelection">
  <div>
    <h3 class="hide-mobile-small">
      <span i18n="@@createCompanyRepresentative.title">Enter role</span>
    </h3>

    <div *ngFor="let role of displayRoles">
      <div
        class="role-selection"
        [class.selectable]="role.selectable"
        (click)="!!role.selectable && roleRadioButton(role)"
      >
        <div class="role-selection-text">
          <div class="role-selection-text-header">
            {{ role.displayName }}
          </div>
          <div class="role-selection-text-description">
            <span [innerHTML]="role.displayDescription"></span>
          </div>
        </div>
        <div class="role-selection-radio-button" *ngIf="role.selectable">
          <zaver-zfb-radio-button
            [color]="'cerise'"
            [selected]="selectedRole === role"
          ></zaver-zfb-radio-button>
        </div>
      </div>
    </div>
  </div>

  <div id="cta-button">
    <zaver-primary-button
      text="Continue"
      i18n-text="@@createCompanyRepresentative.button.continue"
      (click)="continue()"
      [disabled]="!selectedRole"
    >
    </zaver-primary-button>
  </div>
</div>

<div id="create-user-div" *ngIf="!initialRoleSelection">
  <app-create-company-representative-form
    [selectedRole]="selectedRole"
    [roles]="createableRoles"
    (onDone)="submit()"
    (roleChanged)="roleChanged.emit($event)"
  >
  </app-create-company-representative-form>
</div>
