<ng-container *ngIf="appState.getMarket() === 'SE'">
  <app-precheck-se></app-precheck-se>
</ng-container>
<ng-container
  *ngIf="
    appState.getMarket() === 'DE' ||
    appState.getMarket() === 'AT'
  "
>
  <app-precheck-de></app-precheck-de>
</ng-container>
