<div class="form-wrapper spacing-container" *ngIf="!loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="!loading">
    <div class="input-fields">
      <zaver-dropdown
        title="Role"
        i18n-title="@@createCompanyRepresentativeForm.form.role.title"
        [inputModel]="selectedRole.id"
        [selectOptions]="roleSelectionValues"
        (inputModelChange)="onRoleChanged($event)"
        [size]="'large'"
      >
      </zaver-dropdown>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.email.invalid && showError"
    >
      <zaver-form-input
        title="Email address"
        placeholder="Enter email address"
        [error]="form.controls.email.invalid && showError"
        errorMessage="Please enter a valid email address"
        formControlName="email"
        [maxLength]="255"
        [inputMode]="'email'"
        [size]="'large'"
        i18n-title="@@createCompanyRepresentativeForm.form.email.title"
        i18n-placeholder="
          @@createCompanyRepresentativeForm.form.email.placeholder"
        i18n-errorMessage="
          @@createCompanyRepresentativeForm.form.email.errorMessage"
      >
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.phone.invalid && showError"
      *ngIf="showPhoneNumberInput"
    >
      <zaver-form-input
        title="Mobile number"
        placeholder="Enter mobile number"
        [error]="form.controls.phone.invalid && showError"
        [errorMessage]="getPhoneErrorMessage()"
        formControlName="phone"
        [inputMode]="'tel'"
        [size]="'large'"
        i18n-title="@@createCompanyRepresentativeForm.form.phone.title"
        i18n-placeholder="
          @@createCompanyRepresentativeForm.form.phone.placeholder"
        [showContent]="market === 'DE' || market === 'AT'"
      >
        <zaver-select-country
          *ngIf="market === 'DE' || market === 'AT'"
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.personalNumber.invalid && showError"
      *ngIf="selectedRole.identified"
    >
      <zaver-form-input
        title="Social security number"
        i18n-title="@@createCompanyRepresentativeForm.form.personalNumber.title"
        helpTitle="(yyyymmddxxxx)"
        i18n-helpTitle="@@createCompanyRepresentativeForm.form.personalNumber.helpTitle"
        placeholder="yyyymmddxxxx"
        i18n-placeholder="@@createCompanyRepresentativeForm.form.personalNumber.placeholder"
        [error]="form.controls.personalNumber.invalid && showError"
        errorMessage="Please enter a valid social security number (yyyymmddxxxx)"
        i18n-errorMessage="@@createCompanyRepresentativeForm.form.personalNumber.errorMessage"
        formControlName="personalNumber"
        [inputMode]="'numeric'"
        size="large"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields" *ngIf="canSeeStores">
      <zaver-dropdown
        title="Connect to ..."
        i18n-title="@@createCompanyRepresentativeForm.form.store.title"
        [inputModel]="selectedStore"
        [selectOptions]="storeOptions"
        (inputModelChange)="onStoreChanged($event)"
        [size]="'large'"
        [shorterDropdown]="true"
        initialLabel="Choose"
        i18n-initialLabel="
          @@createCompanyRepresentativeForm.form.store.initialLabel"
      >
      </zaver-dropdown>
    </div>
    <div class="input-fields">
      <div *ngIf="canSeeStores && !!selectedStore" class="input-section">
        <div class="input-heading">
          <span
            i18n="
              @@createCompanyRepresentativeForm.form.storeRelation.permission.title"
            >User can manage</span
          >
        </div>
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedStorePermissionOption(true)"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="handleAllActivities"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="
                @@createCompanyRepresentativeForm.form.storeRelation.permission.storeActivities"
              >{{ getStoreAlias() }} activities</span
            >
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedStorePermissionOption(false)"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="!handleAllActivities"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="
                @@createCompanyRepresentativeForm.form.storeRelation.permission.ownActivities"
              >Only own activities</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="error-container">
      <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
      </app-form-error>
    </div>
  </form>
  <div id="cta-button">
    <zaver-primary-button
      text="Add"
      i18n-text="@@createCompanyRepresentativeForm.form.button.submit"
      [loading]="creationInProgress"
      (click)="submit()"
    >
    </zaver-primary-button>
  </div>
</div>
