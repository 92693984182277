import { RadioButtonData } from 'app/zfb/ui/radio-select/radio-select.component';

export enum Industry {
  FINANCIAL_AND_INSURANCE_ACTIVITIES = 'FINANCIAL_AND_INSURANCE_ACTIVITIES',
  CONSTRUCTION_AND_REAL_ESTATE = 'CONSTRUCTION_AND_REAL_ESTATE',
  HAIRDRESSER_AND_CRAFTS = 'HAIRDRESSER_AND_CRAFTS',
  WHOLESALE_AND_RETAIL_TRADE = 'WHOLESALE_AND_RETAIL_TRADE',
  NON_PROFIT = 'NON_PROFIT',
  MEDIA_AND_TELECOMMUNICATIONS = 'MEDIA_AND_TELECOMMUNICATIONS',
  TECHNOLOGY = 'TECHNOLOGY',
  MANUFACTURING_AND_INDUSTRY = 'MANUFACTURING_AND_INDUSTRY',
  CARE_MEDICAL_AND_HEALTH = 'CARE_MEDICAL_AND_HEALTH',
  OTHER = 'OTHER',
}
export class IndustryOptions {
  static VALUES: RadioButtonData[] = [
    {
      value: Industry.FINANCIAL_AND_INSURANCE_ACTIVITIES,
      label: $localize`:@@industry.bank:Bank, finance & insurance`,
    },
    {
      value: Industry.CONSTRUCTION_AND_REAL_ESTATE,
      label: $localize`:@@industry.construction:Construction & real estate`,
    },
    {
      value: Industry.HAIRDRESSER_AND_CRAFTS,
      label: $localize`:@@industry.crafts:Hairdresser & crafts`,
    },
    {
      value: Industry.WHOLESALE_AND_RETAIL_TRADE,
      label: $localize`:@@industry.retail:Trade & consumer production`,
    },
    {
      value: Industry.NON_PROFIT,
      label: $localize`:@@industry.nonProfit:Non-profit organizations`,
    },
    {
      value: Industry.MEDIA_AND_TELECOMMUNICATIONS,
      label: $localize`:@@industry.media:Media & telecom`,
    },
    {
      value: Industry.TECHNOLOGY,
      label: $localize`:@@industry.technology:Technology`,
    },
    {
      value: Industry.MANUFACTURING_AND_INDUSTRY,
      label: $localize`:@@industry.manufacturing:Manufacture & industry`,
    },
    {
      value: Industry.CARE_MEDICAL_AND_HEALTH,
      label: $localize`:@@industry.medicin:Healthcare, medicine & health`,
    },
  ];
}
