<ng-container *ngIf="!loading">
  <div class="card">
    <div class="card-heading">
      <h2 i18n="@@reportRecipient.title">Email settings</h2>
    </div>

    <div class="card-body" [formGroup]="emailForm">
      <ng-container *ngFor="let form of allEmailForms">
        <form [formArrayName]="form.name">
          <div class="form-wrapper">
            <div class="form-title-wrapper">
              <div class="form-title" i18n="@@reportRecipient.form.title">
                Email address for {{ form.title }}
                <span class="file-preference" *ngIf="form.showFileFormatText">{{
                  form.fileFormatText
                }}</span>
              </div>
              <div>
                <button
                  class="admin-link link"
                  *ngIf="form.formArray.controls.length >= 3"
                  (click)="removeAllEmailAdressesPrompt(form)"
                  i18n="@@reportRecipient.button.text.clear"
                >
                  Clear
                </button>
              </div>
            </div>
            <div
              class="input-fields"
              *ngFor="let emailForm of form.formArray.controls; let i = index"
              [ngClass]="i > 0 ? 'removeable' : ''"
            >
              <zaver-form-input
                class="input-fields-input-form"
                placeholder="Enter email address"
                i18n-placeholder="@@reportRecipient.placeholder.email"
                size="large"
                [error]="emailForm.invalid && showError"
                errorMessage="Please enter a valid email address"
                i18n-errorMessage="@@reportRecipient.errorMessage.email"
                [formControlName]="i"
              ></zaver-form-input>
              <div class="remove-button-wrapper" *ngIf="i > 0">
                <button
                  class="exit-close-icon-wrapper"
                  (click)="removeEmailForm(form, i)"
                  type="button"
                >
                  <img
                    class="exit-close-icon"
                    src="assets/icons/Icon-Navigation-Exit-Close-Black.svg"
                    alt="icon-exit-close"
                  />
                </button>
              </div>
            </div>
            <div>
              <button
                id="add-email"
                class="add-email"
                (click)="addEmailForm(form)"
                type="button"
              >
                <p class="add-email-text" i18n="@@reportRecipient.addEmail.text">Add email address</p>
                <!-- We want to change fill(color) of this svg on hover with CSS. If we reference this svg using <img>
                tag we no longer have the ability to manipulate those properties using CSS.-->
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#4466ee"
                  xmlns="http://www.w3.org/2000/svg"
                  class="add-email-icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 0C6.38309 0 6.69364 0.310554 6.69364 0.693642V11.3064C6.69364 11.6894 6.38309 12 6 12C5.61691 12 5.30636 11.6894 5.30636 11.3064V0.693642C5.30636 0.310554 5.61691 0 6 0Z"
                    fill="inherit"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 6C0 5.61691 0.310554 5.30636 0.693642 5.30636H11.3064C11.6894 5.30636 12 5.61691 12 6C12 6.38309 11.6894 6.69364 11.3064 6.69364H0.693642C0.310554 6.69364 0 6.38309 0 6Z"
                    fill="inherit"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </ng-container>

      <div id="admin-buttons">
        <zaver-secondary-button-outlined
          class="admin-button"
          *ngIf="
            checkoutFormsChanged || cashoutFormChanged || refundFormChanged
          "
          (click)="promptCancelModal()"
          text="Cancel"
          i18n-text="@@reportRecipient.buttonText.cancel"
        ></zaver-secondary-button-outlined>
        <zaver-primary-button
          class="admin-button"
          (click)="onSave()"
          [disabled]="
            !(checkoutFormsChanged || cashoutFormChanged || refundFormChanged)
          "
          text="Save"
          i18n-text="@@reportRecipient.buttonText.save"
          color="black"
        ></zaver-primary-button>
      </div>
    </div>
  </div>

  <app-page-error
    *ngIf="!emailForm.valid && showError"
    [errorMessage]="formErrorText"
  ></app-page-error>

  <zaver-details-modal
    *ngIf="modalActive"
    (onClose)="closeModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-report-recipient-confirmation
      *ngIf="modalView === ModalView.SUBMIT"
      (onClose)="closeModal()"
      (onSubmit)="submit()"
      [serverError]="serverError"
      [errorMessage]="errorMessage"
      [submitting]="submitting"
    >
    </app-report-recipient-confirmation>

    <div class="small-modal-wrapper" *ngIf="modalView === ModalView.CANCEL">
      <span i18n="@@reportRecipient.text.confirmCancel"
        >Are you sure you want to cancel? Your changes will not be saved.
        😯</span
      >

      <div class="button-container">
        <zaver-secondary-button-subtle
          class="button-width-auto"
          text="Back"
          i18n-text="@@reportRecipient.text.confirmCancel.back"
          (click)="closeModal()"
          size="x-small"
        ></zaver-secondary-button-subtle>
        <zaver-primary-button
          class="button-width-auto"
          text="Yes, cancel"
          i18n-text="@@reportRecipient.text.confirmCancel.confirm"
          color="black"
          (click)="cancel()"
          size="x-small"
        ></zaver-primary-button>
      </div>
    </div>

    <div
      class="small-modal-wrapper"
      *ngIf="modalView === ModalView.REMOVE_EMAIL_ADDRESSES"
    >
      <span i18n="@@reportRecipient.text.confirmClear"
        >Are you sure you want to clear all email addresses for
        {{ currentForm.title }}?</span
      >

      <div class="button-container">
        <zaver-secondary-button-subtle
          text="Back"
          i18n-text="@@reportRecipient.text.confirmClear.back"
          (click)="closeModal()"
          size="x-small"
        ></zaver-secondary-button-subtle>
        <zaver-primary-button
          text="Yes, clear"
          i18n-text="@@reportRecipient.text.confirmClear.confirm"
          color="black"
          (click)="removeAllEmailAdresses()"
          size="x-small"
        ></zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</ng-container>
