import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CashoutTaskService } from '@core/service/cashout-task.service';

@Component({
  selector: 'app-confirm-cashout-task',
  templateUrl: './confirm-cashout-task.component.html',
  styleUrls: ['./confirm-cashout-task.component.css'],
})
export class ConfirmCashoutTaskComponent
  extends BaseComponent
  implements OnInit
{
  @Input() cashoutTask: CashoutTaskDto;
  @Output() onReturnToMainModal = new EventEmitter();
  @Output() onConfirm = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();

  showError!: boolean;
  inputValue!: string;
  authorizatonMessage: string;
  resentAuthenticationCode: boolean;
  resendingAuthCodeLoading: boolean = false;
  sentAuthenticationCodeBySms: boolean;
  sendingAuthenticationCodeBySms: boolean;
  emailAdress: string;
  missingEmailError = false;

  disbursementInProgress: boolean = false;
  serverErrorMessage =
    $localize`:@@confirmCashoutTask.serverErrorMessage:Changes couldn't be saved. Please try again or cancel by closing. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;

  constructor(
    private cashoutTaskService: CashoutTaskService,
    private appStateService: AppStateService,
    auth: AuthenticationService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.emailAdress = this.appStateService.getActiveRepresentation().email;

    this.cashoutTaskService.sendVerificationCode().subscribe({
      error: (error) => {
        this.showError = true;
        if (error.status === 403) {
          this.missingEmailError = true;
        }
      },
    });
  }

  returnToMainModal() {
    this.onReturnToMainModal.emit();
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  resendAuthenticationCode() {
    this.resendingAuthCodeLoading = true;
    this.cashoutTaskService.sendVerificationCode().subscribe({
      next: () => {
        setTimeout(() => {
          this.resendingAuthCodeLoading = false;
          this.resentAuthenticationCode = true;
        }, 1000);
      },
      error: () => {
        this.showError = true;
        this.resendingAuthCodeLoading = false;
      },
    });
  }

  sendCodeBySmsToRepresentatives() {
    this.sendingAuthenticationCodeBySms = true;
    this.cashoutTaskService.sendVerificationCodeBySms().subscribe({
      next: () => {
        setTimeout(() => {
          this.sendingAuthenticationCodeBySms = false;
          this.sentAuthenticationCodeBySms = true;
        }, 1000);
      },
      error: () => {
        this.showError = true;
        this.sendingAuthenticationCodeBySms = false;
        this.sentAuthenticationCodeBySms = true;
      },
    });
  }

  executeDisbursement() {
    if (this.inputValue == null || this.inputValue.length < 4) {
      this.showError = true;
      this.disbursementInProgress = false;
      return;
    }
    this.errorMessage = '';
    this.disbursementInProgress = true;
    this.showError = false;

    this.cashoutTaskService
      .executeDisbursement(this.cashoutTask.taskId, this.inputValue)
      .subscribe(
        () => {
          this.onConfirm.emit();
        },
        (errorResponse) => {
          this.disbursementInProgress = false;
          if (errorResponse.status === 400) {
            this.authorizatonMessage = errorResponse.error;
          } else {
            this.handleError(errorResponse);
          }
        }
      );
  }
}
