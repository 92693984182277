<div *ngIf="checkedAccess && accessGranted">
  <h1
    class="page-heading"
    i18n="@@cashout.title"
  >Payouts</h1>

  <div id="search-and-create-container">
    <div class="input-container">
      <zaver-search
        id="search-bar"
        [inputModel]="searchQuery"
        (inputModelChange)="searchUpdatedQuery($event)"
      ></zaver-search>
    </div>
    <zaver-zfb-add-button
      *ngIf="shouldDisplayCreateButton()"
      id="main-create-button"
      text="New payout"
      i18n-text="@@cashout.create.payout"
      (click)="create()"
    ></zaver-zfb-add-button>
  </div>
  <app-page-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></app-page-error>

  <div id="balance-container">
    <div class="balance-wrapper">
      <span
        class="balance-label"
        i18n="@@cashout.balanceBar.balance"
      >Balance: </span>
      <span
        *ngIf="fetchingBalance"
        class="balance-text"
        i18n="@@cashout.balanceBar.updating"
      >Updating...</span>
      <span *ngIf="!fetchingBalance" class="balance-text">
        {{
          (balance | currency: 'SEK ':'code':'1.2-2':localeService.getCurrentLocale())
        }}
      </span>
    </div>
    <div id="spacer"></div>
    <div class="balance-wrapper">
      <span
        class="pending-label"
        i18n="@@cashout.balanceBar.payouts"
      >Ongoing payouts: </span>
      <span class="cerise-text">{{
        pendingExecutionSum | currency: 'SEK ':'code':'1.2-2':localeService.getCurrentLocale()
      }}</span>
    </div>
  </div>

  <div id="mobile-balance">
    <div class="balance-wrapper">
      <span class="balance-label">Balance: </span>
      <span
        *ngIf="fetchingBalance"
        class="balance-text"
        i18n="@@cashout.balanceBar.updating"
      >Updating...</span>
      <span *ngIf="!fetchingBalance" class="balance-text">
        {{
          (balance | currency: 'SEK ':'code':'1.2-2':localeService.getCurrentLocale())
        }}
      </span>
    </div>
    <div class="balance-wrapper">
      <span
        class="pending-label"
        i18n="@@cashout.balanceBar.payouts"
      >Ongoing payouts: </span>
      <span class="cerise-text">{{
        pendingExecutionSum | currency: 'SEK ':'code':'1.2-2':localeService.getCurrentLocale()
      }}</span>
    </div>
  </div>

  <div *ngIf="fetchingResponse" class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>

  <ng-container *ngIf="!fetchingResponse">
    <app-table
      [columns]="columns"
      [values]="cashoutTasks"
      (filterChange)="onActiveStatusChange($event)"
    >
    </app-table>

    <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
    </app-empty-state>

    <app-table-pagination
      *ngIf="cashoutTasks.rows.length > 0"
      [paginationState]="paginationState"
      (pageChanged)="onPageChange($event)"
      (pageSizeChanged)="onPageSizeChange($event)"
    >
    </app-table-pagination>
  </ng-container>

  <zaver-zfb-add-button
    *ngIf="!fetchingResponse && shouldDisplayCreateButton()"
    class="mobile-cta"
    text="New payout"
    i18n-text="@@cashout.create.payout"
    (click)="create()"
  ></zaver-zfb-add-button>

  <!-- Modal -->
  <zaver-form-modal
    *ngIf="modalActive"
    (onClose)="closeModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-create-cashout-task
      *ngIf="showCashoutTaskCreation"
      (onCashoutTaskCreated)="displayConfirmationText()"
      (updateModalTextAndColor)="updateModalTextAndColor($event)"
      (onClose)="closeModal()"
    >
    </app-create-cashout-task>

    <app-view-cashout-task
      *ngIf="showCashoutTaskView"
      [cashoutTask]="selectedTask"
      (onDone)="closeModalAndRefreshData()"
      (showCashoutCancelModal)="displayCancelContent()"
      (showCashoutDisbursementConfirmationModal)="displayConfirmation()"
    >
    </app-view-cashout-task>
  </zaver-form-modal>

  <!-- Modal -->
  <zaver-details-modal
    *ngIf="modalSmallActive"
    (onClose)="closeSmallModal()"
    [title]="modalTitle"
    [color]="modalColor"
  >
    <app-cancel-cashout-task
      *ngIf="showCashoutCancel"
      [cashoutTask]="selectedTask"
      (onReturnToMainModal)="returnToViewModal()"
      (onCloseModal)="closeSmallModal()"
      (onCloseAfterCancel)="closeSmallModalAndRefreshData()"
    >
    </app-cancel-cashout-task>
    <app-confirm-cashout-task
      *ngIf="showCashoutConfirm"
      [cashoutTask]="selectedTask"
      (onReturnToMainModal)="returnToViewModal()"
      (onCloseModal)="closeSmallModal()"
      (onConfirm)="closeSmallModalAndRefreshData()"
    >
    </app-confirm-cashout-task>
    <div class="small-modal-wrapper" *ngIf="displayCashoutTaskSentConfirmation">
      <span i18n="@@cashout.success">Done! Your request has been sent. 🙂 </span>

      <div class="button-container">
        <zaver-primary-button
          text="Close"
          i18n-text="@@cashout.modal.close"
          color="black"
          (click)="closeSmallModal()"
        >
        </zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>
</div>

<div *ngIf="checkedAccess && !accessGranted">
  <h4
    id="access-denied-text"
    i18n="@@cashout.accessDenied"
  >Access denied.</h4>
</div>
