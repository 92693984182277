<!-- First out of three pages. Collects personalnumber, phonenumber, title and amount-->
<div *ngIf="showCashoutInfoForm" id="form-container" class="spacing-container">
  <form [formGroup]="cashoutInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.payeePersonalNumber.invalid &&
        showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        title="Recipient's personal number"
        i18n-title="@@createCashoutTask.personalNumber.title"
        placeholder="yyyymmddxxxx"
        i18n-placeholder="@@createCashoutTask.personalNumber.placeholder"
        [error]="
          cashoutInfoForm.controls.payeePersonalNumber.invalid &&
          showCashoutInfoFormError
        "
        errorMessage="Please enter a valid personal number (yyyymmddxxxx)"
        i18n-errorMessage="@@createCashoutTask.personalNumber.errorMessage"
        formControlName="payeePersonalNumber"
        [inputMode]="'numeric'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-User-Single-Black-No-Circle.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.payeePhone.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        title="Mobile number"
        i18n-title="@@createCashoutTask.mobileNumber.title"
        placeholder="Enter mobile number"
        i18n-placeholder="@@createCashoutTask.mobileNumber.placeholder"
        [error]="
          cashoutInfoForm.controls.payeePhone.invalid &&
          showCashoutInfoFormError
        "
        errorMessage="Please enter a valid mobile number"
        i18n-errorMessage="@@createCashoutTask.mobileNumber.errorMessage"
        formControlName="payeePhone"
        [inputMode]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Phone.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.amount.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [showContent]="true"
        title="Amount"
        i18n-title="@@createCashoutTask.amount.title"
        placeholder="Enter amount"
        i18n-placeholder="@@createCashoutTask.amount.placeholder"
        [error]="
          cashoutInfoForm.controls.amount.invalid && showCashoutInfoFormError
        "
        [errorMessage]="
          'Enter amount between 1 - ' +
          (maxAllowedAmount | spacedAmount) +
          currencyCode
        "
        i18n-errorMessage="@@createCashoutTask.amount.errorMessage"
        formControlName="amount"
        [inputMode]="'numeric'"
        [size]=""
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          {{ currencyCode }}
        </span>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="
        cashoutInfoForm.controls.reference.invalid && showCashoutInfoFormError
      "
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAlias"
        formControlName="reference"
        [error]="
          cashoutInfoForm.controls.reference.invalid && showCashoutInfoFormError
        "
        errorMessage="Field can not be empty (max 255 characters)"
        i18n-errorMessage="@@createCashoutTask.reference.errorMessage"
        [maxLength]="255"
        [lengthOfValue]="cashoutInfoForm.controls.reference.value.length"
        [showCounter]="true"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields" *ngIf="displayBankingOptions">
      <div class="input-section">
        <div
          class="input-heading"
          i18n="@@createCashoutTask.bankInformation"
          >Bank account details</div
        >
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedBankingOption(false)"
          >
            <zaver-zfb-radio-button
              [color]="'green'"
              [selected]="!manualBankInfoChoosen"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="@@createCashoutTask.bankConnection"
              >Bank connection</span
            >
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedBankingOption(true)"
          >
            <zaver-zfb-radio-button
              [color]="'green'"
              [selected]="manualBankInfoChoosen"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="@@createCashoutTask.altBankConnection"
              >Enter manually</span
            >
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="continue-back-buttons-container" id="hide-close-btn-on-desktop">
    <zaver-primary-button [text]="'Fortsätt'" (click)="continueToBankInfo()">
    </zaver-primary-button>
    <zaver-secondary-button-subtle
      *ngIf="deviceIsMobile()"
      text="Close"
      i18n-text="@@createCashoutTask.button.close"
      (click)="onClose.emit()"
    ></zaver-secondary-button-subtle>
  </div>
</div>

<!-- Second of three pages. Collect the bankinformation and verifies that the account number is a correct one-->
<div class="spacing-container" *ngIf="showBankInfoForm">
  <div>
    <form [formGroup]="bankInfoForm" novalidate>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.clearingNr.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          title="Clearing number"
          i18n-title="@@createCashoutTask.clearingNumber.title"
          placeholder="Enter clearing number"
          i18n-placeholder="@@createCashoutTask.clearingNumber.placeholder"
          [error]="
            bankInfoForm.controls.clearingNr.invalid && showBankInfoFormError
          "
          errorMessage="
            Please enter a valid clearing number (max 4-5 numbers).
          "
          i18n-errorMessage="@@createCashoutTask.clearingNumber.errorMessage"
          formControlName="clearingNr"
          [inputMode]="'numeric'"
          [size]="'large'"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            #
          </span>
        </zaver-form-input>
      </div>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.accountNr.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          title="Account number"
          i18n-title="@@createCashoutTask.accountNumber.title"
          placeholder="Enter account number"
          i18n-placeholder="@@createCashoutTask.accountNumber.placeholder"
          [error]="
            bankInfoForm.controls.accountNr.invalid && showBankInfoFormError
          "
          errorMessage="
            Please enter a valid account number (max 7-10 numbers)
          "
          i18n-errorMessage="@@createCashoutTask.accountNumber.errorMessage"
          formControlName="accountNr"
          [inputMode]="'numeric'"
          [size]="'large'"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            #
          </span>
        </zaver-form-input>
      </div>
      <div
        class="input-fields"
        [class.error]="
          bankInfoForm.controls.bankName.invalid && showBankInfoFormError
        "
      >
        <zaver-form-input
          [showContent]="true"
          title="Bank"
          i18n-title="@@createCashoutTask.bank.title"
          placeholder="Collected from clearing number"
          i18n-placeholder="@@createCashoutTask.bank.placeholder"
          formControlName="bankName"
          [error]="
            bankInfoForm.controls.bankName.invalid && showBankInfoFormError
          "
          errorMessage="Enter a valid bank name (max 50 characters)"
          i18n-errorMessage="@@createCashoutTask.bank.errorMessage"
          [size]="'large'"
        >
          <img src="assets/icons/Icon-Bank.svg" />
        </zaver-form-input>
      </div>
    </form>
    <div class="error-container">
      <app-form-error
        *ngIf="displayValidBankAccountError && !validBankAccount"
        errorText="The provided bank account number is invalid."
        i18n-errorText="@@createCashoutTask.bank.errorText"
      >
      </app-form-error>
      <app-form-error *ngIf="remoteError" [errorText]="remoteError">
      </app-form-error>
    </div>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      text="Continue"
      i18n-text="@@createCashoutTask.button.continue"
      (click)="setBankingInfo()"
      [loading]="validatingBankInfo"
    >
    </zaver-primary-button>
    <zaver-secondary-button-subtle
      text="Back"
      i18n-text="@@createCashoutTask.button.back"
      (click)="returnToCashoutTaskInfo()"
      [disabled]="validatingBankInfo"
    ></zaver-secondary-button-subtle>
  </div>
</div>

<!-- Last of three pages. Displays the entered information for confirmaiton. Displays a warning if a cashout task has been issued to the same recipient recently.-->
<div *ngIf="showConfirmation" class="spacing-container">
  <div>
    <div id="confirmation-heading" class="medium">
      {{ cashoutTaskToBeCreated.payeeDetailsReference }}
    </div>

    <div class="list-container">
      <div class="list-row">
        <div class="icon-and-text">
          <img
            class="icons"
            src="assets/icons/Icon-User-Single-Black-No-Circle.svg"
            alt=""
          />
          <span
            class="medium2"
            i18n="@@createCashoutTask.seller.header"
            >Seller</span
          >
        </div>
        <div>
          {{ sellerName }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Phone.svg" alt="" />
          <span
            class="hide-mobile-s medium2"
            i18n="@@createCashoutTask.seller.mobile"
            >Sellers mobile number</span
          >
          <span
            class="show-mobile-s medium2"
            i18n="@@createCashoutTask.seller.mobileNumber"
            >Mobile number</span
          >
        </div>
        <div>
          {{ cashoutTaskToBeCreated.payeePhoneNumber }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Money-Wallet.svg" alt="" />
          <span
            class="medium2"
            i18n="@@createCashoutTask.taskAmount"
            >Amount</span
          >
        </div>
        <div>
          {{
            cashoutTaskToBeCreated.amount | currency: 'SEK ':'code':'1.2-2':'sv'
          }}
        </div>
      </div>
      <div class="list-row">
        <div class="icon-and-text">
          <img class="icons" src="assets/icons/Icon-Bank.svg" alt="" />
          <span
            class="medium2"
            i18n="@@createCashoutTask.bankAccountNumber"
            >Bank account number</span
          >
        </div>
        <div>
          {{ bankInfoDisplay }}
        </div>
      </div>
    </div>

    <div class="errors-container">
      <app-form-error *ngIf="remoteError" [errorText]="remoteError">
      </app-form-error>
      <div *ngIf="remoteWarnings.length > 0">
        <div class="remote-warning" *ngFor="let warning of remoteWarnings">
          <span>{{ warning }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="continue-back-buttons-container">
    <zaver-primary-button
      *ngIf="remoteWarnings.length > 0"
      text="Confirm anyway"
      i18n-text="@@createCashoutTask.button.altConfirm"
      [loading]="creationInProgress"
      (click)="confirmAndSend()"
    >
    </zaver-primary-button>
    <zaver-primary-button
      *ngIf="remoteWarnings.length === 0"
      text="Confirm and send"
      i18n-text="@@createCashoutTask.button.confirmSend"
      [loading]="creationInProgress"
      (click)="confirmAndSend()"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      text="Back"
      i18n-text="@@createCashoutTask.button.back"
      (click)="returnToBankInfo()"
      [disabled]="creationInProgress"
    ></zaver-secondary-button-subtle>
  </div>
</div>
