import { OnInit, Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Market } from '@core/dto/Market';
import { CompanyRepresentation, CompanyStatus } from '@core/dto/user-details';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-profile-selection-list',
  templateUrl: './profile-selection-list.component.html',
  styleUrls: ['./profile-selection-list.component.css'],
})
export class ProfileSelectionListComponent implements OnInit {
  @Output()
  onProfileSelected = new EventEmitter();

  availableRepresentations: CompanyRepresentation[];

  constructor(
    private appStateService: AppStateService,
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.availableRepresentations = this.appStateService
      .getAvailableRepresentations()
      .filter((rep) =>
        rep.company.status.find((status) => status === CompanyStatus.APPROVED)
      );
  }

  selectProfile(representation: CompanyRepresentation) {
    this.authService.switchProfile(representation).then((userDetails) => {
      this.onProfileSelected.emit(userDetails);
      this.dummyNavigate();
    });
  }

  dummyNavigate() {
    this.router
      .navigate(['/refresh'], {
        skipLocationChange: true,
        replaceUrl: false,
      })
      .then(() => this.router.navigate(['/'], { skipLocationChange: false }))
      .catch(() => this.router.navigate(['/'], { skipLocationChange: false }));
  }
}
