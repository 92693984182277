<form [formGroup]="formGroup" novalidate>
  <div class="input-fields">
    <zaver-form-input
      title="Organization number"
      i18n-title="@@companyForm.companyOwnerLegal.organization.title"
      placeholder="Organization number"
      i18n-placeholder="@@companyForm.companyOwnerLegal.organization.placeholder"
      [error]="
        formGroup.controls.organizationNumber.invalid &&
        (showError || startOrganizationNumberValidation)
      "
      errorMessage="Please enter a valid organization number"
      i18n-errorMessage="@@companyForm.companyOwnerLegal.organization.errorMessage"
      formControlName="organizationNumber"
      [inputMode]="'tel'"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <div class="input-fields">
    <app-form-error
      *ngIf="displayCompanyNotFoundError"
      [errorText]="
        'Vi kan inte hitta ett företag med det angivna organisationsnumret.'
      "
      i18n-errorText="@@companyForm.companyOwnerLegal.errorText"
    >
    </app-form-error>
  </div>
  <div class="input-fields" [class.hide]="!correctOrganizationNumber">
    <zaver-form-input
      title="Company name"
      i18n-title="@@companyForm.companyOwnerLegal.company.title"
      [placeholder]="companyNamePlaceholder"
      formControlName="name"
      [error]="formGroup.controls.name.invalid && showError"
      errorMessage="This field can not be empty"
      i18n-errorMessage="@@companyForm.companyOwnerLegal.company.errorMessage"
      [maxLength]="255"
      [size]="'large'"
    >
    </zaver-form-input>
  </div>
  <ng-container *ngIf="!nonOwner">
    <div class="input-fields" [class.hide]="!correctOrganizationNumber">
      <zaver-form-input
        [showContent]="true"
        title="Shareholding"
        i18n-title="@@companyForm.companyOwnerLegal.shareholder.title"
        placeholder="Shareholding"
        i18n-placeholder="@@companyForm.companyOwnerLegal.shareholder.placeholder"
        [error]="formGroup.controls.holdingShare.invalid && showError"
        errorMessage="Enter shareholding"
        i18n-errorMessage="@@companyForm.companyOwnerLegal.shareholder.errorMessage"
        formControlName="holdingShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
    <div class="input-fields" [class.hide]="!correctOrganizationNumber">
      <zaver-form-input
        [showContent]="true"
        title="Share of votes"
        i18n-title="@@companyForm.companyOwnerLegal.votesShare.title"
        placeholder="Share of votes"
        i18n-placeholder="@@companyForm.companyOwnerLegal.votesShare.placeholder"
        [error]="formGroup.controls.votesShare.invalid && showError"
        errorMessage="Enter share of votes"
        i18n-errorMessage="@@companyForm.companyOwnerLegal.votesShare.errorMessage"
        formControlName="votesShare"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          %
        </span>
      </zaver-form-input>
    </div>
  </ng-container>
</form>
