<h1 class="page-heading">
  <span i18n="@@paymentRequests.title">Payment requests</span>
</h1>

<div id="search-and-filter">
  <div id="input-fields">
    <zaver-search
      id="search-bar"
      placeholder="Search ..."
      i18n-placeholder="@@paymentRequests.search.placeholder"
      [inputModel]="searchQuery"
      (inputModelChange)="searchUpdatedQuery($event)"
    ></zaver-search>

    <zaver-dropdownv2
      id="dropdown"
      *ngIf="canViewStoresFilter"
      [selectableItems]="storesFilterOptions"
      (inputModelChange)="onStoresFilterChange($event)"
    ></zaver-dropdownv2>
  </div>

  <zaver-zfb-add-button
    *ngIf="mayCreatePaymentRequests()"
    text="New payment"
    (click)="openCreatePaymentRequestModal()"
    id="main-create-button"
    i18n-text="@@paymentRequests.button.create"
  ></zaver-zfb-add-button>
</div>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<ng-container *ngIf="merchantHasB2B && merchantHasPaymentRequests">
  <app-page-tabs
    [activeValue]="buyerTypeFilter"
    [tabs]="tabs"
    (tabClicked)="activePageChange($event)"
  ></app-page-tabs>

  <div id="company-filter-wrapper" *ngIf="buyerTypeFilter === 'COMPANY'">
    <app-navigation-tab
      text="Payments"
      i18n-text="@@paymentRequests.b2bTabs.payments"
      [active]="companyPageFilter === ''"
      (clicked)="activeCompanyPageChange('')"
    ></app-navigation-tab>

    <app-navigation-tab
      text="Unmatched payments"
      i18n-text="@@paymentRequests.b2bTabs.unmatchedPayments"
      [badgeNumber]="displayEmptyState ? 0 : nonMatchedPayments.length"
      [active]="companyPageFilter === 'NON_MATCHED'"
      (clicked)="activeCompanyPageChange('NON_MATCHED')"
    ></app-navigation-tab>

    <app-navigation-tab
      [text]="surplusNavTabText"
      [active]="companyPageFilter === 'SURPLUS'"
      [badgeNumber]="displayEmptyState ? 0 : surplusPayments.length"
      (clicked)="activeCompanyPageChange('SURPLUS')"
    ></app-navigation-tab>

    <app-navigation-tab
      [text]="insufficientNavTabText"
      [active]="companyPageFilter === 'INSUFFICIENT'"
      [badgeNumber]="displayEmptyState ? 0 : insufficientPayments.length"
      (clicked)="activeCompanyPageChange('INSUFFICIENT')"
    ></app-navigation-tab>
  </div>
</ng-container>

<ng-container *ngIf="fetchingResponse || fetchingNonMatchingPayments">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>

<ng-container *ngIf="!fetchingResponse && !fetchingNonMatchingPayments">
  <ng-container *ngIf="!merchantHasB2B">
    <ng-container *ngIf="merchantHasPaymentRequests">
      <app-table
        [columns]="columns"
        [values]="paymentRequests"
        (filterChange)="onActiveStatusChange($event)"
      ></app-table>

      <app-table-pagination
        *ngIf="paymentRequests.rows.length > 0"
        [paginationState]="paginationState"
        (pageChanged)="onPageChange('', $event)"
        (pageSizeChanged)="onPageSizeChange('', $event)"
      ></app-table-pagination>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="merchantHasB2B">
    <ng-container *ngIf="merchantHasPaymentRequests">
      <ng-container
        *ngIf="
          buyerTypeFilter === 'CONSUMER,COMPANY' ||
          (buyerTypeFilter === 'CONSUMER' &&
            merchantHasConsumerPaymentRequests) ||
          (buyerTypeFilter === 'COMPANY' &&
            companyPageFilter === '' &&
            merchantHasCompanyPaymentRequests)
        "
      >
        <app-table
          *ngIf="!displayEmptyState"
          [columns]="columns"
          [values]="paymentRequests"
          (filterChange)="onActiveStatusChange($event)"
        ></app-table>

        <app-table-pagination
          *ngIf="paymentRequests.rows.length > 0"
          [paginationState]="paginationState"
          (pageChanged)="onPageChange('', $event)"
          (pageSizeChanged)="onPageSizeChange('', $event)"
        ></app-table-pagination>
      </ng-container>

      <ng-container
        *ngIf="buyerTypeFilter === 'COMPANY' && companyPageFilter === 'SURPLUS'"
      >
        <app-table
          *ngIf="surplusPayments.length > 0 && !displayEmptyState"
          [columns]="surplusPaymentColumns"
          [values]="currentPageOfSurplusPayments"
        ></app-table>

        <app-table-pagination
          *ngIf="currentPageOfSurplusPayments.rows.length > 0 && !displayEmptyState"
          [paginationState]="surplusPaymentsPaginationState"
          (pageChanged)="onPageChange('SURPLUS', $event)"
          (pageSizeChanged)="onPageSizeChange('SURPLUS', $event)"
        ></app-table-pagination>
      </ng-container>
      <ng-container
        *ngIf="
          buyerTypeFilter === 'COMPANY' && companyPageFilter === 'INSUFFICIENT'
        "
      >
        <app-table
          *ngIf="insufficientPayments.length > 0 && !displayEmptyState"
          [columns]="insufficientPaymentColumns"
          [values]="currentPageOfInsufficientPayments"
        ></app-table>

        <app-table-pagination
          *ngIf="currentPageOfInsufficientPayments.rows.length > 0 && !displayEmptyState"
          [paginationState]="insufficientPaymentsPaginationState"
          (pageChanged)="onPageChange('INSUFFICIENT', $event)"
          (pageSizeChanged)="onPageSizeChange('INSUFFICIENT', $event)"
        ></app-table-pagination>
      </ng-container>
      <ng-container
        *ngIf="
          buyerTypeFilter === 'COMPANY' && companyPageFilter === 'NON_MATCHED'
        "
      >
        <app-table
          *ngIf="nonMatchedPayments.length > 0 && !displayEmptyState"
          [columns]="nonMatchedPaymentsColumns"
          [values]="currentPageOfNonMatchedPayments"
        ></app-table>

        <app-table-pagination
          *ngIf="currentPageOfNonMatchedPayments.rows.length > 0 && !displayEmptyState"
          [paginationState]="nonMatchedPaymentsPaginationState"
          (pageChanged)="onPageChange('NON_MATCHED', $event)"
          (pageSizeChanged)="onPageSizeChange('NON_MATCHED', $event)"
        ></app-table-pagination>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
</app-empty-state>

<zaver-zfb-add-button
  *ngIf="!fetchingResponse"
  text="New payment"
  (click)="openCreatePaymentRequestModal()"
  class="mobile-cta"
  i18n-text="@@paymentRequests.button.create"
></zaver-zfb-add-button>

<!-- Modal -->
<zaver-form-modal
  *ngIf="activeModalComponent"
  (onClose)="deactivateModal()"
  [title]="modalTitle"
  [color]="modalColor"
  [ngSwitch]="activeModalComponent"
  [modalBodyVisible]="activeModalComponent === 'CREATE_REMINDER'"
  [preventClosingmodalByAccident]="preventClosingmodalByAccident"
>
  <app-create-payment-request
    *ngSwitchCase="'CREATE_PAYMENT'"
    (onChangeModalHeader)="handleModalHeaderChange($event)"
    (onDone)="handleModalEvent(activeModalComponent, $event)"
    (onClose)="deactivateModal()"
  ></app-create-payment-request>

  <app-view-payment-request
    *ngSwitchCase="'VIEW_PAYMENT'"
    [paymentRequest]="selectedPaymentRequest"
    (openCancelModal)="openPaymentRequestCancelModal($event, false)"
    (openCreateRefundModal)="openCreateRefundModal($event, false)"
    (openCreateReminderModal)="openCreateReminderModal($event, false)"
  ></app-view-payment-request>

  <app-create-payment-request-reminder
    *ngSwitchCase="'CREATE_REMINDER'"
    [paymentRequest]="selectedPaymentRequest"
    [reminderRequest]="currentReminderRequest"
    (returnToView)="returnToMainModal(activeModalComponent)"
    (promptReminderConfirmation)="openReminderConfirmationModal($event)"
  ></app-create-payment-request-reminder>

  <app-match-payments
    *ngSwitchCase="'MATCH_PAYMENTS'"
    [nonMatchedPayment]="selectedNonMatchedPayment"
    (close)="deactivateModal()"
    (promptConfirmation)="openPaymentMatchConfirmationModal($event)"
  ></app-match-payments>

  <app-create-refund
    *ngSwitchCase="'CREATE_REFUND'"
    [paymentRequest]="selectedPaymentRequest"
    (refundCreated)="handleModalEvent(activeModalComponent)"
  ></app-create-refund>
</zaver-form-modal>

<zaver-details-modal
  *ngIf="!!activeSmallModalComponent"
  (onClose)="deactivateSmallModal()"
  [title]="modalTitle"
  [color]="modalColor"
  [ngSwitch]="activeSmallModalComponent"
>
  <div
    class="small-modal-wrapper"
    *ngSwitchCase="'PAYMENT_LINK_CREATED_COPY_LINK'"
  >
    <div class="link_container" (click)="copyCheckOutLink()">
      <img class="icons" src="assets/icons/Icon-Link.svg" alt="" />
      <p>https://checkout.zaver.com?id=emNvdG93Y5...</p>
    </div>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        (click)="deactivateSmallModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>

      <zaver-primary-button
        style="width: auto"
        text="Copy link"
        [color]="'black'"
        (click)="copyCheckOutLink()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <div class="small-modal-wrapper" *ngSwitchCase="'PAYMENT_CREATED'">
    <span i18n="@@paymentRequests.modal.paymentCreated.text"
      >Done! Your payment link has been sent. 🙂</span
    >

    <div class="button-container">
      <zaver-primary-button
        style="width: auto"
        text="Close"
        i18n-text="@@paymentRequests.modal.paymentCreated.confirmButton.text"
        [color]="'black'"
        (click)="deactivateSmallModal()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <div class="small-modal-wrapper" *ngSwitchCase="'B2B_PAYMENT_CREATED'">
    <span i18n="@@paymentRequests.modal.paymentCreatedB2b.text"
      >Done! The payment instructions are on their way to your customer. 🙂</span
    >

    <div class="button-container">
      <zaver-primary-button
        style="width: auto"
        text="Close"
        i18n-text="@@paymentRequests.modal.paymentCreatedB2b.confirmButton.text"
        [color]="'black'"
        (click)="deactivateSmallModal()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <app-cancel-payment-request
    *ngSwitchCase="'CANCEL_PAYMENT'"
    [selectedPaymentRequest]="selectedPaymentRequest"
    (onReturnToMainModal)="returnToMainModal(activeSmallModalComponent)"
    (onCloseModal)="deactivateSmallModal()"
    (onCancelled)="handleModalEvent(activeSmallModalComponent)"
  >
  </app-cancel-payment-request>
  <app-surplus-payment-contact-confirmation
    *ngSwitchCase="'CONFIRM_SURPLUS'"
    [selectedSurplusPayment]="selectedSurplusPayment"
    (onCloseModal)="deactivateSmallModal()"
    (onSent)="handleModalEvent(activeSmallModalComponent)"
  ></app-surplus-payment-contact-confirmation>

  <app-confirm-payment-request-reminder
    *ngSwitchCase="'CONFIRM_REMINDER'"
    [reminderRequest]="currentReminderRequest"
    [paymentRequest]="selectedPaymentRequest"
    (onCloseModal)="deactivateSmallModal()"
    (onReturnToMainModal)="returnToMainModal(activeSmallModalComponent)"
  ></app-confirm-payment-request-reminder>

  <app-confirm-match
    *ngSwitchCase="'CONFIRM_MATCH'"
    [matchRequest]="nonMatchingMatchRequest"
    (onDone)="handleModalEvent(activeSmallModalComponent)"
    (onCloseModal)="deactivateSmallModal()"
    (onReturnToMainModal)="returnToMainModal(activeSmallModalComponent)"
  ></app-confirm-match>
</zaver-details-modal>
