<h1 class="page-heading" i18n="@@precheck.se.header">Test your purchasing power</h1>

<div class="intro-paragraph" i18n="@@precheck.se.intro">
  By assessing the customer's creditworthiness, you can make a preliminary
  determination of their eligibility for invoice or installment payments through
  Zaver. The customer must provide consent beforehand, as a credit report will
  be conducted and its result sent to them via SMS.
</div>

<div id="precheck-container">
  <div id="precheck-form-results-container">
    <form [formGroup]="form" novalidate>
      <div class="input-fields">
        <zaver-form-input
          title="Customer's social security number"
          i18n-title="@@precheck.se.form.personalNumber.title"
          placeholder="yyyymmddxxxx"
          i18n-placeholder="@@precheck.se.form.personalNumber.placeholder"
          [error]="form.controls.personalNumber.invalid && showError"
          errorMessage="Please enter a valid social security number"
          i18n-errorMessage="@@precheck.se.form.personalNumber.errorMessage"
          formControlName="personalNumber"
          inputMode="numeric"
          [hideLabelsMobile]="false"
        >
        </zaver-form-input>
      </div>
      <div class="input-fields">
        <zaver-form-input
          title="Customer's mobile number"
          i18n-title="@@precheck.se.form.mobileNumber.title"
          placeholder="Enter mobile number"
          i18n-placeholder="@@precheck.se.form.mobileNumber.placeholder"
          [error]="form.controls.phoneNumber.invalid && showError"
          errorMessage="Please check the mobile number"
          i18n-errorMessage="@@precheck.se.form.mobileNumber.errorMessage"
          formControlName="phoneNumber"
          inputMode="tel"
          [hideLabelsMobile]="false"
        >
        </zaver-form-input>
      </div>
      <div class="input-fields" style="margin-bottom: 40px">
        <zaver-form-input
          title="Amount for credit check"
          i18n-title="@@precheck.se.form.amount.title"
          placeholder="Enter amount"
          i18n-placeholder="@@precheck.se.form.amount.placeholder"
          [error]="form.controls.amount.invalid && showError"
          errorMessage="Enter an amount between 2 000 kr and 200 000 kr"
          i18n-errorMessage="@@precheck.se.form.amount.errorMessage"
          formControlName="amount"
          [inputMode]="'numeric'"
          [showContent]="true"
          [hideLabelsMobile]="false"
        >
          <span style="text-align: center; font-weight: 400; font-size: 14px">
            {{ defaultCurrency }}
          </span>
        </zaver-form-input>
      </div>

      <div id="precheck-cta">
        <zaver-primary-button
          text="Test purchasing power"
          i18n-text="@@precheck.se.form.button.text"
          [loading]="fetchingResponse"
          (click)="submit()"
        >
        </zaver-primary-button>
      </div>
    </form>
    <ng-container *ngIf="displayResults && !fetchingResponse">
      <hr />
      <div id="results-heading" i18n="@@precheck.se.result.title">Result</div>
      <!-- At least one granted (Start)-->
      <ng-container *ngIf="precheckSummary.atLeastOneGranted">
        <div id="results-info-text" i18n="@@precheck.se.result.name">
          We can offer {{ precheckSummary.personName }}:
        </div>
        <ng-container *ngIf="precheckSummary.grantedInvoice">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Invoice.svg" alt="" />
            <span i18n="@@precheck.se.result.amount">Invoice amount</span>
          </div>
          <div *ngIf="precheckSummary.grantedInvoice" class="invoice-response">
            <img
              src="assets/icons/Icon-Checkmark-Green.svg"
              alt="Invoice icon"
            />
            <span>{{
              precheckAmount | currency: 'SEK':'symbol':'.2':'sv'
            }}</span>
          </div>
          <div
            *ngIf="!precheckSummary.grantedInvoice"
            class="invoice-response invoice-not-granted"
          >
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span i18n="@@precheck.se.result.invoice.denial">
              Unfortunately we are unable to grant
              {{ precheckSummary.personName }} "invoice" of
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Try
              again with a lower amount.
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="precheckSummary.summaryEntries.length > 0">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Cake.svg" alt="Installments icon" />
            <span i18n="@@precheck.se.result.terms">Installment payment terms</span>
          </div>
          <div id="installments-container">
            <div
              class="installments-row"
              *ngFor="let entry of precheckSummary.summaryEntries"
            >
              <div class="installments-icon-and-text">
                <img
                  src="assets/icons/Icon-Checkmark-Green.svg"
                  alt="Invoice icon"
                />
                <span i18n="@@precheck.se.result.installment">
                  {{
                    entry.monthlyCost | currency: 'SEK':'symbol':'.2':'sv'
                  }}/mo in {{ entry.numberOfMonths }} month(s)
                </span>
              </div>
              <div class="installments-interest regular" i18n="@@precheck.se.result.nominalInterest">
                {{ entry.nominalInterest }}% annual interest
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- At least one granted (End)-->

      <!-- Less than one granted (Start)-->
      <ng-container *ngIf="!precheckSummary.atLeastOneGranted">
        <ng-container *ngIf="!precheckSummary.grantedInvoice">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Invoice.svg" alt="" />
            <span i18n="@@precheck.se.result.amount">Invoice amount</span>
          </div>
          <div
            *ngIf="!precheckSummary.grantedInvoice"
            class="invoice-response invoice-not-granted"
          >
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span i18n="@@precheck.se.result.invoice.denial">
              Unfortunately we are unable to grant
              {{ precheckSummary.personName }} "invoice" of
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Try
              again with a lower amount.
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="precheckSummary.summaryEntries.length == 0">
          <div class="results-product-heading">
            <img src="assets/icons/Icon-Cake.svg" alt="Installments icon" />
            <span i18n="@@precheck.se.result.terms">Installment payment terms</span>
          </div>
          <div class="invoice-response invoice-not-granted">
            <img src="assets/icons/Icon-Cross-Cerise.svg" alt="" />
            <span i18n="@@precheck.se.result.annuity.denial">
              Unfortunately we are unable to grant
              {{ precheckSummary.personName }} "installments" of
              {{ precheckAmount | currency: 'SEK':'symbol':'.2':'sv' }}. Try
              again with a lower amount.
            </span>
          </div>
        </ng-container>
      </ng-container>
      <!-- Less than one granted (End)-->
    </ng-container>
  </div>
</div>
<div *ngIf="errorMessage" id="app-error">
  <img
    src="assets/icons/Icon-Error.svg"
    alt="Error circle"
    style="margin-right: 20px"
  />
  <span>{{ errorMessage }}</span>
</div>
