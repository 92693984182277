<div class="datepicker">
  <zaver-dropdownv2
    id="dropdown"
    [selectedItem]="{ label: selectLabel, value: null }"
    errorMessage="Please select an option"
    i18n-errorMessage="@@dateRangePicker.dropdown.errorMessage"
    (click)="onClickSelect()"
  ></zaver-dropdownv2>

  <zaver-details-modal
    *ngIf="modalActive"
    (onClose)="onCloseModal()"
    title="Time interval"
    i18n-title="@@dateRangePicker.smallModal.title"
    [color]="colorService.getCeriseColorCode()"
  >
    <div class="small-modal-wrapper">
      <form [formGroup]="form" novalidate>
        <div class="input-wrapper">
          <div class="input">
            <div class="input-help-text">
              <span i18n="@@dateRangePicker.inputHelpText.fromDate"
                >From</span
              >
            </div>
            <zaver-form-input
              [placeholder]="dateFormatPlaceholder"
              formControlName="startDate"
              [maxLength]="10"
            ></zaver-form-input>
          </div>

          <div class="input">
            <div class="input-help-text">
              <span i18n="@@dateRangePicker.inputHelpText.endDate"
                >Until</span
              >
            </div>
            <zaver-form-input
              [placeholder]="dateFormatPlaceholder"
              formControlName="endDate"
              [maxLength]="10"
            >
            </zaver-form-input>
          </div>
        </div>

        <p
          class="error-message"
          *ngIf="
            (form.controls.startDate.invalid ||
              form.controls.endDate.invalid) &&
            showError
          "
        >
          {{ errorMessage }}
        </p>
      </form>

      <div class="footer-buttons">
        <zaver-secondary-button-subtle
          style="width: auto; margin-right: 8px"
          (click)="onClose()"
          text="Cancel"
          i18n-text="@@dateRangePicker.smallModal.cancelButton"
          [size]="'x-small'"
        >
        </zaver-secondary-button-subtle>
        <zaver-primary-button
          style="width: auto"
          (click)="onSave()"
          text="Save"
          i18n-text="@@dateRangePicker.smallModal.saveButton"
          [size]="'x-small'"
        >
        </zaver-primary-button>
      </div>
    </div>
  </zaver-details-modal>

  <div class="date-range-picker" *ngIf="showCalendar">
    <div class="legend" *ngIf="!deviceIsMobile()">
      <button
        *ngFor="let button of buttonOptions"
        (click)="legendButtonClick(button)"
        [ngClass]="button.focus ? 'legend-button-focus' : ''"
      >
        {{ button.text }}
      </button>
    </div>

    <div class="mobile-header cerise-bg" *ngIf="deviceIsMobile()">
      <div class="header-buttons">
        <button (click)="onClose()">
          <img
            class="exit-close-icon"
            src="assets/icons/Icon-Navigation-Exit-Close.svg"
            alt="exit-close-icon"
          />
        </button>
        <button (click)="onSave()">
          <p i18n="@@dateRangePicker.mobileHeader.saveButton">Save</p>
        </button>
      </div>
      <div class="top-text">
        <p>{{ mobilelabel }}</p>
        <button (click)="openModal()">
          <img
            class="icon-edit"
            src="assets/icons/Icon-Edit-White.svg"
            alt="icon-edit"
          />
        </button>
      </div>
    </div>

    <div class="calendar-wrapper">
      <app-datepicker-calendar
        #CalendarComponent
        class="calendar"
        [(date)]="startDate"
        [closeCalendar]="updateCalendar"
        [minDate]="minDate"
        [maxDate]="endDate"
        [isRangeStart]="true"
      >
      </app-datepicker-calendar>
      <app-datepicker-calendar
        #CalendarComponent
        class="calendar"
        [(date)]="endDate"
        [closeCalendar]="updateCalendar"
        [minDate]="startDate"
        [maxDate]="limitDate"
        [isRangeEnd]="true"
      >
      </app-datepicker-calendar>

      <div *ngIf="!deviceIsMobile()">
        <hr class="divider" />
        <div class="footer-body">
          <form [formGroup]="form" novalidate>
            <div class="input-wrapper">
              <div class="input">
                <zaver-form-input
                  [placeholder]="dateFormatPlaceholder"
                  formControlName="startDate"
                  [maxLength]="10"
                ></zaver-form-input>
              </div>
              <img
                class="arrow-right-icon"
                src="assets/icons/Icon-Vector-Right.svg"
                alt="icon-exit-close"
              />
              <div class="input">
                <zaver-form-input
                  [placeholder]="dateFormatPlaceholder"
                  formControlName="endDate"
                  [maxLength]="10"
                >
                </zaver-form-input>
              </div>
            </div>

            <p
              class="error-message"
              *ngIf="
                (form.controls.startDate.invalid ||
                  form.controls.endDate.invalid) &&
                showError
              "
            >
              {{ errorMessage }}
            </p>
            <p
              class="input-info-text"
              *ngIf="startDateString === endDateString"
            >
              <span i18n="@@dateRangePicker.footer.infoText.sameDate"
                >Payments completed {{ endDateString }} included in the
                file</span
              >
            </p>
            <p
              class="input-info-text"
              *ngIf="startDateString !== endDateString"
            >
              <span i18n="@@dateRangePicker.footer.infoText.differentDates"
                >Payments completed up to {{ endDateString }} included
                in the file</span
              >
            </p>
          </form>

          <div class="footer-buttons">
            <zaver-secondary-button-subtle
              style="margin-right: 8px"
              (click)="onClose()"
              text="Cancel"
              [size]="'x-small'"
              i18n-text="@@dateRangePicker.footer.backButton"
            >
            </zaver-secondary-button-subtle>
            <zaver-primary-button
              (click)="onSave()"
              text="Save"
              [size]="'x-small'"
              i18n-text="@@dateRangePicker.footer.saveButton"
            >
            </zaver-primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="datepicker-background"
  (click)="onClose()"
  *ngIf="showCalendar"
></div>
