<div id="form-wrapper" class="spacing-container" *ngIf="!loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="!loading">
    <div class="input-fields">
      <zaver-dropdown
        title="Role"
        i18n-title="@@editCompanyRepresentative.form.role.title"
        [inputModel]="selectedRole.id"
        [selectOptions]="roleSelectionValues"
        (inputModelChange)="onRoleChanged($event)"
        [size]="'large'"
      >
      </zaver-dropdown>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.email.invalid && showError"
    >
      <zaver-form-input
        title="Email address"
        placeholder="Enter email address"
        [error]="form.controls.email.invalid && showError"
        errorMessage="Please enter a valid email address"
        formControlName="email"
        [maxLength]="255"
        [inputMode]="'email'"
        [size]="'large'"
        i18n-title="@@editCompanyRepresentative.form.email.title"
        i18n-placeholder="@@editCompanyRepresentative.form.email.placeholder"
        i18n-errorMessage="@@editCompanyRepresentative.form.email.errorMessage"
      >
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      *ngIf="showPhoneNumberInput"
      [class.error]="form.controls.phone.invalid && showError"
    >
      <zaver-form-input
        title="Mobile number"
        placeholder="Enter mobile number"
        [error]="form.controls.phone.invalid && showError"
        [errorMessage]="getPhoneErrorMessage()"
        formControlName="phone"
        [inputMode]="'tel'"
        [size]="'large'"
        i18n-title="@@editCompanyRepresentative.form.phone.title"
        i18n-placeholder="@@editCompanyRepresentative.form.phone.placeholder"
        [showContent]="market === 'DE' || market === 'AT'"
      >
        <zaver-select-country
          *ngIf="market === 'DE' || market === 'AT'"
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      *ngIf="selectedRole.identified"
      [class.error]="form.controls.personalNumber.invalid && showError"
    >
      <zaver-form-input
        [title]="'Personnummer'"
        [helpTitle]="'(ååååmmddxxxx)'"
        [placeholder]="'ååååmmddxxxx'"
        [error]="form.controls.personalNumber.invalid && showError"
        [errorMessage]="'Vänligen ange ett giltigt personnummer (ååååmmddxxxx)'"
        formControlName="personalNumber"
        [inputMode]="'numeric'"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields">
      <zaver-dropdown
        title="Connect to..."
        i18n-title="@@editCompanyRepresentative.form.store.title"
        [inputModel]="selectedStore"
        [selectOptions]="storeOptions"
        (inputModelChange)="onStoreChanged($event)"
        *ngIf="canSeeStores"
        [size]="'large'"
        [shorterDropdown]="true"
        initialLabel="Choose"
        i18n-initialLabel="@@editCompanyRepresentative.form.store.initialLabel"
      >
      </zaver-dropdown>
    </div>
    <div class="input-fields">
      <div *ngIf="canSeeStores && selectedStore != ''" class="input-section">
        <div class="input-heading">
          <span
            i18n="
              @@editCompanyRepresentative.form.storeRelation.permission.title"
            >User can manage</span
          >
        </div>
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedStorePermissionOption(true)"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="handleAllActivities"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="
                @@editCompanyRepresentative.form.storeRelation.permission.storeActivities"
              >{{ getStoreAlias() }} activities</span
            >
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedStorePermissionOption(false)"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="!handleAllActivities"
            ></zaver-zfb-radio-button>
            <span
              class="radio-button-label"
              i18n="
                @@editCompanyRepresentative.form.storeRelation.permission.ownActivities"
              >Only own activities</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="error-container">
      <app-form-error
        *ngIf="errorMessage"
        [errorText]="defaultEditUserErrorMessage"
      >
      </app-form-error>
    </div>
  </form>
  <div class="two-cta-buttons" *ngIf="mayEditUser()">
    <zaver-primary-button
      text="Update"
      i18n-text="@@editCompanyRepresentative.form.button.submit"
      [loading]="submitting"
      (click)="submit()"
      [disabled]="activating || inactivating"
    >
    </zaver-primary-button>
    <zaver-secondary-button-subtle
      *ngIf="representative.status === 'ACTIVE'"
      text="Deactivate"
      i18n-text="@@editCompanyRepresentative.form.button.inactivate"
      (click)="inactivate()"
      [disabled]="submitting"
      [loading]="inactivating"
    >
    </zaver-secondary-button-subtle>
    <zaver-secondary-button-subtle
      *ngIf="representative.status === 'INACTIVE'"
      text="Activate"
      i18n-text="@@editCompanyRepresentative.form.button.activate"
      (click)="activate()"
      [disabled]="submitting"
      [loading]="activating"
    >
    </zaver-secondary-button-subtle>
  </div>
</div>
