import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CompanyRepresentationRoleColorRepresentations } from '@assets/language/RoleColorRepresentation';
import { CompanyRepresentativeRole } from '@core/dto/CompanyRepresentativeRole';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { LocaleService } from '@core/service/locale.service';

export class RoleViewModel extends CompanyRepresentativeRole {
  selectable?: boolean;

  constructor(role: CompanyRepresentativeRole) {
    super(role);
  }
}

@Component({
  selector: 'app-create-company-representative',
  templateUrl: './create-company-representative.component.html',
  styleUrls: ['./create-company-representative.component.css'],
})
export class CreateCompanyRepresentativeComponent implements OnInit {
  @Output() submitClicked = new EventEmitter();
  @Output() roleChanged = new EventEmitter<{
    roleText: string;
    roleColor: string;
  }>();

  // Roles that the representative can be created with
  createableRoles: CompanyRepresentativeRole[];
  // The roles to be displayed. Contains all roles in creatableRoles, and optional additional roles for display.
  displayRoles: RoleViewModel[];
  selectedRole: CompanyRepresentativeRole;
  initialRoleSelection = true;

  constructor(
    public access: AccessControlService,
    private appStateService: AppStateService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.setupDisplayRoles();
    this.selectedRole = this.displayRoles.filter(
      (role) => !!role.selectable
    )[0];
  }

  roleRadioButton(role: CompanyRepresentativeRole) {
    this.selectedRole = role;
  }

  continue() {
    let colorInfo = CompanyRepresentationRoleColorRepresentations.get(
      this.selectedRole.color
    );
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    this.createableRoles = this.appStateService
      .getMerchantRoles()
      .map((merchantRole) => merchantRole.role)
      .filter((role) => this.access.mayCreateUserWithRole(role));
    this.roleChanged.emit({ roleText: null, roleColor: colorInfo.hexColor });
    this.initialRoleSelection = false;
  }

  submit() {
    this.submitClicked.emit();
  }

  setupDisplayRoles() {
    const displayRoles: RoleViewModel[] = [];
    const merchantRoles = this.appStateService.getMerchantRoles();

    merchantRoles.forEach((merchantRole) => {
      const mayCreateUserWithRole = this.access.mayCreateUserWithRole(
        merchantRole.role
      );
      if (mayCreateUserWithRole) {
        const roleVM = new RoleViewModel(merchantRole.role);
        roleVM.selectable = true;
        displayRoles.push(roleVM);
      } else {
        // If the role is not allowed to be created, we check if the role matches
        // the role of the current user, if it does we display the role with additional
        // text, but the role is not selectable
        if (
          this.appStateService.getCurrentUser().activeRepresentation.role.id ===
          merchantRole.role.id
        ) {
          const roleVM = new RoleViewModel(merchantRole.role);
          roleVM.selectable = false;
          roleVM.displayDescription =
            merchantRole.role.displayDescription + ' ';
          $localize`:@@createCompanyRepresentative.noneCreateableRole.infoText:Please contact us in order to create more users with this role <a href="mailto:merchants@zaver.se" class="link">merchants@zaver.se.</a>`;
          displayRoles.push(roleVM);
        }
      }
    });
    this.displayRoles = displayRoles;
  }
}
