<div id="container">
  <div id="detail-container">
    <div class="detail">
      <span
        class="detail-span"
        i18n="@@accountingReport.viewDisbursement.reference"
        ><img
          class="icons"
          src="assets/icons/Icon-Bank.svg"
          alt=""
        />Bank reference</span
      >
      <span>{{ reportDisbursement.paymentReference }}</span>
    </div>

    <div class="detail">
      <span
        class="detail-span"
        i18n="@@accountingReport.viewDisbursement.amount"
      >
        <img
          class="icons"
          src="assets/icons/Icon-Money-Wallet.svg"
          alt=""
        />Amount</span
      >
      <span [style.color]="reportDisbursement.reportTypeColor">{{
        reportDisbursement.value | value: localeService.getCurrentLocale()
      }}</span>
    </div>
  </div>

  <zaver-search
    id="search-bar"
    placeholder="Search title, amount, description ..."
    i18n-placeholder="@@accountingReport.search.placeholder"
    [inputModel]="searchQuery"
    (inputModelChange)="searchUpdatedQuery($event)"
  ></zaver-search>

  <div id="summaries">
    <span i18n="@@accountingReport.summary.transactions">Transactions:</span>
    <span
      id="transactions-span"
      i18n="@@accountingReport.summary.transactionsAmount"
      *ngIf="
        reportPaymentRequests &&
        reportPaymentRequests.length === reportDisbursement.count
      "
      >{{ reportDisbursement.count }} pcs</span
    >
    <span
      id="transactions-span"
      i18n="@@accountingReport.disbursement.transactionsOf"
      *ngIf="
        reportPaymentRequests &&
        reportPaymentRequests.length !== reportDisbursement.count
      "
      >{{ reportPaymentRequests.length }} of {{
        reportDisbursement.count
      }} pcs</span
    >
  </div>

  <div [ngClass]="(selectedReportPaymentRequest === pr ) ? 'payment-request-container' : ' payment-request-container payment-request-container-hover'" *ngFor="let pr of reportPaymentRequests">
    <div id="payment-request-info" (click)="openReportPaymentRequest(pr)">
      <div id="payment-request-info-rows">
        <div id="title-container">
          <span id="title-span">
            {{ pr.title }}
          </span>
        </div>

        <div id="payment-request-info-row">
          <span i18n="@@accountingReport.viewDisbursement.paymentRequest.amount"
            >Amount:</span
          >
          <span
            id="amount-span"
            [style.color]="reportDisbursement.reportTypeColor"
            *ngIf="pr.value.amount === pr.paymentRequestValue.amount"
          >
            {{ pr.value | value: localeService.getCurrentLocale() }}
          </span>
          <span
            id="amount-span"
            *ngIf="pr.value.amount !== pr.paymentRequestValue.amount"
          >
            <div [style.color]="reportDisbursement.reportTypeColor">{{ pr.value | value: localeService.getCurrentLocale() }}</div>
            <div>&nbsp;/&nbsp;{{ pr.paymentRequestValue | value: localeService.getCurrentLocale()}} </div>
          </span>
        </div>
      </div>

      <img
        *ngIf="!selectedReportPaymentRequest"
        id="arrow"
        class="icons"
        src="assets/icons/Icon-Navigation-Arrow-Down.svg"
        alt=""
      />
      <img
        *ngIf="
          selectedReportPaymentRequest && selectedReportPaymentRequest === pr
        "
        id="arrow"
        class="icons"
        src="assets/icons/Icon-Navigation-Arrow-Up.svg"
        alt=""
      />
    </div>

    <div
      id="payment-request-info-expanded"
      *ngIf="
        selectedReportPaymentRequest && selectedReportPaymentRequest === pr
      "
    >
      <div id="disbursement-date-container">
        <span i18n="@@accountingReport.disbursement.confirmationDate"
          >Confirmation date:</span
        >
        <span id="disbursement-date-span">{{ selectedReportPaymentRequest.disbursementDate }}</span>
      </div>

      <span id="description-span">{{
        selectedReportPaymentRequest.description
      }}</span>

      <button
        id="view-payment-request-button"
        i18n="@@accountingReport.disbursement.viewPaymentRequest"
        (click)="viewPaymentRequest(selectedReportPaymentRequest)"
      >
        Show payment request
      </button>
    </div>
  </div>
</div>
