import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { SignupQuestions } from '@core/dto/signup/SignupQuestions';
import { AuthenticationService } from '@core/service/authentication.service';
import { QuestionsService } from '@core/service/questions.service';

@Component({
  selector: 'app-detailed-questions-form-confirmation',
  templateUrl: './detailed-questions-form-confirmation.component.html',
  styleUrls: ['./detailed-questions-form-confirmation.component.css'],
})
export class DetailedQuestionsFormConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() signupQuestionsToCreate: SignupQuestions;

  @Output() onAbort = new EventEmitter();
  @Output() onSubmit = new EventEmitter<SignupQuestions>();

  submitting: boolean = false;
  serverErrorMessage: string =
    $localize`:@@detailedQuestionsForm.serverErrorMessage:Changes couldn't be saved. Please try again or cancel by closing. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;

  constructor(
    auth: AuthenticationService,
    private questionsService: QuestionsService
  ) {
    super(auth);
  }

  ngOnInit(): void {}

  closeModal() {
    this.onAbort.emit();
  }

  submit() {
    this.submitting = true;
    this.questionsService
      .create(this.signupQuestionsToCreate)
      .then((response) => {
        this.onSubmit.emit(response);
      })
      .catch((error) => {
        this.handleError(error);
        this.submitting = false;
      });
  }
}
