<!-- horizontal menu for large screens, i.e. desktops/laptops -->
<div
  [class]="
    hasSelectedProfile() ? 'header-content' : 'header-content signed-out'
  "
>
  <div id="logo">
    <a [routerLink]="['/']" (click)="logoClicked()">
      <img src="{{ getLogoPath() }}" alt="Zaver for Business" />
    </a>
  </div>

  <!-- main menu, represented as dropdown menu on small screens/mobile and as a horizontal menu on larger screens -->
  <div class="main-menu" *ngIf="isLoggedIn() && isApproved()">
    <div class="menu-item" *ngIf="hasSelectedProfile()">
      <a
        [routerLink]="['/paymentRequests']"
        routerLinkActive="active"
        [skipLocationChange]="false"
        i18n="@@header.mainMenu.paymentRequests.text"
      >
        Payments
      </a>
    </div>
    <div class="menu-item" *ngIf="mayViewCashout()">
      <a
        [routerLink]="['/cashout']"
        routerLinkActive="active"
        [skipLocationChange]="false"
      >
        Cashout
      </a>
    </div>
    <div class="menu-item" *ngIf="mayViewPrecheck()">
      <a
        [routerLink]="['/precheck']"
        routerLinkActive="active"
        [skipLocationChange]="false"
        i18n="@@header.mainMenu.precheck.text"
      >
        Precheck
      </a>
    </div>
    <div class="menu-item" *ngIf="mayDisplayAdminMenu()">
      <a
        [routerLink]="navigateOnOpenAdminMenu()"
        routerLinkActive="active"
        [skipLocationChange]="false"
        i18n="@@header.mainMenu.administration.text"
      >
        Administration
      </a>
    </div>
  </div>
  <div class="other-menu-items-container">
    <div
      *ngIf="!isLoggedIn() || !isApproved()"
      class="menu-item other-menu-item"
    >
      <a
        [href]="contactLink()"
        target="_blank"
        i18n="@@header.mainMenu.contact.text"
        >Contact</a
      >
    </div>
    <div
      *ngIf="isLoggedIn() && !isApproved()"
      class="menu-item other-menu-item"
    >
      <a (click)="openLogoutModal()" i18n="@@header.mainMenu.logOut.text"
        >Sign out</a
      >
    </div>
    <div class="menu-item is-separate" *ngIf="!isLoggedIn() || !isApproved()">
      <select-locale
        [activeLocale]="activeLocale"
        [locales]="locales"
        (selectOption)="localeChanged($event)"
    ></select-locale>
    </div>
  </div>

  <div
    class="profile-menu"
    *ngIf="showProfileMenu && hasSelectedProfile() && isApproved()"
    #profileMenu
  >
    <div
      *ngIf="hasMultipleApprovedProfiles()"
      (click)="openProfileSelectModal()"
      class="menu-item profile-menu-item"
    >
      <a class="profile-menu-link">
        <div class="profile-menu-div">
          <span
            class="profile-menu-item-text"
            i18n="@@header.mainMenu.switchProfile.text"
            >Change profile</span
          >
          <span class="profile-menu-item-text2">{{
            user.activeRepresentation.company.name
          }}</span>
        </div>
        <app-chevron-right></app-chevron-right>
      </a>
    </div>
    <div
      *ngIf="hasMultipleApprovedProfiles()"
      (click)="openLogoutModal()"
      class="menu-item profile-menu-item"
      id="logout-with-change"
    >
      <a (click)="openLogoutModal()" class="profile-menu-link">
        <div class="profile-menu-div">
          <span
            class="profile-menu-item-text cerise-text"
            i18n="@@header.profileMenu.logOut.text"
            >Sign out</span
          >
        </div>
      </a>
    </div>
    <div
      *ngIf="!hasMultipleApprovedProfiles()"
      (click)="openLogoutModal()"
      class="menu-item profile-menu-item"
      id="only-logout"
    >
      <a class="profile-menu-link">
        <div class="profile-menu-div">
          <span
            class="profile-menu-item-text"
            i18n="@@header.profileMenu.singleProfile.logOut.text"
            >Sign out</span
          >
          <span class="profile-menu-item-text2">{{
            user.activeRepresentation.company.name
          }}</span>
        </div>
        <app-chevron-right></app-chevron-right>
      </a>
    </div>
  </div>

  <div class="profile-and-hamburger" *ngIf="isLoggedIn() && isApproved()">
    <div
      class="profile"
      *ngIf="isLoggedIn() && isApproved()"
      (click)="toggleProfileMenu()"
    >
      <a>
        <app-avatar [color]="roleColor()"></app-avatar>
      </a>
    </div>
    <div
      class="hamburger hide-large"
      (click)="toggleMobileMenu()"
      *ngIf="!showMobileMenu || (showMobileMenu && mobileProfileMenu)"
    >
      <a style="display: flex; align-items: center">
        <img
          height="23"
          width="20"
          src="assets/icons/Icon-Navigation-Hamburger-Menu.svg"
          alt="Hamburger icon"
        />
      </a>
    </div>
    <div
      class="hamburger hide-large"
      (click)="toggleMobileMenu()"
      *ngIf="showMobileMenu && mobileMainMenu"
    >
      <a style="display: flex; align-items: center">
        <img
          height="20"
          width="20"
          src="assets/icons/Icon-Navigation-Exit-Close.svg"
          alt="Close icon"
        />
      </a>
    </div>
  </div>
</div>

<!-- Logout modal -->
<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalText"
  [color]="colorService.getCeriseColorCode()"
>
  <ng-container *ngIf="profileSelectModalActive">
    <div class="spacing-container">
      <app-profile-selection-list
        (onProfileSelected)="onProfileSelected()"
      ></app-profile-selection-list>

      <div class="single-cta-button">
        <zaver-primary-button
          text="Sign out"
          i18n-text="@@header.modal.switchProfile.logOutButton.text"
          (click)="logoutUser()"
        >
        </zaver-primary-button>
      </div>
    </div>
  </ng-container>
</zaver-form-modal>

<zaver-details-modal
  *ngIf="modalSmallActive"
  (onClose)="closeSmallModal()"
  [title]="modalText"
  [color]="colorService.getCeriseColorCode()"
>
  <div class="small-modal-wrapper" *ngIf="logoutModalActive">
    <span i18n="@@header.modal.logOut.text"
      >Are you sure you want to sign out? 😯</span
    >
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@header.modal.logOut.backButton.text"
        (click)="closeSmallModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Sign out"
        i18n-text="@@header.modal.logOut.confirmButton.text"
        [color]="'black'"
        (click)="logoutUser()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <div class="small-modal-wrapper" *ngIf="profileChangedModalActive">
    <span i18n="@@header.modal.profileChanged.text"
      >It looks like you changed your profile. Please refresh the page.</span
    >
  </div>
</zaver-details-modal>

<div id="mobile-menu" *ngIf="showMobileMenu">
  <!-- Main menu -->
  <div *ngIf="mobileMainMenu">
    <div class="main-item" *ngIf="hasSelectedProfile() && isApproved()">
      <a
        [routerLink]="['/paymentRequests']"
        routerLinkActive="active"
        (click)="toggleMobileMenu()"
        [skipLocationChange]="false"
        i18n="@@header.mobileMenu.paymentRequests.text"
        >Payments</a
      >
    </div>
    <div *ngIf="mayViewCashout()" class="main-item">
      <a
        [routerLink]="['/cashout']"
        routerLinkActive="active"
        (click)="toggleMobileMenu()"
        [skipLocationChange]="false"
        >Cashout</a
      >
    </div>
    <div *ngIf="mayViewPrecheck()" class="main-item">
      <a
        [routerLink]="['/precheck']"
        routerLinkActive="active"
        (click)="toggleMobileMenu()"
        [skipLocationChange]="false"
        i18n="@@header.mobileMenu.precheck.text"
        >Precheck</a
      >
    </div>
    <div
      class="contains-sub-items main-item"
      (click)="toggleAdminList()"
      *ngIf="mayDisplayAdminMenu() && isApproved()"
    >
      <span i18n="@@header.mobileMenu.administration.text">Administration</span>
      <img
        *ngIf="showAdminSubMenu"
        src="assets/icons/Icon-Navigation-Arrow-Up.svg"
        alt=""
      />
      <img
        *ngIf="!showAdminSubMenu"
        src="assets/icons/Icon-Navigation-Arrow-Down.svg"
        alt=""
      />
    </div>
    <div
      class="sub-items-container"
      [class.hideSubMenu]="!showAdminSubMenu"
      *ngIf="mayDisplayAdminMenu() && isApproved()"
    >
      <!-- Admin sub-menu - this should be visible if one or more sub items are available -->
      <div class="sub-item" *ngIf="mayViewUsers()">
        <a
          [routerLink]="['admin/users']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
          i18n="@@header.mobileMenu.users.text"
        >
          User
        </a>
      </div>
      <div class="sub-item" *ngIf="mayUseStores()">
        <a
          [routerLink]="['admin/stores']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
        >
          {{ storeAliasName }}
        </a>
      </div>
      <div class="sub-item" *ngIf="mayViewRefunds()">
        <a
          [routerLink]="['admin/refunds']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
          i18n="@@header.mobileMenu.refunds.text"
          >Refunds</a
        >
      </div>
      <div class="sub-item" *ngIf="mayViewReport()">
        <a
          [routerLink]="['admin/report']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
          i18n="@@header.mobileMenu.reports.text"
          >Reports</a
        >
      </div>
      <div class="sub-item" *ngIf="mayViewCompanyGeneralInfo()">
        <a
          [routerLink]="['admin/company']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
          i18n="@@header.mobileMenu.companySettings.text"
          >Company settings</a
        >
      </div>
      <div class="sub-item" *ngIf="mayViewZfbMerchantSettings()">
        <a
          [routerLink]="['admin/zfbMerchantSettings']"
          routerLinkActive="cerise-text"
          (click)="toggleMobileMenu()"
          [skipLocationChange]="false"
          i18n="@@header.mobileMenu.zfbMerchantSettings.text"
          >Miscellaneous</a
        >
      </div>
    </div>
    <div
      *ngIf="isLoggedIn() && hasSelectedProfile()"
      class="main-item"
      id="mobile-menu-logout"
    >
      <a
        (click)="openLogoutModalMobile()"
        class="cerise-text"
        i18n="@@header.mobileMenu.logOut.text"
        >Sign out</a
      >
    </div>
    <div *ngIf="!isLoggedIn() || !isApproved()" class="main-item">
      <a
        [href]="contactLink()"
        (click)="toggleMobileMenu()"
        target="_blank"
        i18n="@@header.mobileMenu.contact.text"
        >Contact</a
      >
    </div>
  </div>
  <!-- profile menu -->
  <div id="mobile-profile-menu" *ngIf="mobileProfileMenu">
    <div
      *ngIf="
        hasSelectedProfile() && hasMultipleApprovedProfiles() && isApproved()
      "
    >
      <div (click)="openProfileSelectModal()" id="main-profile-menu-div">
        <div class="switch-profile-text">
          <span id="switch-profile-text-primary">{{
            user.activeRepresentation.company.name
          }}</span>
          <span
            id="switch-profile-text-secondary"
            i18n="@@header.mobileProfileMenu.switchProfile.text"
            >Change profile</span
          >
        </div>
        <div id="chevron-div">
          <svg
            width="10"
            height="17"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.300434 0.277585C0.701013 -0.0925284 1.35048 -0.0925284 1.75106 0.277585L9.53938 7.47358C9.68539 7.60829 9.80124 7.76829 9.88028 7.94442C9.95932 8.12055 10 8.30934 10 8.5C10 8.69066 9.95932 8.87945 9.88028 9.05558C9.80133 9.2315 9.68567 9.39133 9.5399 9.52595C9.53973 9.5261 9.53955 9.52626 9.53938 9.52642L1.75106 16.7224C1.35048 17.0925 0.701013 17.0925 0.300434 16.7224C-0.100145 16.3523 -0.100145 15.7522 0.300434 15.3821L7.74904 8.5L0.300434 1.61789C-0.100145 1.24777 -0.100145 0.647699 0.300434 0.277585Z"
              fill="#000000"
            />
          </svg>
        </div>
      </div>
      <hr id="profile-menu-spacer" />
      <div (click)="openLogoutModal()" id="logout-text" class="cerise-text">
        <span class="cerise-text" i18n="@@header.mobileProfileMenu.logOut.text"
          >Sign out</span
        >
      </div>
    </div>
    <div
      *ngIf="!hasMultipleApprovedProfiles()"
      id="main-profile-menu-div"
      (click)="openLogoutModal()"
    >
      <div class="switch-profile-text">
        <span id="switch-profile-text-primary">{{
          user.activeRepresentation.company.name
        }}</span>
        <span
          id="switch-profile-text-secondary"
          class="cerise-text"
          i18n="@@header.mobileProfileMenu.singleProfile.logOut.text"
          >Sign out</span
        >
      </div>
      <div id="chevron-div">
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.300434 0.277585C0.701013 -0.0925284 1.35048 -0.0925284 1.75106 0.277585L9.53938 7.47358C9.68539 7.60829 9.80124 7.76829 9.88028 7.94442C9.95932 8.12055 10 8.30934 10 8.5C10 8.69066 9.95932 8.87945 9.88028 9.05558C9.80133 9.2315 9.68567 9.39133 9.5399 9.52595C9.53973 9.5261 9.53955 9.52626 9.53938 9.52642L1.75106 16.7224C1.35048 17.0925 0.701013 17.0925 0.300434 16.7224C-0.100145 16.3523 -0.100145 15.7522 0.300434 15.3821L7.74904 8.5L0.300434 1.61789C-0.100145 1.24777 -0.100145 0.647699 0.300434 0.277585Z"
            fill="#000000"
          />
        </svg>
      </div>
    </div>
    <hr *ngIf="!hasMultipleApprovedProfiles()" id="profile-menu-spacer" />
  </div>
</div>
