<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@removeStore.text"
      >Are you sure you want to remove this {{ storeAlias }}? This action
      can not be undone. 😯</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@removeStore.backButton"
        [disabled]="deleteInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, remove"
        i18n-text="@@removeStore.confirmButton"
        [loading]="deleteInProgress"
        [color]="'black'"
        (click)="removeStore()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        i18n-text="@@removeStore.serverError.backButton"
        [disabled]="deleteInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Try again"
        i18n-text="@@removeStore.serverError.confirmButton"
        [loading]="deleteInProgress"
        [color]="'black'"
        (click)="removeStore()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
