import { NgModule } from '@angular/core';

import { AgreementComponent } from './page/agreement/agreement.component';
import { CompanyAdminstrationComponent } from './page/company-adminstration/company-adminstration.component';
import { UsersComponent } from './page/users/users.component';
import { ErrorComponent } from './ui/error/error.component';
import { FooterComponent } from './ui/footer/footer.component';
import { BannerComponent } from './ui/banner/banner.component';
import { HeaderComponent } from './ui/header/header.component';
import { BankIdLoginComponent } from './page/login/bankid-login/bank-id-login.component';
import { PaymentRequestsComponent } from './page/payment-requests/payment-requests.component';
import { PendingApprovalComponent } from './page/pending-approval/pending-approval.component';
import { RadioSelectComponent } from './ui/radio-select/radio-select.component';
import { CompanyOwnerComponent } from './page/signup/company-form/company-owner/company-owner.component';
import { CompanyOwnerLegalComponent } from './page/signup/company-form/company-owner/company-owner-legal/company-owner-legal.component';
import { CompanyOwnerNaturalComponent } from './page/signup/company-form/company-owner/company-owner-natural/company-owner-natural.component';
import { CompanyFormComponent } from './page/signup/company-form/company-form.component';
import { SoleTraderFormComponent } from './page/signup/sole-trader-form/sole-trader-form.component';
import { DetailedQuestionsFormComponent } from './page/signup/detailed-questions-form/detailed-questions-form.component';
import { Step1Component } from './page/signup/step-1/step-1.component';
import { Step2Component } from './page/signup/step-2/step-2.component';
import { Step3Component } from './page/signup/step-3/step-3.component';
import { StoresComponent } from './page/stores/stores.component';
import { UnApprovedComponent } from './page/un-approved/un-approved.component';
import { TableComponent } from './ui/table/table.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { CustomRouterModule } from '../custom-router.module';
import { RouterModule } from '@angular/router';
import { BasicQuestionsFormComponent } from './page/signup/basic-questions-form/basic-questions-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchPipe } from '@shared/pipe/search.pipe';
import { AmountFormatterPipe } from '@shared/pipe/amount-formatter.pipe';
import { CashoutComponent } from './page/cashout/cashout.component';
import { AccountingReportComponent } from './page/report/accounting-report/accounting-report.component';
import { SalesReportComponent } from './page/report/sales-report/sales-report.component';
import { ReportComponent } from './page/report/report.component';
import { ProfileSelectionListComponent } from './ui/profile-selection-list/profile-selection-list.component';
import { SelectProfileComponent } from './page/select-profile/select-profile.component';
import { OopsComponent } from './page/oops/oops.component';
import { StartSignupComponent } from './page/signup/start/start-signup.component';
import { SignupAuthComponent } from './page/signup/auth/signup-auth.component';
import { PartnerSignupComponent } from './page/signup/partner/partner-signup.component';
import { PrecheckComponent } from './page/precheck/precheck.component';
import { EmailComponent } from './page/login/email/email.component';
import { PasswordEntryComponent } from './page/login/password-entry/password-entry.component';
import { PasswordResetComponent } from './page/login/password-reset/password-reset.component';
import { EmailNotFoundComponent } from './page/login/email-not-found/email-not-found.component';
import { TokenEntryComponent } from './page/login/token-entry/token-entry.component';
import { UserPendingAdminApprovalComponent } from './page/login/user-pending-admin-approval/user-pending-admin-approval.component';
import { ApproveNewUsersComponent } from './ui/approve-new-users/approve-new-users.component';
import { UserPendingUserApprovalComponent } from './page/login/user-pending-user-approval/user-pending-user-approval.component';
import { UserApprovalComponent } from './page/login/user-approval/user-approval.component';
import { CreateUserComponent } from './page/login/create-user/create-user.component';
import { BankIdComponent } from '@zfb/ui/bank-id-auth/bank-id.component';
import { ComponentLibraryModule } from '../../../web-component-library/projects/component-library/src/lib/component-library.module';
// import { ComponentLibraryModule } from 'web-component-library';
import { AvatarComponent } from './ui/avatar/avatar.component';
import { ChevronRightComponent } from './ui/chevron-right/chevron-right.component';
import { SubHeaderComponent } from './ui/sub-header/sub-header.component';
import { AdminComponent } from './page/admin/admin.component';
import { TableSortDropdownComponent } from './ui/table-sort-dropdown/table-sort-dropdown.component';
import { TableFilterDropdownComponent } from './ui/table-filter-dropdown/table-filter-dropdown.component';
import { TableFilterDropdownMobileComponent } from './ui/table-filter-dropdown-mobile/table-filter-dropdown-mobile.component';
import { TablePaginationComponent } from './ui/table-pagination/table-pagination.component';
import { CreateCompanyRepresentativeComponent } from './ui/company-representatives/create-company-representative/create-company-representative.component';
import { CreateCompanyRepresentativeFormComponent } from './ui/company-representatives/create-company-representative/create-company-representative-form/create-company-representative-form.component';
import { ViewCompanyRepresentativeComponent } from './ui/company-representatives/view-company-representative/view-company-representative.component';
import { EditCompanyRepresentativeComponent } from './ui/company-representatives/edit-company-representative/edit-company-representative.component';
import { CreateStoreComponent } from './ui/stores/create-store/create-store.component';
import { ViewStoreComponent } from './ui/stores/view-store/view-store.component';
import { EditStoreComponent } from './ui/stores/edit-store/edit-store.component';
import { CancelCashoutTaskComponent } from './ui/cashout/cancel-cashout-task/cancel-cashout-task.component';
import { ConfirmCashoutTaskComponent } from './ui/cashout/confirm-cashout-task/confirm-cashout-task.component';
import { CreateCashoutTaskComponent } from './ui/cashout/create-cashout-task/create-cashout-task.component';
import { ViewCashoutTaskComponent } from './ui/cashout/view-cashout-task/view-cashout-task.component';
import { RemoveStoreComponent } from './ui/stores/remove-store/remove-store.component';
import { FormErrorComponent } from './ui/form-error/form-error.component';
import { UserApprovalTableComponent } from './ui/user-approval-table/user-approval-table.component';
import { CreatePaymentRequestComponent } from './ui/payment-request/create-payment-request/create-payment-request.component';
import { CreatePaymentRequestConsumerComponent } from './ui/payment-request/create-payment-request/create-payment-request-consumer/create-payment-request-consumer.component';
import { CreatePaymentRequestCompanyComponent } from './ui/payment-request/create-payment-request/create-payment-request-company/create-payment-request-company.component';
import { ViewPaymentRequestComponent } from './ui/payment-request/view-payment-request/view-payment-request.component';
import { B2bPaymentProgressbarComponent } from './ui/b2b/b2b-payment-progressbar/b2b-payment-progressbar.component';
import { CreatePaymentRequestReminderComponent } from './ui/payment-request/create-payment-request-reminder/create-payment-request-reminder.component';
import { MatchPaymentsComponent } from './ui/b2b/match-payments/match-payments.component';
import { ConfirmPaymentRequestReminderComponent } from './ui/payment-request/confirm-payment-request-reminder/confirm-payment-request-reminder.component';
import { EmptyStateComponent } from './ui/empty-state/empty-state.component';
import { ConfirmMatchComponent } from './ui/b2b/confirm-match/confirm-match.component';
import { NavigationTabComponent } from './ui/navigation-tab/navigation-tab.component';
import { CardAccordionComponent } from './ui/card-accordion/card-accordion.component';
import { PageErrorComponent } from './ui/page-error/page-error.component';
import { ServerErrorComponent } from './ui/server-error/server-error.component';
import { CancelPaymentRequestComponent } from './ui/payment-request/cancel-payment-request/cancel-payment-request.component';
import { SurplusPaymentContactConfirmationComponent } from './ui/payment-request/surplus-payment-contact-confirmation/surplus-payment-contact-confirmation.component';
import { CompanyFormConfirmationComponent } from './page/signup/company-form/company-form-confirmation/company-form-confirmation.component';
import { DetailedQuestionsFormConfirmationComponent } from './page/signup/detailed-questions-form/detailed-questions-form-confirmation/detailed-questions-form-confirmation.component';
import { CalendarComponent } from '@shared/components/calendar/calendar.component';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';
import { DateRangePickerComponent } from '@shared/components/date-range-picker/date-range-picker.component';
import { ToastMessagesComponent } from './ui/toast-messages/toast-messages.component';
import { ValuePipe } from '@shared/pipe/value.pipe';
import { CreateRefundComponent } from './ui/refund/create-refund/create-refund.component';
import { CreateRefundReasonComponent } from './ui/refund/create-refund/create-refund-reason/create-refund-reason.component';
import { CreateRefundAmountComponent } from './ui/refund/create-refund/create-refund-amount/create-refund-amount.component';
import { CreateRefundConfirmComponent } from './ui/refund/create-refund/create-refund-confirm/create-refund-confirm.component';
import { RefundsComponent } from './page/refunds/refunds.component';
import { ViewRefundComponent } from './ui/refund/view-refund/view-refund.component';
import { ApproveRefundComponent } from './ui/refund/approve-refund/approve-refund.component';
import { DeclineRefundComponent } from './ui/refund/decline-refund/decline-refund.component';
import { QuickActionsComponent } from './ui/quick-actions/quick-actions.component';
import { PaymentRequestEventComponent } from './ui/payment-request/events/payment-request-event/payment-request-event.component';
import { EventTypePillComponent } from './ui/payment-request/events/event-type-pill/event-type-pill.component';
import { PaymentRequestEventsComponent } from './ui/payment-request/events/payment-request-events/payment-request-events.component';
import { ReportRecipientComponent } from './page/company-adminstration/communication/report-recipient/report-recipient.component';
import { ReportRecipientConfirmationComponent } from './page/company-adminstration/communication/report-recipient/report-recipient-confirmation/report-recipient-confirmation.component';
import { PageTabsComponent } from './ui/page-tabs/page-tabs.component';
import { PrecheckDeComponent } from './page/precheck/precheck-de/precheck-de.component';
import { PrecheckSeComponent } from './page/precheck/precheck-se/precheck-se.component';
import { CreatePrecheckComponent } from './ui/precheck/create-precheck/create-precheck.component';
import { ViewPrecheckComponent } from './ui/precheck/view-precheck/view-precheck.component';
import { AccountingReportV2Component } from './page/report/accounting-report-v2/accounting-report-v2.component';
import { ViewReportDisbursementComponent } from './page/report/accounting-report-v2/view-report-disbursement/view-report-disbursement.component';
import { ViewDownloadFileComponent } from './page/report/accounting-report-v2/view-download-file/view-download-file.component';
import { ReportTableComponent } from './ui/report-table/report-table.component';
import { CheckoutUserFlowEventComponent } from './ui/payment-request/events/checkout-user-flow-event/checkout-user-flow-event.component';
import { PageLoaderComponent } from '@shared/components/page-loader/page-loader.component';
import { DECreatePaymentRequestConsumerComponent } from './ui/payment-request/create-payment-request/de-create-payment-request-consumer/de-create-payment-request-consumer.component';
import { AddLineItemComponent } from './ui/payment-request/create-payment-request/de-create-payment-request-consumer/add-line-item/add-line-item.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CsrfInterceptor } from '@shared/interceptor/csrf.interceptor';
import { ExampleModule } from '../../../projects/widget/src/app/example.module';
import { AdminZfbMerchantSettings } from './page/zfb-merchant-settings/zfb-merchant-settings';
import { zfbMerchantSettingsConfirmationComponent } from './page/zfb-merchant-settings/zfb-merchant-settings-confirmation/zfb-merchant-settings-confirmation.component';

@NgModule({
  declarations: [
    AgreementComponent,
    CompanyAdminstrationComponent,
    UsersComponent,
    ErrorComponent,
    FooterComponent,
    BannerComponent,
    HeaderComponent,
    BankIdLoginComponent,
    PaymentRequestsComponent,
    PendingApprovalComponent,
    RadioSelectComponent,
    CompanyOwnerComponent,
    CompanyOwnerLegalComponent,
    CompanyOwnerNaturalComponent,
    BasicQuestionsFormComponent,
    CompanyFormComponent,
    SoleTraderFormComponent,
    DetailedQuestionsFormComponent,
    StartSignupComponent,
    SignupAuthComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    StoresComponent,
    TableComponent,
    UnApprovedComponent,
    SearchPipe,
    CashoutComponent,
    AmountFormatterPipe,
    AccountingReportComponent,
    AccountingReportV2Component,
    SalesReportComponent,
    ReportComponent,
    OopsComponent,
    SelectProfileComponent,
    ProfileSelectionListComponent,
    PartnerSignupComponent,
    PrecheckComponent,
    EmailComponent,
    PasswordEntryComponent,
    PasswordResetComponent,
    EmailNotFoundComponent,
    TokenEntryComponent,
    UserPendingAdminApprovalComponent,
    ApproveNewUsersComponent,
    UserPendingUserApprovalComponent,
    UserApprovalComponent,
    CreateUserComponent,
    AvatarComponent,
    ChevronRightComponent,
    SubHeaderComponent,
    AdminComponent,
    TableSortDropdownComponent,
    TableFilterDropdownComponent,
    TableFilterDropdownMobileComponent,
    TablePaginationComponent,
    CreateCompanyRepresentativeComponent,
    CreateCompanyRepresentativeFormComponent,
    ViewCompanyRepresentativeComponent,
    EditCompanyRepresentativeComponent,
    CreateStoreComponent,
    ViewStoreComponent,
    EditStoreComponent,
    CancelCashoutTaskComponent,
    ConfirmCashoutTaskComponent,
    CreateCashoutTaskComponent,
    ViewCashoutTaskComponent,
    RemoveStoreComponent,
    FormErrorComponent,
    UserApprovalTableComponent,
    CreatePaymentRequestComponent,
    CreatePaymentRequestConsumerComponent,
    DECreatePaymentRequestConsumerComponent,
    CreatePaymentRequestCompanyComponent,
    ViewPaymentRequestComponent,
    B2bPaymentProgressbarComponent,
    CreatePaymentRequestReminderComponent,
    MatchPaymentsComponent,
    ConfirmPaymentRequestReminderComponent,
    EmptyStateComponent,
    ConfirmMatchComponent,
    NavigationTabComponent,
    CardAccordionComponent,
    PageErrorComponent,
    ServerErrorComponent,
    CancelPaymentRequestComponent,
    SurplusPaymentContactConfirmationComponent,
    CompanyFormConfirmationComponent,
    DetailedQuestionsFormConfirmationComponent,
    CalendarComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    ToastMessagesComponent,
    CreateRefundComponent,
    CreateRefundReasonComponent,
    CreateRefundAmountComponent,
    CreateRefundConfirmComponent,
    RefundsComponent,
    ViewRefundComponent,
    ApproveRefundComponent,
    DeclineRefundComponent,
    QuickActionsComponent,
    PaymentRequestEventComponent,
    CheckoutUserFlowEventComponent,
    EventTypePillComponent,
    PaymentRequestEventsComponent,
    ReportRecipientComponent,
    ReportRecipientConfirmationComponent,
    PageTabsComponent,
    PrecheckDeComponent,
    PrecheckSeComponent,
    CreatePrecheckComponent,
    ViewPrecheckComponent,
    ViewReportDisbursementComponent,
    ViewDownloadFileComponent,
    ReportTableComponent,
    PageLoaderComponent,
    AddLineItemComponent,
    AdminZfbMerchantSettings,
    BankIdComponent,
    zfbMerchantSettingsConfirmationComponent,
  ],
  exports: [
    FooterComponent,
    BannerComponent,
    HeaderComponent,
    ToastMessagesComponent,
  ],
  imports: [
    CoreModule,
    CustomRouterModule,
    RouterModule,
    SharedModule,
    NgxPaginationModule,
    ComponentLibraryModule,
    ExampleModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
  ],
})
export class ZfbModule {}
