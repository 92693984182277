import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { LocaleService } from '@core/service/locale.service';
import { ValuePipe } from '@shared/pipe/value.pipe';

@Component({
  selector: 'app-create-refund-amount',
  templateUrl: './create-refund-amount.component.html',
  styleUrls: ['./create-refund-amount.component.css'],
})
export class CreateRefundAmountComponent implements OnInit {
  @Input() paymentRequest: PaymentRequestResponse;

  @Output() changeStep = new EventEmitter<'forward' | 'backwards'>();

  public form: FormGroup;
  showError = false;

  constructor(
    private controlContainer: ControlContainer,
    private valuePipe: ValuePipe,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }

  getAmountErrorMessage() {
    const fromValue = this.valuePipe.transform(
      {
        amount: 1,
        currencyCode: this.paymentRequest.value.currencyCode,
      },
      this.localeService.getCurrentLocale()
    );
    const toValue = this.valuePipe.transform(
      this.paymentRequest.refundableValue,
      this.localeService.getCurrentLocale()
    );
    return $localize`:@@createRefundAmount.amountErrorMessage:Enter an amount between ${fromValue} - ${toValue} (up to two decimal places)`;
  }

  checkFormAndAdvance() {
    if (this.form.controls.wholeAmount.value === 'true') {
      this.form.controls.amount.setValue(
        this.paymentRequest.refundableValue.amount.toString()
      );
    }
    this.showError = false;
    if (this.form.valid) {
      this.changeStep.emit('forward');
    } else {
      this.showError = true;
    }
  }
}
