<h1 class="page-heading-with-subheader">
  <span i18n="@@stores.title">Manage {{ getStoreAlias(true) }}</span>
</h1>

<section id="search-and-filter">
  <div class="input-fields">
    <zaver-search
      id="search-bar"
      placeholder="Search ..."
      i18n-placeholder="@@stores.search.placeholder"
      [inputModel]="filterQuery"
      (inputModelChange)="searchUpdatedQuery($event)"
    ></zaver-search>
  </div>
  <zaver-zfb-add-button
    id="main-create-button"
    *ngIf="mayCreateNewStore()"
    [text]="ctaButtonText"
    (click)="create()"
    [ngClass]="
      getStoreAlias(false).length < 7
        ? 'smaller-cta-desktop'
        : 'larger-cta-button'
    "
  ></zaver-zfb-add-button>
</section>

<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>

<ng-container *ngIf="fetchingResponse">
  <div class="page-loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<ng-container *ngIf="!fetchingResponse">
  <app-table
    [columns]="columns"
    [values]="currentPageOfStores"
    (filterChange)="onFilterChange($event)"
    *ngIf="stores.length > 0"
  ></app-table>

  <app-table-pagination
    *ngIf="currentPageOfStores.rows.length > 0"
    [paginationState]="paginationState"
    (pageChanged)="onPageChange($event)"
    (pageSizeChanged)="onPageSizeChange($event)"
  >
  </app-table-pagination>

  <app-empty-state *ngIf="displayEmptyState" [emptyState]="currentEmptyState">
  </app-empty-state>
</ng-container>

<zaver-zfb-add-button
  *ngIf="mayCreateNewStore() && !fetchingResponse"
  [text]="ctaButtonText"
  (click)="create()"
  class="mobile-cta"
></zaver-zfb-add-button>

<zaver-form-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-create-store *ngIf="createNewStore" (onDone)="newStoreCreated()">
  </app-create-store>

  <app-view-store
    *ngIf="displayStore"
    [store]="selectedStore"
    (openEditStoreModal)="edit($event)"
    (openRemoveStoreModal)="openRemovalModal($event, false)"
  >
  </app-view-store>

  <app-edit-store
    *ngIf="editStore"
    [store]="selectedStore"
    (onUpdated)="storeUpdated()"
    (promptCancel)="openRemovalModal($event, false)"
  >
  </app-edit-store>
</zaver-form-modal>

<zaver-details-modal
  *ngIf="modalSmallActive"
  (onClose)="closeSmallModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-remove-store
    *ngIf="removeStore"
    [store]="selectedStore"
    (onCloseModal)="closeSmallModal()"
    (onReturnToMainModal)="returnToViewModal()"
    (onRemove)="onStoreRemoved()"
  >
  </app-remove-store>
</zaver-details-modal>
