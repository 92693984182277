<app-page-error
  [class.in-admin]="displayingInCompanyAdminPage"
  *ngIf="serverError"
  [errorMessage]="errorMessage"
></app-page-error>

<ng-container *ngIf="!fetchingResponse && !serverError">
  <form *ngIf="mayGetKyc()" [formGroup]="kycForm" novalidate>
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2 i18n="@@detailedQuestionsForm.kyc.cardHeading">KYC</h2>
      </div>
      <div class="card-body">
        <div class="input-fields">
          <zaver-dropdown
            id="dropdown"
            title="
              Estimated amount that the company generates through services per year
            "
            i18n-title="@@detailedQuestionsForm.kyc.amount.title"
            [inputModel]="kycForm.controls.amount.value"
            (inputModelChange)="kycForm.controls.amount.setValue($event)"
            [selectOptions]="amountOptions"
            [error]="showError && kycForm.controls.amount.invalid"
            errorMessage="Please enter an option"
            i18n-errorMessage="@@detailedQuestionsForm.kyc.amount.errorMessage"
            [disabled]="!mayChangeKYC()"
          >
          </zaver-dropdown>
        </div>
        <div class="input-fields">
          <zaver-dropdown
            id="dropdown"
            title="
              Estimated amount of payments that the company receives through services per year
            "
            i18n-title="@@detailedQuestionsForm.kyc.payments.title"
            [inputModel]="kycForm.controls.frequency.value"
            (inputModelChange)="kycForm.controls.frequency.setValue($event)"
            [selectOptions]="frequencyOptions"
            [error]="showError && kycForm.controls.frequency.invalid"
            errorMessage="Please enter an option"
            i18n-errorMessage="@@detailedQuestionsForm.kyc.payments.errorMessage"
            [disabled]="!mayChangeKYC()"
          >
          </zaver-dropdown>
        </div>
        <div [class.radio-select-container]="displayingInCompanyAdminPage">
          <div>
            <div class="label" i18n="@@detailedQuestionsForm.kyc.transactionPurpose.question">
              What is the purpose of the transfers made to the company?
            </div>
            <app-radio-select
              [value]="kycForm.controls.transactionPurpose.value"
              (valueChange)="
                kycForm.controls.transactionPurpose.setValue($event)
              "
              [options]="transactionPurposeValues"
              [allDisabled]="!mayChangeKYC()"
              [showRequiredError]="
                showError && kycForm.controls.transactionPurpose.invalid
              "
              errorMessage="Please enter an option"
              i18n-errorMessage="@@detailedQuestionsForm.kyc.transactionPurpose.errorMessage"
            >
            </app-radio-select>
          </div>
          <div>
            <div
              class="label"
              i18n="@@detailedQuestionsForm.kyc.businessPurpose.question"
              >What is the purpose of this business relationship?</div
            >
            <app-radio-select
              [value]="kycForm.controls.businessPurpose.value"
              (valueChange)="kycForm.controls.businessPurpose.setValue($event)"
              [options]="businessPurposeValues"
              [allDisabled]="!mayChangeKYC()"
              [showRequiredError]="
                showError && kycForm.controls.businessPurpose.invalid
              "
              errorMessage="Please enter an option"
              i18n-errorMessage="@@detailedQuestionsForm.kyc.businessPurpose.errorMessage"
            >
            </app-radio-select>
          </div>
        </div>
        <div
          *ngIf="displayingInCompanyAdminPage && mayChangeKYC()"
          id="admin-buttons"
        >
          <zaver-secondary-button-outlined
            *ngIf="kycFormChanged"
            style="width: 110px"
            (click)="promptKycCancelationConfirmation()"
            text="Cancel"
            i18n-text="@@detailedQuestionsForm.kyc.button.cancel"
          ></zaver-secondary-button-outlined>
          <zaver-primary-button
            style="width: 110px; margin-left: 12px"
            (click)="promptKycSubmissionConfirmation()"
            [disabled]="!kycFormChanged"
            text="Save"
            i18n-text="@@detailedQuestionsForm.kyc.button.save"
            [color]="'black'"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="displayingInCompanyAdminPage">
      <app-page-error [errorMessage]="errorMessage"></app-page-error>
    </ng-container>
  </form>
  <form [formGroup]="bankForm" novalidate *ngIf="mayGetBankDetails()">
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2>
          <span i18n="@@detailedQuestionsForm.bankInformation.cardHeading"
            >Bank account details</span
          >
        </h2>
      </div>
      <div class="card-body">
        <div class="input-group-zfb">
          <ng-container *ngIf="activeMarket === 'SE'">
            <div class="input-fields">
              <zaver-form-input
                title="Clearing number"
                i18n-title="@@detailedQuestionsForm.clearingNumber.title"
                placeholder="Clearing number"
                i18n-placeholder="@@detailedQuestionsForm.clearingNumber.placeholder"
                [error]="bankForm.controls.clearingNumber.invalid && showError"
                errorMessage="
                  Please enter a valid clearing number (max 4-5 numbers)
                "
                i18n-errorMessage="@@detailedQuestionsForm.clearingNumber.errorMessage"
                formControlName="clearingNumber"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
            <div class="input-fields">
              <zaver-form-input
                title="Account number"
                i18n-title="@@detailedQuestionsForm.accountNumber.title"
                placeholder="Account number"
                i18n-placeholder="@@detailedQuestionsForm.accountNumber.placeholder"
                [error]="bankForm.controls.accountNumber.invalid && showError"
                errorMessage="
                  Please enter a valid account number (max 7-10 numbers)
                "
                i18n-errorMessage="@@detailedQuestionsForm.accountNumber.errorMessage"
                formControlName="accountNumber"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              activeMarket === 'AT' ||
              activeMarket === 'DE'
            "
          >
            <div class="input-fields">
              <zaver-form-input
                title="IBAN"
                i18n-title="@@detailedQuestionsForm.bankInformation.iban.title"
                placeholder="IBAN"
                i18n-placeholder="@@detailedQuestionsForm.bankInformation.iban.placeholder"
                [error]="bankForm.controls.iban.invalid && showError"
                formControlName="iban"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
            <div class="input-fields">
              <zaver-form-input
                title="BIC"
                i18n-title="@@detailedQuestionsForm.bankInformation.bic.title"
                placeholder="BIC"
                i18n-placeholder="@@detailedQuestionsForm.bankInformation.bic.placeholder"
                [error]="bankForm.controls.bic.invalid && showError"
                formControlName="bic"
                [inputMode]="'numeric'"
                [size]="'large'"
              >
              </zaver-form-input>
            </div>
          </ng-container>
        </div>
        <div class="input-fields">
          <zaver-form-input
            title="Bank"
            i18n-title="@@detailedQuestionsForm.bankInformation.bankName.title"
            placeholder="Collected from clearing number"
            i18n-placeholder="@@detailedQuestionsForm.bankInformation.bankName.placeholder"
            formControlName="bank"
            [error]="bankForm.controls.bank.invalid && showError"
            errorMessage="
              Please enter a valid bank name (max 50 characters)
            "
            i18n-errorMessage="@@detailedQuestionsForm.bankInformation.bankName.errorMessage"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>

        <div *ngIf="displayingInCompanyAdminPage" id="bank-info-text">
          <span i18n="@@detailedQuestionsForm.bankInformation.infoText"
            >In order to change bank account details we require a proof of
            account for the new account. Please contact us for more information
            <a href="mailto:merchants@zaver.se" class="link"
              >merchants@zaver.se</a
            ></span
          >
        </div>
      </div>
    </div>
  </form>
  <ng-container *ngIf="!displayingInCompanyAdminPage">
    <app-page-error
      *ngIf="errorMessage"
      [errorMessage]="errorMessage"
    ></app-page-error>
    <app-page-error
      *ngIf="showError && (kycForm.invalid || bankForm.invalid)"
      [errorMessage]="formErrorText"
    ></app-page-error>
  </ng-container>

  <div class="next-button-container" *ngIf="!displayingInCompanyAdminPage">
    <button
      class="back-button"
      i18n="@@detailedQuestionsForm.button.back"
      (click)="back.emit()"
      >Back</button
    >
    <button
      class="next-button cerise-bg"
      i18n="@@detailedQuestionsForm.button.next"
      (click)="submit()"
      >Next</button
    >
  </div>
</ng-container>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-detailed-questions-form-confirmation
    *ngIf="displaySubmissionConfirmaiton"
    [signupQuestionsToCreate]="signupQuestionsToCreate"
    (onAbort)="closeModal()"
    (onSubmit)="changesSaved($event)"
  >
  </app-detailed-questions-form-confirmation>
  <div class="small-modal-wrapper" *ngIf="displayCancelConfirmation">
    <span i18n="@@detailedQuestionsForm.modal.cancelConfirmation.text"
      >Are you sure you want to cancel? Your changes will not be saved.
      😯</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@detailedQuestionsForm.modal.cancelConfirmation.button.back"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, cancel"
        i18n-text="
          @@detailedQuestionsForm.modal.cancelConfirmation.button.confirm"
        [color]="'black'"
        (click)="cancelKycForm()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
