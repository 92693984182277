import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-zfb-merchant-settings-confirmation',
  templateUrl: './zfb-merchant-settings-confirmation.component.html',
})
export class zfbMerchantSettingsConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @Input() serverError: boolean;
  @Input() errorMessage: string;
  @Input() submitting: boolean;

  cancelText: string = $localize`:@@zfbMerchantSettings.return.modal-settings.button.text:Back`;
  saveText: string = $localize`:@@zfbMerchantSettings.modal.confirm-save.button.text:Yes, save`;
  closeText: string = $localize`:@@zfbMerchantSettings.close.text:Close`;
  tryAgainText: string = $localize`:@@zfbMerchantSettings.tryAgain.text:Try again`;

  serverErrorMessage = $localize`:@@zfbMerchantSettings.server-error.modal-settings.text:Changes couldn't be saved. Please try again or cancel by closing. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;
  serverErrorHeadingText = $localize`:@@zfbMerchantSettings.server-error.modal-settings.heading:Oops! Something went wrong.`;

  constructor(auth: AuthenticationService) {
    super(auth);
  }

  ngOnInit(): void {}

  closeModal() {
    this.onClose.emit();
  }

  submit() {
    this.onSubmit.emit();
  }
}
