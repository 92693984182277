import { Component, OnInit } from '@angular/core';
import { AppStateService } from './core/service/app-state.service';
import { UserDetails } from './core/dto/user-details';
import { AuthenticationService } from './core/service/authentication.service';
import { Router } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  user: UserDetails;

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, // Import enables auto-tracking.
    private appStateService: AppStateService,
    public authService: AuthenticationService,
    public router: Router,
  ) {
    this.appStateService.renewContextId();
  }

  ngOnInit() {
    this.appStateService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
