import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RestBase } from '@core/rest-base';
import { HttpClient } from '@angular/common/http';
import { HrefService } from '@core/service/href.service';
import { EmptyState } from '../empty-state/empty-state.component';

export interface RepresentativeApprovalDto {
  representativeId: string;
  userEmail: string;
  registrationDate: Date;
  action: UserApprovalAction;
}

export enum UserApprovalAction {
  APPROVE = 'APPROVE',
  DENY = 'DENY',
}

@Component({
  selector: 'app-approve-new-users',
  templateUrl: './approve-new-users.component.html',
  styleUrls: ['./approve-new-users.component.css'],
})
export class ApproveNewUsersComponent extends RestBase implements OnInit {
  fetchingResponse = true;
  pendingApprovals: RepresentativeApprovalDto[];
  @Output() numOfPendingApprovals = new EventEmitter<number>();
  columns = [
    {
      value: 'userEmail',
      text: $localize`:@@approveNewUsers.columns.userEmail.text:Name`,
    },
    {
      value: 'registrationDate',
      text: $localize`:@@approveNewUsers.columns.registrationDate.text:Added`,
    },
    {
      value: 'actionCol',
      text: '',
    },
  ];

  emptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Space.png',
    headingText: $localize`:@@approveNewUsers.emptyState.headingText:No new accounts to&nbsp;confirm`,
    bodyText: $localize`:@@approveNewUsers.emptyState.bodyText:There are no new accounts waiting for confirmation&nbsp;currently.`,
  };
  displayEmptyState = false;

  constructor(private httpClient: HttpClient, private href: HrefService) {
    super(httpClient, href.getApiUrl());
  }

  ngOnInit() {
    this.updateApprovals();
  }

  actionClicked(event: { object: any; action: string }) {
    const dto: RepresentativeApprovalDto = event.object;
    dto.action = UserApprovalAction[event.action];
    this.submitDecision(dto);
  }

  submitDecision(event: RepresentativeApprovalDto) {
    super
      .post('/companyRepresentative/approvalTask/', JSON.stringify(event))
      .then(() => this.updateApprovals());
  }

  private updateApprovals() {
    this.fetchingResponse = true;
    this.displayEmptyState = false;
    super.get('/companyRepresentative/approvalTask/').then((res) => {
      this.pendingApprovals = res;
      this.numOfPendingApprovals.emit(this.pendingApprovals.length);
      this.fetchingResponse = false;
      if (this.pendingApprovals.length === 0) {
        this.displayEmptyState = true;
      }
    });
  }
}
