<h1 class="page-heading-with-subheader">
  <span i18n="@@companyAdministration.title">Company settings</span>
</h1>

<app-page-tabs
  *ngIf="showSettingsTabs()"
  [activeValue]="activePage"
  [tabs]="tabs"
  (tabClicked)="setActivePage($event)"
></app-page-tabs>

<ng-container *ngIf="activePage === SettingsPage.COMPANY_INFORMATION">
  <ng-container
    *ngIf="!basicQuestionsDoneFetching || !signupQuestionsDoneFetching"
  >
    <div class="page-loader-container">
      <app-page-loader></app-page-loader>
    </div>
  </ng-container>
  <div
    style="padding-top: 4px"
    [class.hide]="!basicQuestionsDoneFetching || !signupQuestionsDoneFetching"
  >
    <app-basic-questions
      #companyInfo
      [displayingInCompanyAdminPage]="true"
      [displayingInCompanyInformationTab]="true"
      [displayingInCompanyOwnerInformationTab]="false"
      (isLegalEntity)="isLegalEntity = $event"
      (doneFetchingResponse)="basicQuestionsDone()"
    ></app-basic-questions>

    <app-signup-questions
      #kycAndBankInfo
      [displayingInCompanyAdminPage]="true"
      (doneFetchingResponse)="signupQuestionsDoneFetching = true"
    ></app-signup-questions>
  </div>
</ng-container>
<ng-container *ngIf="activePage === SettingsPage.OWNER_INFORMATION">
  <ng-container *ngIf="!basicQuestionsDoneFetching">
    <div class="page-loader-container">
      <app-page-loader></app-page-loader>
    </div>
  </ng-container>
  <div style="padding-top: 24px" [class.hide]="!basicQuestionsDoneFetching">
    <app-basic-questions
      #ownerInfo
      [displayingInCompanyAdminPage]="true"
      [displayingInCompanyInformationTab]="false"
      [displayingInCompanyOwnerInformationTab]="true"
      (doneFetchingResponse)="basicQuestionsDone()"
    ></app-basic-questions>
  </div>
</ng-container>
<ng-container *ngIf="activePage === SettingsPage.COMMUNICATION">
  <ng-container *ngIf="!reportRecipientsDoneFetching">
    <div class="page-loader-container">
      <app-page-loader></app-page-loader>
    </div>
  </ng-container>
  <div style="padding-top: 4px" [class.hide]="!reportRecipientsDoneFetching">
    <app-report-recipient
      #reportRecipient
      (doneFetchingResponse)="reportRecipientsDoneFetching = true"
    ></app-report-recipient>
  </div>
</ng-container>

<ng-container *ngIf="activePage === SettingsPage.GENERAL">
  <ng-container *ngIf="!zfbMerchantSettingsDoneFetching">
    <div class="page-loader-container">
      <app-page-loader></app-page-loader>
    </div>
  </ng-container>
  <div style="padding-top: 4px" [class.hide]="!zfbMerchantSettingsDoneFetching">
    <admin-zfb-merchant-settings
      #zfbMerchantSettings
      (doneFetchingResponse)="zfbMerchantSettingsDoneFetching = true"
    ></admin-zfb-merchant-settings>
  </div>
</ng-container>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="action(false)"
  title="Exit without saving"
  i18n-title="@@companyAdministration.unsavedModal.title"
  [color]="colorService.getCeriseColorCode()"
>
  <div class="small-modal-wrapper">
    <span i18n="@@companyAdministration.unsavedModal.text"
      >You have made changes to the page that are not saved. Are you sure you
      want to leave the page? 😯</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@companyAdministration.unsavedModal.backButton"
        (click)="action(false)"
        [size]="'x-small'"
      >
      </zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, leave"
        i18n-text="@@companyAdministration.unsavedModal.confirmButton"
        [color]="'black'"
        (click)="action(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
