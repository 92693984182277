<div class="card">
  <h4>
    <span i18n="@@emailNotFound.title"
      >The email address provided does not appear to be affiliated with any
      company.</span
    >
  </h4>
  <p>
    <span i18n="@@emailNotFound.firstParagraph"
      >The email adress provided (<span class="email">{{ email }}</span
      >) doesn't seem to be connected to any company. If your company
      already uses Zaver, you should contact the company administrator.</span
    >
  </p>

  <!-- The Swedish "sign up" flow -->
  <ng-container *ngIf="currentLocale === 'sv'">
    <p id="last-paragraph">
      Om ditt företag inte använder Zaver, men vill bli kund hos oss, så är ni
      välkomna att registrera ett företagskonto genom att klicka
      <a href="#" routerLink="/signup" routerLinkActive="active">här</a>.
    </p>
  </ng-container>

  <ng-container
    *ngIf="currentLocale !== 'sv'"
  >
    <p i18n="@@emailNotFound.lastParagraph">
      If your company does not use Zaver but would like to become a customer of ours,
      please feel free to contact us. Click on the link below
      and fill out the form. We will get back to you as soon as
      possible.
    </p>
    <p>
      <a i18n="@@emailNotFound.lastParagraph.start" [href]="localeService.getLocaleRegistrationLink()" target="_blank" class="link"
        >start Zaver</a
      >
    </p>
  </ng-container>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Back"
      i18n-text="@@emailNotFound.button"
      (click)="goBack()"
      [color]="'cerise'"
    ></zaver-primary-button>
  </div>
</div>

<!-- Swedish-only "sign up" flow -->
<div id="signup-box" *ngIf="currentLocale === 'sv'">
  <span>Vill ditt företag börja använda Zaver?</span>
  <a href="#" routerLink="/signup" routerLinkActive="active" class="link"
    >Registrera nytt företag</a
  >
</div>

<div
  *ngIf="currentLocale !== 'sv'"
  id="signup-box"
  i18n="@@login.email.footer"
>
  <span>Don't have an account?</span>
  <a [href]="localeService.getLocaleRegistrationLink()" class="link" target="_blank"
    >Register here</a
  >
</div>
