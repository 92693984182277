<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@companyFormConfirmation.text"
      >Are you sure you want to save your changes? 👋</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@companyFormConfirmation.button.back"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, save"
        i18n-text="@@companyFormConfirmation.button.confirm"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        i18n-text="@@companyFormConfirmation.serverError.button.back"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Try again"
        i18n-text="@@companyFormConfirmation.serverError.button.confirm"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
