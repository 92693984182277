import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';

export interface LocaleOption {
  text: string;
  locale: string;
  flagLink: string;
  subPath: string;
}

@Component({
  selector: 'select-locale',
  templateUrl: './select-locale.component.html',
  styleUrls: ['./select-locale.component.scss'],
})
export class SelectLocaleComponent implements OnInit {
  @Input() activeLocale: string;
  @Input() locales: LocaleOption[];
  @Input() buttonImage: string;
  @Input() shouldDisplayMenuAbove: boolean = false;

  @Output() selectOption: EventEmitter<LocaleOption> = new EventEmitter();

  isMenuOpen: boolean = false;
  localeText: string = "";
  localeFlagImage: string = "";

  @ViewChild('button') button: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  constructor(private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (!this.button.nativeElement.contains(event.target) && event.target !== this.menu.nativeElement) {
        this.isMenuOpen = false;
      }
    });
  }

  ngOnInit(): void {
    this.getButtonData();
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  select(locale: LocaleOption): void {
    this.selectOption.emit(locale);
  }

  getButtonData(): void {
    if (this.buttonImage) {
      return;
    }

    const foundLocale: LocaleOption = this.locales.find((locale) => locale.locale === this.activeLocale);
    this.localeText = foundLocale.subPath;
    this.localeFlagImage = foundLocale.flagLink;
  }

  ngOnChanges(): void {
    this.getButtonData();
  }
}
