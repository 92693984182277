import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  ControlContainer,
  FormControl,
  Validators,
} from '@angular/forms';
import { FormValidators } from '@shared/validators/validators';
import { formatDate } from '@angular/common';
import { LocaleService } from '@core/service/locale.service';
import { DateValidators } from '@shared/validators/date.validators';
import { Market } from '@core/dto/Market';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})
export class DatePickerComponent implements OnInit {
  public formGroup: FormGroup;

  @Input() dueDate: Date;
  @Input() initialDate: Date;
  @Input() minDateInput: string;
  @Input() maxDateInput: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() showError: boolean;
  @Input() formName: string;
  @Input() resetDueDateForm: () => void;

  showCalendar: boolean;
  minDate: Date;
  maxDate: Date;
  dateFormat: string;
  errorMessage: string;
  clearedForm: boolean;
  market: Market = null;
  isDeAtmarket: boolean = false;

  constructor(
    private controlContainer: ControlContainer,
    private localeService: LocaleService,
    private appStateService: AppStateService,
  ) {
    switch (localeService.getCurrentLocale()) {
      case 'sv':
      case 'en-US':
        this.dateFormat = 'yyyy-MM-dd';
        break;
      case 'de':
      case 'de-AT':
        this.dateFormat = 'dd.MM.yyyy';
        break;
      default:
        console.error(
          'Unknown local not supported in calendar: ',
          localeService.getCurrentLocale()
        );
        return;
    }
  }

  ngOnInit(): void {
    this.market = this.appStateService.getMarket();
    this.isDeAtmarket = this.market === Market.DE || this.market === Market.AT;

    this.showCalendar = false;
    this.dueDate = this.initialDate ?? new Date();
    this.setup();
  }

  setup() {
    if (this.clearedForm) {
      this.dueDate = this.initialDate ?? new Date();
    }
    this.clearedForm = false;
    this.formGroup = <FormGroup>this.controlContainer.control;
    const setMinDateHours = new Date(this.minDateInput).setHours(12, 0, 0, 0);
    const setMaxDateHours = new Date(this.maxDateInput).setHours(12, 0, 0, 0);
    this.minDate = new Date(setMinDateHours);
    this.maxDate = new Date(setMaxDateHours);

    this.formGroup.addControl(
      this.formName,
      new FormControl(
        formatDate(
          this.dueDate,
          this.dateFormat,
          this.localeService.getCurrentLocale()
        ),
        [
          Validators.required,
          FormValidators.dateRange(
            this.minDate,
            this.maxDate,
            this.localeService.getCurrentLocale()
          ),
          Validators.maxLength(10),
          DateValidators.dateFormat(this.localeService.getCurrentLocale()),
        ]
      )
    );
  }

  convertDEStringToSEDate(dateString: string): Date {
    const parts = dateString.split('.');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    } else {
      return new Date(dateString);
    }
  }

  convertSEStringToDEString(dateString): string {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      const year = parseInt(parts[0], 10);
      const month =
        parseInt(parts[1], 10) <= 10
          ? parseInt(parts[1], 10)
          : parseInt(parts[1], 10);
      const day =
        parseInt(parts[2], 10) <= 10
          ? '0' + parseInt(parts[2], 10)
          : parseInt(parts[2], 10);
      return day + '.' + month + '.' + year;
    } else {
      return;
    }
  }

  clearForm = (): void => {
    this.clearedForm = true;
    this.resetDueDateForm();
  };

  closeCalendar = (): void => {
    this.showCalendar = false;
    if (this.clearedForm) {
      this.formGroup.controls[this.formName].setValue('');
    } else {
      this.formGroup.controls[this.formName].setValue(
        formatDate(
          this.dueDate,
          this.dateFormat,
          this.localeService.getCurrentLocale()
        )
      );
      this.setup();
    }
  };

  onDateChange(newValue: Date): void {
    this.dueDate = newValue;
  }

  onDateInputChange(): void {
    this.errorMessage = "";
    let inputValue = this.formGroup.controls[this.formName].value;
    let inputValueDate: Date;

    if (this.isDeAtmarket) {
      inputValueDate = this.convertDEStringToSEDate(inputValue);
    } else {
      inputValueDate = new Date(inputValue);
    }

    //If inputValueDate is a valid Date it returns a number
    if (!isNaN(inputValueDate.getTime())) {
      const dateString = (date: String) => this.isDeAtmarket ? this.convertSEStringToDEString(date) : date;

      if (this.dueDate === null) {
        this.errorMessage = '';
      } else if (inputValueDate < this.minDate) {
        this.errorMessage = $localize`:@@datePicker.error.fromDate:Please enter a date from ${dateString(
          this.minDateInput
        )}`;
      } else if (inputValueDate > this.maxDate) {
        this.errorMessage = $localize`:@@datePicker.error.toDate:Please enter a date until ${dateString(
          this.maxDateInput
        )}`;
      }

      if (
        this.dueDate !== null &&
        inputValueDate &&
        this.formGroup.controls[this.formName].valid
      ) {
        this.dueDate = inputValueDate;
        this.errorMessage = "";
      }
    } else {
      this.errorMessage = $localize`:@@datePicker.error:Please enter a date format according to yyyy-mm-dd`;
    }
  }

  onClickSelect(): void {
    this.setup();
    this.showCalendar = !this.showCalendar;
  }
}
