import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { Store } from '@core/dto/Store';
import { AuthenticationService } from '@core/service/authentication.service';
import { CustomizationService } from '@core/service/customization.service';
import { StoreService } from '@core/service/store.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-remove-store',
  templateUrl: './remove-store.component.html',
  styleUrls: ['./remove-store.component.css'],
})
export class RemoveStoreComponent extends BaseComponent implements OnInit {
  @Input() store: Store;

  deleteInProgress = false;
  storeAlias = this.customizationService.getStoreAlias(false);
  serverErrorMessage: string;

  @Output() onReturnToMainModal = new EventEmitter<Store>();
  @Output() onRemove = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();

  constructor(
    private storeService: StoreService,
    private customizationService: CustomizationService,
    auth: AuthenticationService,
    private toastService: ToastMessageService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    const storeAlias = this.customizationService.getStoreAlias(
      false,
      true,
      false,
      true
    );
    this.serverErrorMessage = $localize`:@@removeStore.serverErrorMessage:${storeAlias} wasn't removed. Please try again or cancel by closing. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;
  }

  returnToMainModal() {
    this.onReturnToMainModal.emit(this.store);
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  removeStore() {
    this.errorMessage = '';
    this.deleteInProgress = true;
    this.storeService
      .deleteStore(this.store)
      .then(() => {
        const storeAlias = this.customizationService.getStoreAlias(
          false,
          true,
          false,
          true
        );
        this.toastService.displaySuccess(
          $localize`:@@removeStore.toast.remove.success:${storeAlias} was removed`
        );
        this.onRemove.emit();
      })
      .catch((error) => {
        this.handleError(error);
        this.deleteInProgress = false;
      });
  }
}
