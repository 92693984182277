<div class="card">
  <ng-container *ngIf="!showError">
    <ng-container *ngIf="verifying">
      <h1>
        <span i18n="@@userApproval.verifying.title">Processing verification</span>
      </h1>
      <app-page-loader></app-page-loader>
    </ng-container>
    <ng-container *ngIf="!verifying">
      <ng-container *ngIf="accepted">
        <h1>
          <span i18n="@@userApproval.accepted.title">Your answer:</span
          >&nbsp;<span
            class="color-green"
            i18n="@@userApproval.accepted.subTitle"
            >Yes, confirming.</span
          >
        </h1>
        <span class="text" i18n="@@userApproval.accepted.text"
          >You have answered that you must have an account with the company in question.</span
        >
      </ng-container>
      <ng-container *ngIf="!accepted">
        <h1>
          <span i18n="@@userApproval.declined.title">Your answer:</span
          >&nbsp;<span
            class="cerise-text"
            i18n="@@userApproval.declined.subTitle"
            >No, decline.</span
          >
        </h1>
        <span class="text" i18n="@@userApproval.declined.text"
          >You have replied that you should not have an account with the company
          in question.</span
        >
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showError">
    <ng-container *ngIf="alreadyResolved">
      <h1>
        <span i18n="@@userApproval.error.alreadyResolved.title"
          >Hello again! 👋</span
        >
      </h1>
      <span
        class="last-paragraph text"
        i18n="@@userApproval.error.alreadyResolved.text"
        >It appears that you have already visited this page.</span
      >
    </ng-container>
    <ng-container *ngIf="unexpectedError">
      <h1>
        <span i18n="@@userApproval.error.unexpectedError.title"
          >Oops, something went wrong.</span
        >
      </h1>
      <span
        class="last-paragraph text"
        i18n="@@userApproval.error.unexpectedError.text"
        >Something unexpected happend. Please try again, or contact
        our customer service.</span
      >
    </ng-container>
  </ng-container>
  <div class="button-wrapper">
    <zaver-primary-button
      *ngIf="!verifying"
      text="Sign in"
      i18n-text="@@userApproval.button.login"
      (click)="toLogin()"
      [color]="'cerise'"
    ></zaver-primary-button>
  </div>
</div>
