<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@zfbMerchantSettings.save-settings.modal.text"
      >Are you sure you want to save your changes? 👋</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="cancelText"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="saveText"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error
      [errorHeading]="serverErrorHeadingText"
      [errorMessage]="serverErrorMessage"
    ></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        [text]="closeText"
        [disabled]="submitting"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        [text]="tryAgainText"
        [loading]="submitting"
        [color]="'black'"
        (click)="submit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
