<div id="footer-container">
  <div class="grey">Copyright © {{ currentYear }} Frink AB. All rights reserved.</div>
  <div id="links">
    <div id="links-div">
      <a
        *ngIf="currentLocale === 'sv'"
        class="links"
        id="terms-link"
        target="_blank"
        [href]="'https://zaver.tawk.help/'"
        >Hjälpcenter</a
      >
      <a
        class="links"
        id="terms-link"
        target="_blank"
        [href]="termsLink()"
        i18n="@@footer.links.terms"
        >Terms of Use</a
      >
      <a
        class="links"
        id="contact-link"
        target="_blank"
        [href]="contactLink()"
        i18n="@@footer.links.contact"
        >Contact</a
      >
    </div>
    <div class="language-selection-container">
      <select-locale
        [shouldDisplayMenuAbove]="true"
        [activeLocale]="currentLocale"
        [locales]="locales"
        (selectOption)="localeChanged($event)"
    ></select-locale>
    </div>
  </div>
</div>
