import { Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';
import { GuiCustomizationDto } from '@core/dto/user-details';

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  constructor(private appStateService: AppStateService) {}

  getStoreAlias(
    plural: boolean,
    definite?: boolean,
    genitive?: boolean,
    capitalize?: boolean
  ): string {
    const guiCustomizations: GuiCustomizationDto | any =
      this.appStateService.getGuiCustomizations() || {};
    let returnValue: string;
    if (plural) {
      returnValue = guiCustomizations.termStores
        ? guiCustomizations.termStores
        : $localize`:@@customizationService.store.plural:stores`;
    } else if (definite) {
      if (genitive) {
        returnValue = guiCustomizations.termTheStoreGenitive
          ? guiCustomizations.termTheStoreGenitive
          : $localize`:@@customizationService.store.singular.definite.genitive:the store's`;
      } else {
        returnValue = guiCustomizations.termTheStore
          ? guiCustomizations.termTheStore
          : $localize`:@@customizationService.store.singular.definite:the store`;
      }
    } else {
      returnValue = guiCustomizations.termStore
        ? guiCustomizations.termStore
        : $localize`:@@customizationService.store.singular.indefinite:store`;
    }

    if (capitalize) {
      returnValue = returnValue.charAt(0).toUpperCase() + returnValue.slice(1);
    }

    return returnValue;
  }
}
