import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'zaver-input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.css']
})
/**
 * Input val and output valChange makes two-way-binding possible. When using the component,
 * input value can be externaly accessed by setting [(val)]="whatever" as an html attribute.
 */
export class InputSliderComponent implements OnInit {

  @Input() min: number;
  @Input() max: number;

  @Input() initialValue: number;
  @Input() ariaLabel: string;

  @Input() val: number;
  @Output() valChange = new EventEmitter();

  @Input() color: string = '#DB305E';

  @ViewChild('slider') slider: ElementRef<HTMLInputElement>;
  sliderEl: HTMLInputElement;

  @Output() valueChangeFinished = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  //The slider element is not accessible before view init
  ngAfterViewInit(): void {

    //Prevent angular expression changed after it has been checked error by setting timeout
    setTimeout(() => {
      this.sliderEl = this.slider.nativeElement;

      if (this.initialValue) {
        this.sliderEl.value = String(this.initialValue);
      } else {
        this.sliderEl.value = "0";
      }

      this.val = Number(this.sliderEl.value);
      this.onInputChange();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.val && this.sliderEl) {
      const percent =
      ((Number(this.val) - Number(this.min)) /
      (Number(this.max) - Number(this.min))) *
      100;
    this.sliderEl.style.setProperty("--webkitProgressPercent", `${percent}%`);
    this.valChange.emit(this.val);
    }
  }

  onInputChange(): void {
    this.adjustSlider();
    this.valChange.emit(this.val);
  }

  adjustSlider(): void {
    const percent =
      ((Number(this.sliderEl.value) - Number(this.sliderEl.min)) /
      (Number(this.sliderEl.max) - Number(this.sliderEl.min))) *
      100;
    this.sliderEl.style.setProperty("--webkitProgressPercent", `${percent}%`);
  }

  onValueChange(): void {
    this.valChange.emit(this.val);
    this.valueChangeFinished.emit();
  }

  getColor(): string {
    return this.color;
  }
}
