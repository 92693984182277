import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Market } from '@core/dto/Market';
import { PaymentRequestEvent } from '@core/dto/PaymentRequestEvent';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CheckoutEventsService } from '@core/service/checkout-events.service';
import { EventsService } from '@core/service/events.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { CheckoutFlowEvent } from '../checkout-user-flow-event/checkout-user-flow-event.component';
import { PaymentRequestService } from '@core/service/payment-request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-request-events',
  templateUrl: './payment-request-events.component.html',
  styleUrls: ['./payment-request-events.component.scss'],
})
export class PaymentRequestEventsComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    auth: AuthenticationService,
    private eventsService: EventsService,
    private toastService: ToastMessageService,
    public appState: AppStateService,
    private checkoutEventsService: CheckoutEventsService,
    private paymentRequestService: PaymentRequestService,
    private router: Router
  ) {
    super(auth);
  }

  @Input() paymentRequest: PaymentRequestResponse;

  noteForm: FormGroup;
  showError = false;

  eventTypeFilter: '' | 'REFUND' | 'NOTE' | 'LOGS' = '';

  eventsLists: DateWithListOfPaymentRequestEvents[] = [];
  paymentRequestEvents: PaymentRequestEvent[];
  fetchingEvents = false;

  showNoteForm = false;
  checkoutFlowEvents = null;

  noNotesEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@paymentRequestEvents.noNotesEmptyState.headingText:There is nothing here&nbsp;yet`,
    bodyText: $localize`:@@paymentRequestEvents.noNotesEmptyState.bodyText:There are no notes&nbsp;available.`,
    lessMarginTop: true,
    placedInModal: true,
  };
  noRefundsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@paymentRequestEvents.noRefundsEmptyState.headingText:There is nothing here&nbsp;yet`,
    bodyText: $localize`:@@paymentRequestEvents.noRefundsEmptyState.bodyText:There are no refunds&nbsp;available.`,
    lessMarginTop: true,
    placedInModal: true,
  };

  merchantHasRefundsEnabled = false;

  ngOnInit(): void {
    if (this.paymentRequest) {
      this.fetchEvents();
    }
    this.noteForm = new FormGroup({
      note: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
    });

    this.merchantHasRefundsEnabled = this.appState.merchantHasRefundAccess();
  }

  eventTypeFilterChange(filter: '' | 'REFUND' | 'NOTE' | 'LOGS') {
    if (this.eventTypeFilter === filter) {
      return;
    }

    this.eventTypeFilter = filter;
    this.fetchEvents();
  }

  async getCheckoutEvents(): Promise<any> {
    this.fetchingEvents = true;
    const resp = await this.checkoutEventsService.getUserFlowEvents(
      this.paymentRequest.id
    );
    this.checkoutFlowEvents = this.mapTocheckoutFlowEventsVievModel(resp);
    this.centerSelectedElement();
    this.fetchingEvents = false;
  }

  mapTocheckoutFlowEventsVievModel(checkoutFlowEventsDto) {
    const datesAreOnSameDay = (first: Date, second: Date) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

    return checkoutFlowEventsDto.map(
      (item: CheckoutFlowEvent, index: number) => {
        let firstEventOfDay = true;
        if (index > 0) {
          // check if current event took place on the same day as preious event
          firstEventOfDay = !datesAreOnSameDay(
            new Date(item.created),
            new Date(checkoutFlowEventsDto[index - 1].created)
          );
        }

        return {
          firstEventOfDay: firstEventOfDay,
          description: item.description,
          type: item.type,
          created: item.created,
        };
      }
    );
  }

  fetchEvents() {
    if (this.eventTypeFilter === 'LOGS') {
      this.getCheckoutEvents();
    } else {
      this.fetchingEvents = true;
      this.eventsService
        .getPaymentRequestEvents(this.paymentRequest.id, this.eventTypeFilter)
        .then((res) => {
          this.constructListOfEvents(res);
          this.fetchingEvents = false;
          this.centerSelectedElement();
        })
        .catch((err) => {
          this.handleError(err);
          this.fetchingEvents = false;
        });
    }
  }

  constructListOfEvents(response: PaymentRequestEvent[]) {
    response.map(PaymentRequestEvent.setup);
    const eventListWithDate = new Set(
      response.map((event) => event.dateDisplay)
    );
    this.eventsLists = [];
    eventListWithDate.forEach((date) => {
      this.eventsLists.push({
        date: date,
        events: response.filter((event) => event.dateDisplay === date),
      });
    });
  }

  toggleNoteForm() {
    const newShowNoteForm = !this.showNoteForm;
    if (newShowNoteForm) {
      this.showError = false;
      this.noteForm = new FormGroup({
        note: new FormControl('', [
          Validators.required,
          Validators.maxLength(255),
        ]),
      });
    }
    this.showNoteForm = newShowNoteForm;
  }

  createNote() {
    if (this.noteForm.valid) {
      this.eventsService
        .createNote(this.paymentRequest.id, this.noteForm.value['note'])
        .then(() => {
          this.showNoteForm = false;
          this.noteForm = null;
          this.toastService.displaySuccess(
            $localize`:@@paymentRequestEvents.toast.noteCreated.success:Note was created`
          );
          this.fetchEvents();
        })
        .catch(() => {
          this.showNoteForm = false;
          this.toastService.displayError(
            $localize`:@@paymentRequestEvents.toast.noteCreated.fail:Note could not be created`
          );
        });
    } else {
      this.showError = true;
    }
  }

  centerSelectedElement() {
    const statusPickerElement = document.getElementById('event-filter-wrapper');

    statusPickerElement.addEventListener('click', (e: Event): void => {
      if (e.target instanceof Element) {
        return e.target.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    });
  }
}

export interface DateWithListOfPaymentRequestEvents {
  date: number;
  events: PaymentRequestEvent[];
}
