import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from './dto/error/ErrorResponse';
import { AuthenticationService } from './service/authentication.service';
import { captureException, captureMessage } from '@sentry/angular';

export class BaseComponent {
  // This is the default server error message
  private defualtServerErrorMessage = $localize`:@@base.error.defaultServerError:Oops! Something went wrong. Please try again. If the problem persists, &lt;a class="link" href="mailto:merchants@zaver.se" target="_blank"&gt;contact&amp;nbsp;us.&lt;/a&gt;`;

  // This can be used by components extending this component that e.g. want to display a specific component on server error
  public serverError = false;
  // Error message to display in components that extends this base component
  public errorMessage;

  public processing = false;

  protected constructor(protected auth: AuthenticationService) {}

  protected handleError(error: any) {
    try {
      const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(error));
      if (httpError.status === 401 || httpError.status === 403) {
        this.processing = false;
        this.auth.logout();
        return;
      } else if (httpError.status === 0) {
        this.processing = false;
        this.errorMessage = $localize`:@@base.error.networkConnectionError:Control network connection and try again.`;
        return;
      } else if (httpError.status === 500) {
        this.processing = false;
        this.onServerError();
        return;
      }
      if (httpError.error) {
        if (!httpError.error.exception) {
          if (
            httpError.error.responseHeader &&
            httpError.error.responseHeader.message
          ) {
            this.processing = false;
            this.errorMessage = httpError.error.responseHeader.message;
            return;
          } else if (httpError.error.message) {
            this.processing = false;
            this.errorMessage = httpError.error.message;
            return;
          } else {
            if (typeof httpError.error === 'string') {
              this.processing = false;
              this.errorMessage = httpError.error;
              return;
            } else {
              this.processing = false;
              this.onServerError();
              return;
            }
          }
        } else {
          try {
            this.processing = false;
            const springError: ErrorResponse = JSON.parse(
              JSON.stringify(httpError.error)
            );
            this.errorMessage = springError.error;
            return;
          } catch (Exception) {
            this.processing = false;
            this.onServerError();
            return;
          }
        }
      } else {
        captureMessage('Error occured but http error was not set by IDAU.');
        this.onServerError();
        return;
      }
    } catch (Exception) {
      captureException(Exception);
      this.onServerError();
      return;
    }
  }

  protected clearError() {
    this.errorMessage = null;
    this.serverError = false;
  }

  protected onServerError() {
    this.errorMessage = this.defualtServerErrorMessage;
    this.serverError = true;
  }
}
