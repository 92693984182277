import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { BidProgress } from '@core/dto/Progress';
import { BaseComponent } from '@core/base.component';
import {AuthenticationService, UserIdentificationBody} from '@core/service/authentication.service';
import { BankIdService } from '@core/service/bank-id.service';
import QRCodeStyling from 'qr-code-styling';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AgreementService} from '@core/service/agreement.service';

@Component({
  selector: 'app-bank-id',
  templateUrl: './bank-id.component.html',
  styleUrls: ['./bank-id.component.css'],
})
export class BankIdComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  errorMessageList: string[];

  readonly useThisDeviceButtonText = $localize`:@@bankId.changeThisDevice:Open on this device instead.`;
  readonly useDifferentDeviceButtonText = $localize`:@@bankId.changeDifferentDevice:Open on a different device`;
  readonly tryAgainButtonText = $localize`:@@bankId.tryAgain:Try again`;
  readonly scanQrCodeStatusMessage =
    $localize`:@@bankId.statusMessage:Start the BankID-app on your phone and navigate to the QR-code scan. Point the camera to the QR-code found on this page.`;
  readonly beforeBankIdAutoStartStatusMessage = $localize`:@@bankId.loading:Please wait...`;

  pollingHandle: ReturnType<typeof setTimeout>;
  authInProgress = false;
  bankIdStatusMessage = '';
  autoStartToken = '';
  authId = '';
  bankIdStatus = '';

  readonly bankIdStatusOutstandingTransaction = 'OUTSTANDING_TRANSACTION';

  bankIdActivityTimer: any = null;
  manualDeeplinkEnabled = false;

  showQrCode: boolean;
  @ViewChild('canvas') canvas: ElementRef;
  qrCode = <QRCodeStyling>{};
  readonly qrCodeMaxRetries = 2;
  qrCodeCurrentRetries = 0;

  isMobile: boolean;

  @Input()
  provideUserBody: () => UserIdentificationBody = null;

  @Output()
  onSuccess = new EventEmitter<BidProgress<any>>();

  private _signingInsteadOfAuth = false;

  @Input()
  set signingInsteadOfAuth(value: boolean) {
    this._signingInsteadOfAuth = value;
  }

  private initialiseBankId: () => Promise<BidProgress<any>>;
  private checkBankId: (referenceId: string) => Promise<BidProgress<any>>;

  constructor(
    private authenticationService: AuthenticationService,
    private bankIdService: BankIdService,
    private deviceService: DeviceDetectorService,
    private agreementService: AgreementService,
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.showQrCode = !this.isMobile;

    if (this._signingInsteadOfAuth) {
      this.initialiseBankId = () => this.agreementService.initializeSign();
      this.checkBankId = (_) => this.agreementService.sign();
    } else {
      this.initialiseBankId = () => this.authenticationService.initializeBankIdLogin();
      this.checkBankId = (reference) => this.authenticationService.checkLoginProgress(reference, this.getUserIdentificationBody());
    }

    if (this.showQrCode) {
      this.bankIdStatusMessage = this.scanQrCodeStatusMessage;
    } else {
      this.bankIdStatusMessage = this.beforeBankIdAutoStartStatusMessage;
    }

    const authId = this.bankIdService.getCurrentAuthRefId();
    if (authId) {
      // this should only happen if a browser on iOS opens a new tab instead of reusing the old one
      // won't happen for Chrome and Firefox
      // shouldn't happen for Safari, although it's not documented and may change
      this.continueAuth(authId);
    } else {
      this.startAuth();
    }
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  private continueAuth(authId: string) {
    this.authInProgress = true;
    this.startPolling(authId);
  }

  private startAuth() {
    if (!this.authInProgress) {
      this.authInProgress = true;
      this.errorMessageList = null;

      this.initialiseBankId()
      .then((bidAuth) => {
        this.autoStartToken = bidAuth.autoStartToken;
        this.authId = bidAuth.id;

        if (!this.showQrCode) {
          this.bankIdService.startBankIdApp(this.autoStartToken, this.authId);
        } else {
          this.updateQrCode(bidAuth.qrCodeData);
        }

        this.startPolling(this.authId);
      }).catch((error) => {
        super.handleError(error);
      });
    }
  }

  private startPolling(reference: string) {
    if (!this.pollingHandle) {
      this.pollCheckLogin(reference);
    }
  }

  private pollCheckLogin(reference: string) {
    this.pollingHandle = setTimeout(
      () =>
        this.checkBankId(reference)
          .then((progress: BidProgress<any>) => {
            if (!progress.active && !progress.failed) {
              this.handleSuccess(progress);
            } else if (progress.failed) {
              this.handleFailure();
            } else {
              this.handleInProgress(progress, reference);
            }
          })
          .catch((error) => {
            this.stopPolling();
            super.handleError(error);
          }),
      1000
    );
  }

  private getUserIdentificationBody(): UserIdentificationBody {
    if (this.provideUserBody) {
      return this.provideUserBody();
    }

    return null;
  }

  private handleSuccess(progress: BidProgress<any>) {
    this.stopPolling();
    this.onSuccess.emit(progress);
  }

  private handleFailure() {
    this.stopPolling();

    if (this.showQrCode &&
        this.bankIdStatus === this.bankIdStatusOutstandingTransaction &&
        this.qrCodeCurrentRetries < this.qrCodeMaxRetries) {
      // BankId fails the auth after 30 second, but we want to show the QR code longer than that
      this.qrCodeCurrentRetries++;
      this.startAuth();
    } else {
      this.errorMessageList = [
        $localize`:@@bankId.failed:Authentication failed.`,
        $localize`:@@bankId.failedTryAgain:Please try again.`,
      ];
      this.bankIdStatusMessage = 'Vänta...';
      this.manualDeeplinkEnabled = false;
      this.bankIdActivityTimer = null;
      this.qrCodeCurrentRetries = 0;
    }
  }

  private handleInProgress(progress: BidProgress<any>, reference: string) {
    this.updateQrCode(progress.qrCodeData);

    if (this.showQrCode &&
        (progress.status === '' || progress.status === this.bankIdStatusOutstandingTransaction)) {
      this.bankIdStatusMessage = this.scanQrCodeStatusMessage;
    } else {
      this.bankIdStatusMessage = progress.message;
    }

    if (!this.manualDeeplinkEnabled && progress.status === this.bankIdStatusOutstandingTransaction && !this.showQrCode) {
      // if the user does not start their app in a few seconds show the button to give them
      // another chance to do it
      if (!this.bankIdActivityTimer) {
        this.bankIdActivityTimer = setTimeout(() => {
          this.manualDeeplinkEnabled = true;
          this.bankIdActivityTimer = null;
        }, 2000);
      }
    }

    this.bankIdStatus = progress.status;

    this.pollCheckLogin(reference);
  }

  private updateQrCode(data: string) {
    if (this.showQrCode) {
      if (!this.qrCode._options) {
        this.qrCode = new QRCodeStyling({
          width: 180,
          height: 180,
          type: 'svg',
          margin: 0,
          data: data,
          cornersSquareOptions: {
            type: 'extra-rounded',
          },
          cornersDotOptions: {
            type: 'dot',
          },
          qrOptions: {
            errorCorrectionLevel: 'L',
          },
          backgroundOptions: {
            color: 'rgba(0,0,0,0)',
          },
        });

        this.qrCode.append(this.canvas.nativeElement);
      } else {
        this.qrCode.update({ data: data });
      }
    }
  }

  autoStartAppClick() {
    this.showQrCode = false;
    this.bankIdStatusMessage = this.beforeBankIdAutoStartStatusMessage;

    if (this.authInProgress) {
      this.bankIdService.startBankIdApp(this.autoStartToken, this.authId);
    } else {
      this.startAuth();
    }
  }

  showQrCodeClick() {
    this.resetQrCodeComponent();
    this.showQrCode = true;
    this.bankIdStatusMessage = this.scanQrCodeStatusMessage;

    this.startAuth();
  }

  private resetQrCodeComponent() {
    if (this.qrCode._options) {
      this.qrCode.update({data: ''});
    }
    this.qrCode = <QRCodeStyling>{};
  }

  private stopPolling() {
    clearTimeout(this.pollingHandle);
    this.pollingHandle = null;
    this.authInProgress = false;
  }
}
