import { Component, OnInit } from '@angular/core';
import { LocaleService } from '@core/service/locale.service';
import { LocaleOption } from '../../../../../web-component-library/projects/component-library/src/public-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(
    private localeService: LocaleService,
    private router: Router,
  ) {}
  currentLocale: string = "";
  currentYear: string;
  currentLocaleCode: string;

  locales: LocaleOption[] = this.localeService.getLocales();

  ngOnInit() {
    this.currentLocale = this.localeService.getCurrentLocale();
    this.currentYear = new Date().getFullYear().toString();
    this.currentLocaleCode = this.localeService.getCurrentLocaleBaseCode().toUpperCase();
  }

  contactLink(): string {
    switch (this.currentLocale) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/kontakt';
      case 'sv':
        return 'https://zaver.com/se/kontakt';
      default:
        return 'https://zaver.com/en/contact';
    }
  }

  termsLink(): string {
    switch (this.currentLocale) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/bedingungen';
      case 'sv':
        return 'https://zaver.com/se/anvandarvillkor';
      default:
        return 'https://zaver.com/en/terms-of-use';
    }
  }

  localeChanged(option: LocaleOption): void {
    if (this.currentLocale === option.locale) {
      return;
    }

    this.localeService.setCurrentLocale(option.locale);
    location.href = '/' + option.subPath + this.router.url;
  }
}
