<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError && !approvalError">
    <span i18n="@@approveRefund.text"
      >Are you sure you want to approve the refund? 🙂</span
    >
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@approveRefund.backButton"
        [disabled]="approving"
        (click)="return.emit(refund)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, approve"
        i18n-text="@@approveRefund.confirmButton"
        [loading]="approving"
        [color]="'black'"
        (click)="approve()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="approvalError">
    <app-server-error
      [imgPath]="'assets/error-icons/Icon-Error.svg'"
      [errorMessage]="approvalErrorText"
      [errorHeading]="approvalErrorHeading"
    >
    </app-server-error>
    <div class="button-container">
      <zaver-primary-button
        text="Ok, close"
        i18n-text="@@approveRefund.approvalError.confirmButton"
        [color]="'black'"
        (click)="approved.emit()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        i18n-text="@@approveRefund.serverError.backButton"
        [disabled]="approving"
        (click)="approved.emit()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Try again"
        i18n-text="@@approveRefund.serverError.confirmButton"
        [loading]="approving"
        [color]="'black'"
        (click)="approve()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
