<div>
    <div
      *ngIf="shouldShowPresentedValue"
      [ngClass]="
        !shouldHideControllerButtons ?
          'header-presented-value' :
          'presented-value'
      "
    >
      {{ presentedValue }}
    </div>

    <div class="slider-container">
      <button
        *ngIf="!shouldHideControllerButtons"
        class="slider-button"
        type="button"
        (click)="onButtonClicked(false)"
        [class.disabled]="false"
      >
        <img src="assets/icon/dash.svg" alt="-" width="28" height="28">
      </button>

      <zaver-input-slider
        *ngIf="setupReady"
        #inputSlider
        [ngClass]="{
          'slider-with-buttons': !shouldHideControllerButtons
        }"
        [initialValue]="initialValueIndex"
        [ariaLabel]="ariaLabel"
        [min]="min"
        [max]="max"
        [(val)]="val"
        [color]="color"
        (valChange)="onValueChange()"
        (valueChangeFinished)="onFinishedChange()"
      ></zaver-input-slider>

      <button
        *ngIf="!shouldHideControllerButtons"
        class="slider-button"
        type="button"
        (click)="onButtonClicked(true)"
        [class.disabled]="false"
      >
        <img src="assets/icon/plus.svg" alt="+" width="28" height="28">
      </button>
    </div>
</div>
