<div class="auth-container">
  <div class="page-icon">
    <img id="top-logo" src="assets/bankid-logo.svg" alt="BID-logo"/>
  </div>
  <div
    *ngIf="authInProgress && (!isMobile || showQrCode)"
    class="open-app-text"
    i18n="@@bankId.open.text">
    Please open the BankID-app to continue
  </div>

  <ng-container *ngIf="!showQrCode">
    <span *ngIf="authInProgress" class="status-message">{{ bankIdStatusMessage }}</span>

    <div class="dot-spinner-container" *ngIf="authInProgress && isMobile">
      <app-dot-spinner></app-dot-spinner>
    </div>

    <div *ngIf="authInProgress && manualDeeplinkEnabled" class="deeplink-button-container">
      <div
        class="deeplink-button"
        i18n="@@bankId.open.button"
        (click)="autoStartAppClick()">
        Open the BankID-app
      </div>
    </div>

    <div *ngIf="!authInProgress && errorMessageList" class="alert-container">
      <zaver-alert-box type="WARNING" [messageList]="errorMessageList"
      ></zaver-alert-box>
    </div>

    <div *ngIf="!authInProgress" class="try-again-button">
      <zaver-primary-button
          [text]="tryAgainButtonText"
          [logo]="'assets/logo/bank_id_logo_white.svg'"
          (click)="autoStartAppClick()"
      ></zaver-primary-button>
    </div>

    <a class="second-option-link" (click)="showQrCodeClick()">
      <u>{{ useDifferentDeviceButtonText }}</u>
    </a>
  </ng-container>

  <ng-container *ngIf="showQrCode">
    <div class="qr-container" [hidden]="!authInProgress">
      <div class="qr" #canvas></div>
    </div>

    <span *ngIf="authInProgress" class="status-message">{{ bankIdStatusMessage }}</span>

    <div class="alert-container" *ngIf="!authInProgress && errorMessageList">
      <zaver-alert-box type="WARNING" [messageList]="errorMessageList"
      ></zaver-alert-box>
    </div>

    <div *ngIf="!authInProgress && errorMessageList" class="try-again-button">
      <zaver-primary-button
          [text]="tryAgainButtonText"
          (click)="showQrCodeClick()"
          [logo]="'assets/logo/bank_id_logo_white.svg'"
      ></zaver-primary-button>
    </div>

    <a class="second-option-link" (click)="autoStartAppClick()">
      <u>{{ useThisDeviceButtonText }}</u>
    </a>
  </ng-container>
</div>
