import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { CalendarService } from '@core/service/calendar.service';
import { LocaleService } from '@core/service/locale.service';
import { LANG_DE } from './lang-de';
import { LANG_SV } from './lang-sv';
import { LANG_EN } from './lang-en';

export interface Weekday {
  letter: string;
  short: string;
  full: string;
}
export interface Month {
  short: string;
  full: string;
  index: number;
}

export enum CALENDARVIEW {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

@Component({
  selector: 'app-datepicker-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit, OnChanges {
  private readonly calendarService: CalendarService;

  @Input() date: Date;
  @Output() dateChange = new EventEmitter<Date>();

  @Input() maxDate: Date;
  @Input() minDate: Date;

  @Input() closeCalendar: () => void;
  @Input() updateCalendar: () => void;
  @Input() resetDueDateForm: () => void;

  @Input() isRangeStart = false;
  @Input() isRangeEnd = false;

  calendarView: CALENDARVIEW;
  dayNames: Array<Weekday>;
  monthNames: Array<Month>;
  today: Date;
  InitialDate: Date;

  currentMonth: Month;
  currentMonthNumber: number;
  currentYear: number;

  displayMonth: Month;
  displayMonthNumber: number;
  displayYear: number;
  displayDays: Date[];

  availableYears: Array<number>;
  calendarLegend: string;

  displayYears: Array<number>;
  disableLegend = false;

  minYear: number;
  minMonth: number;

  maxYear: number;
  maxMonth: number;

  isMinMonth: boolean;
  isMaxMonth: boolean;

  constructor(
    calendarService: CalendarService,
    private localeService: LocaleService
  ) {
    this.calendarService = calendarService;
    switch (this.localeService.getCurrentLocale()) {
      case 'sv':
        this.dayNames = LANG_SV.weekDays;
        this.monthNames = LANG_SV.months;
        break;
      case 'en-US':
          this.dayNames = LANG_EN.weekDays;
          this.monthNames = LANG_EN.months;
          break;
      case 'de':
      case 'de-AT':
        this.dayNames = LANG_DE.weekDays;
        this.monthNames = LANG_DE.months;
        break;
      default:
        console.error(
          'Unknown local not supported in calendar: ',
          this.localeService.getCurrentLocale()
        );
        break;
    }
  }

  ngOnInit() {
    this.today = new Date(new Date().setHours(12, 0, 0, 0));
    this.InitialDate = this.date;
    this.updateMinMaxValues();
    this.updateDate(this.date);
    this.calendarView = CALENDARVIEW.DAYS;
    this.availableYears = this.calendarService.getYears(
      this.minDate.getFullYear(),
      this.maxDate.getFullYear()
    );
    this.minDate.setHours(12, 0, 0, 0);
    this.equalsMonth(this.maxDate, this.minDate);
  }

  ngOnChanges(changes: any) {
    if (changes['date']) {
      this.updateDate(this.date);
    }
  }

  updateMinMaxValues() {
    this.minYear = this.minDate.getFullYear();
    this.minMonth = this.minDate.getMonth();
    this.maxYear = this.maxDate.getFullYear();
    this.maxMonth = this.maxDate.getMonth();
  }

  private updateDate(date: Date) {
    this.currentMonthNumber = date.getMonth();
    this.currentMonth = this.monthNames[this.currentMonthNumber];
    this.currentYear = date.getFullYear();
    this.calendarView = CALENDARVIEW.DAYS;
    this.updateDisplay(this.currentYear, this.currentMonthNumber);
  }

  private updateDisplay(year: number, month: number) {
    const calendarArray = this.calendarService.monthDays(year, month);
    this.displayDays = calendarArray.reduce((acc, val) => acc.concat(val), []);
    this.displayMonthNumber = month;
    this.displayMonth = this.monthNames[month];
    this.displayYear = year;
    this.updateLegend();
    this.isMaxMonth = this.checkIfMaxMonth();
    this.isMinMonth = this.checkIfMinMonth();
  }

  updateLegend() {
    switch (this.calendarView) {
      case CALENDARVIEW.DAYS:
        this.calendarLegend = `${this.displayMonth.full} ${this.displayYear}`;
        break;
      case CALENDARVIEW.YEARS:
        if (this.displayYears.length === 1) {
          this.calendarLegend = `${this.displayYears[0]}`;
          return;
        }
        this.calendarLegend = `${this.displayYears[0]}-${
          this.displayYears[this.displayYears.length - 1]
        }`;
        break;
      case CALENDARVIEW.MONTHS:
        this.calendarLegend = `${this.displayYear}`;
        break;
    }
  }

  private equalsDate(date1: Date, date2: Date): boolean {
    if (date1 && date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    }
  }

  private equalsMonth(date1: Date, date2: Date): void {
    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    ) {
      this.disableLegend = true;
    } else {
      this.disableLegend = false;
    }
  }

  getDayBackgroundColor(day: Date) {
    if (this.equalsDate(day, this.date)) {
      if (this.isRangeStart) {
        return 'selected-range-start cerise-bg';
      } else if (this.isRangeEnd) {
        return 'selected-range-end cerise-bg';
      } else {
        return 'selected cerise-bg';
      }
    } else if (this.equalsDate(day, this.today)) {
      return 'current';
    }
  }

  getYearBackgroundColor(year: number) {
    if (year === this.displayYear) {
      return 'selected';
    } else if (year === this.currentYear) {
      return 'current';
    }
  }

  getMonthBackgroundColor(month: number) {
    if (month === this.displayMonth.index) {
      return 'selected';
    } else if (month === 1) {
      return 'current';
    }
  }

  getSelectableDays(day: Date) {
    if (!day) {
      return false;
    }
    const isAfter = day.getTime() > this.maxDate.getTime();
    const isBefore = day.getTime() < this.minDate.getTime();
    if (isBefore || isAfter) {
      return false;
    }
    return true;
  }

  getDisplayYears() {
    const currentYearIndex = this.availableYears.findIndex(
      (item) => item === this.date.getFullYear()
    );
    const firstVisibleYear = Math.max(currentYearIndex - 10, 0);
    this.displayYears = this.availableYears.slice(
      firstVisibleYear,
      firstVisibleYear + 20
    );
  }

  getPreviousYears() {
    const firstYearIndex = this.availableYears.findIndex(
      (item) => item === this.displayYears[0]
    );
    const firstDisplayYear = Math.max(0, firstYearIndex - 20);
    const lastDisplayYear = Math.max(20, firstYearIndex);
    this.displayYears = this.availableYears.slice(
      firstDisplayYear,
      lastDisplayYear
    );
  }

  getNextYears() {
    const lastYearIndex = this.availableYears.findIndex(
      (item) => item === this.displayYears[19]
    );
    const firstDisplayYear = Math.min(
      this.availableYears.length - 20,
      lastYearIndex + 1
    );
    const lastDisplayYear = Math.min(
      this.availableYears.length,
      lastYearIndex + 21
    );
    this.displayYears = this.availableYears.slice(
      firstDisplayYear,
      lastDisplayYear
    );
  }

  getSelectableMonths(month: number) {
    const isBefore = month < this.minMonth;
    const isAfter = month > this.maxMonth;
    if (this.displayYear === this.maxYear && isAfter) {
      return false;
    }
    if (this.displayYear === this.minYear && isBefore) {
      return false;
    }
    return true;
  }

  onYearView() {
    if (this.calendarView === CALENDARVIEW.YEARS) {
      this.calendarView = CALENDARVIEW.DAYS;
      this.updateLegend();
    } else {
      this.getDisplayYears();
      this.calendarView = CALENDARVIEW.YEARS;
      this.updateLegend();
    }
  }

  checkIfMinMonth() {
    return (
      this.displayMonth.index === this.minMonth &&
      this.displayYear === this.minYear
    );
  }

  checkIfMaxMonth() {
    return (
      this.displayMonth.index === this.maxMonth &&
      this.displayYear === this.maxYear
    );
  }

  onPrev() {
    switch (this.calendarView) {
      case CALENDARVIEW.DAYS:
        if (this.isMinMonth) {
          return;
        }
        if (this.displayMonthNumber > 0) {
          this.updateDisplay(this.displayYear, this.displayMonthNumber - 1);
        } else {
          this.updateDisplay(this.displayYear - 1, 11);
        }
        break;
      case CALENDARVIEW.YEARS:
        this.getPreviousYears();
        break;
      case CALENDARVIEW.MONTHS:
        if (this.minDate.getFullYear() < this.displayYear) {
          this.displayYear = this.displayYear - 1;
        }
        break;
      default:
    }
    this.updateLegend();
  }

  onNext() {
    switch (this.calendarView) {
      case CALENDARVIEW.DAYS:
        if (this.displayMonthNumber < 11) {
          if (this.isMaxMonth) {
            return;
          }
          this.updateDisplay(this.displayYear, this.displayMonthNumber + 1);
        } else {
          this.updateDisplay(this.displayYear + 1, 0);
        }
        break;
      case CALENDARVIEW.YEARS:
        this.getNextYears();
        break;
      case CALENDARVIEW.MONTHS:
        if (this.maxDate.getFullYear() > this.displayYear) {
          this.displayYear = this.displayYear + 1;
        }
        break;
    }
    this.updateLegend();
  }

  onSelectDate(date: Date) {
    this.date = date;
    this.dateChange.emit(this.date);
    if (!this.deviceIsMobile() || this.isRangeEnd || this.isRangeStart) {
      this.closeCalendar();
    }
    this.updateMinMaxValues();
    this.equalsMonth(this.maxDate, this.minDate);
  }

  onSave() {
    this.closeCalendar();
  }

  resetCalendar(): void {
    this.dateChange.emit();
    this.resetDueDateForm();
    this.closeCalendar();
  }

  onCancel(): void {
    this.dateChange.emit(this.InitialDate);
    this.closeCalendar();
  }

  onSelectYear(year: number) {
    this.displayYear = year;
    if (this.isRangeEnd || this.isRangeStart) {
      this.onSelectMonth(this.displayMonthNumber);
    } else {
      this.calendarView = CALENDARVIEW.MONTHS;
    }
    this.updateLegend();
  }

  onSelectMonth(month: number) {
    this.displayMonthNumber = month;
    this.updateDisplay(this.displayYear, this.displayMonthNumber);
    this.calendarView = CALENDARVIEW.DAYS;
    this.updateLegend();
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }

  dateWithinRange(day: Date) {
    if (!day) {
      return;
    }
    if (this.isRangeStart) {
      if (
        day.getTime() > this.date.getTime() &&
        day.getTime() <= this.maxDate.getTime()
      ) {
        return 'date-range';
      }
    }
    if (this.isRangeEnd) {
      if (
        day.getTime() < this.date.getTime() &&
        day.getTime() >= this.minDate.getTime()
      ) {
        return 'date-range';
      }
    }
  }
}
