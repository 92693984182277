import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
  selector: 'app-report-recipient-confirmation',
  templateUrl: './report-recipient-confirmation.component.html',
  styleUrls: ['./report-recipient-confirmation.component.scss'],
})
export class ReportRecipientConfirmationComponent
  extends BaseComponent
{
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @Input() serverError: boolean;
  @Input() errorMessage: string;
  @Input() submitting: boolean;

  serverErrorMessage = $localize`:@@reportRecipientConfirmation.serverErrorMessage:The changes could not be saved. Please try again or cancel by closing this window. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;

  constructor(auth: AuthenticationService) {
    super(auth);
  }

  closeModal() {
    this.onClose.emit();
  }

  submit() {
    this.onSubmit.emit();
  }
}
