import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { AuthenticationService } from '@core/service/authentication.service';
import { PaymentRequestService } from '@core/service/payment-request.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-cancel-payment-request',
  templateUrl: './cancel-payment-request.component.html',
  styleUrls: ['./cancel-payment-request.component.css'],
})
export class CancelPaymentRequestComponent
  extends BaseComponent
  implements OnInit
{
  @Input() selectedPaymentRequest: PaymentRequestResponse;

  @Output() onReturnToMainModal = new EventEmitter();
  @Output() onCloseModal = new EventEmitter();
  @Output() onCancelled = new EventEmitter();

  cancellationInProgress = false;
  serverErrorMessage = $localize`:@@cancelPaymenRequest.serverErrorMessage:Action could not complete. Please try again. <a class="link" href="mailto:merchants@zaver.se" target="_blank">Contact&nbsp;us</a> if the problem persists.`;

  constructor(
    private paymentRequestService: PaymentRequestService,
    auth: AuthenticationService,
    private toastService: ToastMessageService
  ) {
    super(auth);
  }

  ngOnInit(): void {}

  returnToMainModal() {
    this.onReturnToMainModal.emit();
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  cancel() {
    this.errorMessage = '';
    this.cancellationInProgress = true;
    this.paymentRequestService
      .cancel(this.selectedPaymentRequest.id)
      .then(() => {
        this.toastService.displaySuccess(
          $localize`:@@cancelPaymenRequest.toast.cancelConfirmed:The payment request has been cancelled.`
        );
        this.onCancelled.emit();
      })
      .catch((error) => {
        this.handleError(error);
        this.cancellationInProgress = false;
      });
  }
}
