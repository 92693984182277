export const LANG_EN = {
  weekDays: [
    {
      letter: 'M',
      short: 'Mon',
      full: 'Monday',
    },
    {
      letter: 'T',
      short: 'Tue',
      full: 'Tuesday',
    },
    {
      letter: 'W',
      short: 'Wed',
      full: 'Wednesday',
    },
    {
      letter: 'T',
      short: 'Thu',
      full: 'Thursday',
    },
    {
      letter: 'F',
      short: 'Fri',
      full: 'Friday',
    },
    {
      letter: 'S',
      short: 'Sat',
      full: 'Saturday',
    },
    {
      letter: 'S',
      short: 'Sun',
      full: 'Sunday',
    },
  ],
  months: [
    {
      index: 0,
      short: 'Jan',
      full: 'January',
    },
    {
      index: 1,
      short: 'Feb',
      full: 'February',
    },
    {
      index: 2,
      short: 'Mar',
      full: 'Mars',
    },
    {
      index: 3,
      short: 'Apr',
      full: 'April',
    },
    {
      index: 4,
      short: 'May',
      full: 'May',
    },
    {
      index: 5,
      short: 'Jun',
      full: 'June',
    },
    {
      index: 6,
      short: 'Jul',
      full: 'July',
    },
    {
      index: 7,
      short: 'Aug',
      full: 'August',
    },
    {
      index: 8,
      short: 'Sep',
      full: 'September',
    },
    {
      index: 9,
      short: 'Oct',
      full: 'October',
    },
    {
      index: 10,
      short: 'Nov',
      full: 'November',
    },
    {
      index: 11,
      short: 'Dec',
      full: 'December',
    },
  ],
};
