<div>
  <input
    *ngIf="max !== null && min !== null"
    #slider
    type="range"
    [attr.aria-label]="ariaLabel"
    [min]="min"
    [max]="max"
    [(ngModel)]="val"
    (input)="onInputChange()"
    (change)="onValueChange()"
    [style.--background]="getColor()"
  >
</div>
