<div class="card">
  <h1 style="margin-bottom: 52px">
    <span i18n="@@createUser.title">Choose a password</span>
  </h1>
  <form [formGroup]="form" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Choose password"
        i18n-placeholder="@@createUser.form.password.placeholder"
        [inputType]="'password'"
        [error]="form.controls.password.invalid && showError"
        [errorMessage]="passwordError"
        formControlName="password"
        [readonly]="processingLogin"
      >
      </zaver-animated-form-input>
    </div>
    <div class="input-container" id="confirm-password-input">
      <zaver-animated-form-input
        [size]="'large'"
        placeholder="Confirm password"
        i18n-placeholder="@@createUser.form.passwordConfirm.placeholder"
        [inputType]="'password'"
        [error]="form.controls.passwordConfirm.invalid && showError"
        [errorMessage]="confirmPasswordError"
        formControlName="passwordConfirm"
        [readonly]="processingLogin"
      >
      </zaver-animated-form-input>
    </div>
    <div class="password-help-text">
      <span i18n="@@createUser.passwordHelpText"
        >The password must be between 8 and 30 characters, and contain at least 1
        capital letter</span
      >
    </div>
    <div id="errors-container" *ngIf="validationErrors">
      <app-form-error
        *ngFor="let error of validationErrors"
        [errorText]="error"
      >
      </app-form-error>
    </div>
    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>
    <div class="button-wrapper">
      <zaver-primary-button
        [buttonType]="'submit'"
        text="Continue"
        i18n-text="@@createUser.button.submit"
        [loading]="processingLogin"
        (click)="submit()"
        [color]="'black'"
      ></zaver-primary-button>
    </div>
  </form>
</div>
