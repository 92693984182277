<div class="select-locale">
  <img
    #button
    *ngIf="buttonImage"
    class="locale-button"
    [src]="buttonImage"
    width="24"
    height="24"
    (click)="toggleMenu()"
  />
  <div
    #button
    *ngIf="!buttonImage"
    class="locale-button"
    (click)="toggleMenu()"
  >
    <img [src]="localeFlagImage" alt="Flag" width="16" height="12" />
    <span>{{ localeText }}</span>
  </div>

  <!-- Language selection menu -->
  <div #menu [hidden]="!isMenuOpen" class="language-menu" [ngClass]="shouldDisplayMenuAbove? 'is-above' : 'is-under'">
    <div
      class="language-container"
      *ngFor="let locale of locales"
      (click)="select(locale)"
    >
      <img src="{{ locale.flagLink }}" alt="Flag" width="27" height="20" />
      <span class="locale-text">{{ locale.text }}</span>
    </div>
  </div>
</div>
