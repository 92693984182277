<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError && !missingEmailError">
    <div class="confirm-cashout-authentication-container">
      <h3 i18n="@@confirmCashoutTask.confirm.header">Confirm payout</h3>
      <p i18n="@@confirmCashoutTask.confirm.subHeader">Enter the 4-digit code sent to {{ emailAdress }}</p>

      <zaver-token-input
        [showError]="showError"
        (valueChange)="inputValue = $event"
        [maxLength]="4"
      ></zaver-token-input>

      <p *ngIf="authorizatonMessage" class="unauthorized-error">
        {{ authorizatonMessage }}
      </p>

      <div *ngIf="resentAuthenticationCode; else showResendButton">
        <p
          class="resentAuthenticationCode"
          i18n="@@confirmCashoutTask.confirm.sent"
          >Sent</p
        >
      </div>

      <div *ngIf="sentAuthenticationCodeBySms; else showSendSmsButton">
        <p
          class="resentAuthenticationCode"
          i18n="@@confirmCashoutTask.confirm.sentSms"
          >SMS sent</p
        >
      </div>

      <ng-template #showResendButton>
        <div
          *ngIf="!resendingAuthCodeLoading && !sendingAuthenticationCodeBySms"
          class="resend-btn-container"
        >
          <button
            i18n="@@confirmCashoutTask.confirm.resendCode"
            (click)="resendAuthenticationCode()"
            >Didn't get a code? Send again</button
          >
        </div>
      </ng-template>

      <ng-template #showSendSmsButton>
        <div
          *ngIf="!sendingAuthenticationCodeBySms && !resendingAuthCodeLoading"
          class="resend-btn-container"
        >
          <button
            i18n="@@confirmCashoutTask.confirm.resendCodeSms"
            (click)="sendCodeBySmsToRepresentatives()"
            >Send code as SMS instead</button
          >
        </div>
      </ng-template>

      <div
        *ngIf="resendingAuthCodeLoading || sendingAuthenticationCodeBySms"
        class="loader-container"
      >
        <zaver-loading-dots
          [color]="'#6A7381'"
          [diameter]="10"
        ></zaver-loading-dots>
      </div>
    </div>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@confirmCashoutTask.button.back"
        [disabled]="disbursementInProgress"
        (click)="returnToMainModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Confirm"
        i18n-text="@@confirmCashoutTask.button.confirm"
        [loading]="disbursementInProgress"
        [color]="'black'"
        (click)="executeDisbursement()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="missingEmailError">
    <div class="confirm-cashout-authentication-container">
      <p
        class="unauthorized-error"
        i18n-text="@@confirmCashoutTask.error"
        >Email address not authorised for this</p
      >
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>
    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Close"
        i18n-text="@@confirmCashoutTask.button.close"
        [disabled]="disbursementInProgress"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Try again"
        i18n-text="@@confirmCashoutTask.button.tryAgain"
        [loading]="disbursementInProgress"
        [color]="'black'"
        (click)="executeDisbursement()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
