import { RepresentativeStatus } from '@core/params';

export interface CompanyRepresentativeStatusRepresentation {
  friendlyName: string;
  textColor: string;
  backgroundColor?;
}

export const CompanyRepresentativeStatusRepresentations: Map<
  string,
  CompanyRepresentativeStatusRepresentation
> = new Map([
  // Active
  [
    RepresentativeStatus.ACTIVE,
    {
      friendlyName: $localize`:@@representativeStatus.active:Active`,
      textColor: '#009773',
      backgroundColor: '#E5F5F2'
    },
  ],
  // Inactive
  [
    RepresentativeStatus.INACTIVE,
    {
      friendlyName: $localize`:@@representativeStatus.inactive:Inactive`,
      textColor: '#DB305E',
      backgroundColor: '#FDEBF0'
    },
  ],
  // Pending user approval
  [
    RepresentativeStatus.PENDING_USER_APPROVAL,
    {
      friendlyName: $localize`:@@representativeStatus.pendingUserApproval:Pending reply`,
      textColor: '#4466EE',
      backgroundColor: '#DBE7FF'
    },
  ],
  // Fallback if an unknown status is encountered
  [
    '_default_',
    {
      friendlyName: $localize`:@@representativeStatus.unknown:Unknown`,
      textColor: '#000000',
    },
  ],
]);
