<ng-container *ngIf="!form">
  <div class="loader-container">
    <app-page-loader></app-page-loader>
  </div>
</ng-container>
<div class="wrapper" *ngIf="form">
  <form [formGroup]="form" novalidate>
    <div
      class="input-fields"
      [class.error]="form.controls.name.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        title="First and last name"
        i18n-title="@@precheck.de.form.name.title"
        placeholder="First and last name"
        i18n-placeholder="@@precheck.de.form.name.placeholder"
        [error]="form.controls.name.invalid && showError"
        errorMessage="Please enter your name"
        i18n-errorMessage="@@precheck.de.form.name.errorMessage"
        formControlName="name"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-User-Single-Black-No-Circle.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.phone.invalid && showError"
    >
      <zaver-form-input
        title="Mobile number"
        i18n-title="@@precheck.de.form.mobileNumber.title"
        placeholder="Mobile number"
        i18n-placeholder="@@precheck.de.form.mobileNumber.placeholder"
        [error]="form.controls.phone.invalid && showError"
        [errorMessage]="getPhoneErrorMessage()"
        formControlName="phone"
        [inputMode]="'tel'"
        [size]="'large'"
        [showContent]="true"
      >
        <zaver-select-country
          [buttonId]="'select-country-code'"
          [items]="AVAILABLECOUNTRYCODES"
          (itemSelected)="setCountry($event)"
          [selectedItem]="selectedcountry"
        ></zaver-select-country>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="form.controls.amount.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        title="Amount"
        i18n-title="@@precheck.de.form.amount.title"
        [placeholder]="amountPlaceholder"
        [error]="form.controls.amount.invalid && showError"
        [errorMessage]="getAmountErrorMessage()"
        formControlName="amount"
        [inputMode]="'numeric'"
        [size]="'large'"
      >
        <span class="default-currency">
          {{ merchantDefaultCurrency }}
        </span>
      </zaver-form-input>
    </div>
  </form>
  <app-page-error *ngIf="errorMessage" [errorMessage]="errorMessage">
  </app-page-error>
  <div class="button-wrapper">
    <zaver-primary-button
      class="create-button"
      text="Send link"
      i18n-text="@@precheck.de.create.button.text"
      (click)="submit()"
      [loading]="creatingPrecheck"
    ></zaver-primary-button>
  </div>
</div>
