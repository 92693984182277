import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Store, StoreType } from '@core/dto/Store';
import { AuthenticationService } from '@core/service/authentication.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';
import { StoreService } from '@core/service/store.service';
import { ToastMessageService } from '@core/service/toast-message.service';

@Component({
  selector: 'app-create-store',
  templateUrl: './create-store.component.html',
  styleUrls: ['./create-store.component.scss'],
})
export class CreateStoreComponent extends BaseComponent implements OnInit {
  nameInputTitle: string;
  loading = true;

  selectedStoreType: StoreType = StoreType['STORE'];

  form: FormGroup;
  showError = false;
  creatingStore = false;
  formNameErrorMessage = $localize`:@@createStore.formNameErrorMessage:Enter name on ${this.customizationService.getStoreAlias(
    false,
    true
  )}`;

  @Output() onDone = new EventEmitter();

  constructor(
    private customizationService: CustomizationService,
    private storeService: StoreService,
    auth: AuthenticationService,
    private toastService: ToastMessageService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.loading = true;
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
    this.prepareNameInputTitle();
    this.loading = false;
  }

  submit() {
    this.errorMessage = '';
    if (this.form.valid) {
      this.creatingStore = true;
      const name: string = this.form.value.name;
      const newStore = new Store(name.trim(), this.selectedStoreType);
      this.storeService
        .create(newStore)
        .then((response: Store) => {
          this.creatingStore = false;
          this.toastService.displaySuccess(
            $localize`:@@createStore.toast.create.success:The new
            ${this.customizationService.getStoreAlias(false, true)} has been created`
          );
          this.onDone.emit();
        })
        .catch((error) => {
          this.handleError(error);
          this.creatingStore = false;
        });
    } else {
      this.showError = true;
    }
  }

  prepareNameInputTitle() {
    this.nameInputTitle = $localize`:@@createStore.nameInputTitle:${this.customizationService.getStoreAlias(
      false,
      true,
      true,
      true
    )} name`;
  }

  getStoreAliasName(): string {
    return this.customizationService.getStoreAlias(false, false, false, true);
  }

  clickedStoreType(storeType: string) {
    this.selectedStoreType = StoreType[storeType];
  }
}
