<div class="small-modal-wrapper">
  <ng-container *ngIf="!serverError">
    <span i18n="@@reportRecipientConfirmation.title">Are you sure you want to save the changes? 👋</span>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        class="button-width-auto"
        text="Back"
        i18n-text="@@reportRecipientConfirmation.text.back"
        [disabled]="submitting"
        (click)="closeModal()"
        size="x-small"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        class="button-width-auto"
        text="Yes, save"
        i18n-text="@@reportRecipientConfirmation.text.save"
        [loading]="submitting"
        color="black"
        (click)="submit()"
        size="x-small"
      ></zaver-primary-button>
    </div>
  </ng-container>

  <ng-container *ngIf="serverError">
    <app-server-error [errorMessage]="serverErrorMessage"></app-server-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        class="button-width-auto"
        text="Close"
        i18n-text="@@reportRecipientConfirmation.text.close"
        [disabled]="submitting"
        (click)="closeModal()"
        size="x-small"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        class="button-width-auto"
        text="Try again"
        i18n-text="@@reportRecipientConfirmation.text.tryagain"
        [loading]="submitting"
        color="black"
        (click)="submit()"
        size="x-small"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>
