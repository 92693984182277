import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { B2bPaymentRequestRequest } from '@core/dto/B2bPaymentRequestRequest';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { EMAIL_PATTERN } from '@core/patterns';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import {
  PaymentRequestService,
  CompanyInfoResponse,
  PaymentRequestCreationResponse,
} from '@core/service/payment-request.service';
import { b2bOrganizationNumberValidator } from '@shared/validators/identification-number-validators';
import { FormValidators } from '@shared/validators/validators';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';

@Component({
  selector: 'app-create-payment-request-company',
  templateUrl: './create-payment-request-company.component.html',
  styleUrls: ['./create-payment-request-company.component.scss'],
})
export class CreatePaymentRequestCompanyComponent implements OnInit {
  showCompanyInfoForm = false;
  companyInfoForm: FormGroup;
  showInvoiceInfoForm = false;
  invoiceInfoForm: FormGroup;
  formError = '';
  showError = false;

  processing = false;
  today: Date = new Date();
  minDueDate: string;
  maxDueDate: string;
  initialDate: Date;
  currencyCode: CurrencyCode;

  companyInfoResponse: CompanyInfoResponse;

  // Possible alias for title in the PR creation form. Potentially sent from IDAU as part of the guiCustomizations
  // field on the UserDetails from a call to /user/whoami. Defaults to 'Title'
  prFormTitleAlias = this.getAliasForTitleInForm();

  @Input() defaultCurrency: CurrencyCode;

  @Output() onDone = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();
  @Output() returnToSelection = new EventEmitter();

  constructor(
    private paymentRequestService: PaymentRequestService,
    private appStateService: AppStateService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.companyInfoForm = new FormGroup({
      companyNumber: new FormControl('', [
        Validators.required,
        b2bOrganizationNumberValidator(),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
      phone: new FormControl('', [
        Validators.required,
        phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
      ]),
    });
    this.minDueDate = formatDate(this.today, 'yyyy-MM-dd', 'sv');
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    this.maxDueDate = formatDate(maxDate, 'yyyy-MM-dd', 'sv');
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 3);
    this.initialDate = initialDate;
    this.invoiceInfoForm = new FormGroup({
      price: new FormControl('', [
        Validators.required,
        FormValidators.number(1, 999999),
      ]),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(50000),
      ]),
    });
    this.currencyCode = this.appStateService.getDefaultCurrency();
    this.showCompanyInfoForm = true;
    this.onChanges();
  }

  onChanges(): void {
    // When updating form values inside valueChanges, setValue() may not emit events.
    // That would trigger valueChanges again, causing an infinite loop
    this.companyInfoForm.controls['companyNumber'].valueChanges.subscribe(
      (val) => {
        if (this.companyInfoForm.controls['companyNumber'].valid) {
          this.formError = '';
          let companyNumberToSend =
            this.companyInfoForm.controls['companyNumber'].value;
          companyNumberToSend = companyNumberToSend.replace(/-/g, '');
          this.paymentRequestService
            .getBuyerCompanyInfo(companyNumberToSend)
            .then((res) => {
              this.companyInfoResponse = res;
            })
            .catch((err) => {
              this.handleError(err);
            });
        } else {
          this.companyInfoResponse = null;
        }
      }
    );
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();
    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }

    return $localize`:@@form.header.title:Title`;
  }

  submit() {
    this.formError = '';
    if (this.invoiceInfoForm.valid) {
      this.processing = true;
      const paymentRequest = new B2bPaymentRequestRequest(
        formatPhoneNumber(this.companyInfoForm.controls['phone'].value),
        this.companyInfoForm.controls['email'].value,
        this.invoiceInfoForm.controls['dueDate'].value,
        this.invoiceInfoForm.controls['price'].value.replace(/\s/g, ''),
        this.invoiceInfoForm.controls['title'].value,
        this.invoiceInfoForm.controls['description'].value,
        this.companyInfoResponse.legalEntityId
      );
      this.handleRequest(
        this.paymentRequestService.createVirtualInvoice(paymentRequest)
      );
    } else {
      this.showError = true;
    }
  }

  handleRequest(creationPromise) {
    creationPromise
      .then((response: PaymentRequestCreationResponse) => {
        if (response && response.paymentRequestId) {
          this.onDone.emit('b2b');
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleError(httpError: HttpErrorResponse) {
    if (httpError.status === 0) {
      this.formError = $localize`:@@createPaymentRequestCompany.handleError.statusZero:Check your network connection and try again.`;
      this.processing = false;
      return;
    }
    if (httpError.status === 401 || httpError.status === 403) {
      this.onClose.emit();
      this.processing = false;
      this.auth.logout();
      return;
    }
    this.formError = httpError?.error?.message
      ? httpError.error.message
      : httpError.error;
    this.processing = false;
  }

  continueToInvoiceInfoForm() {
    this.formError = '';
    if (
      this.companyInfoForm.valid &&
      this.companyInfoResponse &&
      !this.companyInfoResponse.error
    ) {
      this.showError = false;
      this.showCompanyInfoForm = false;
      this.showInvoiceInfoForm = true;
    } else {
      this.showError = true;
      if (this.companyInfoResponse) {
        if (
          this.companyInfoResponse.error &&
          this.companyInfoResponse.error === 'COMPANY_NOT_FOUND'
        ) {
          this.formError =
            $localize`:@@createPaymentRequestCompany.handleError.noCompany:We can't find a company with the entered organization number.`;
        } else if (
          this.companyInfoResponse.error &&
          this.companyInfoResponse.error === 'UNKNOWN_ERROR'
        ) {
          this.formError = $localize`:@@createPaymentRequestCompany.handleError.error:An error has occurred. Please try again.`;
        }
      }
    }
  }

  returnToCompanyInfoForm() {
    this.formError = '';
    this.showError = false;
    this.showInvoiceInfoForm = false;
    this.showCompanyInfoForm = true;
  }
}
