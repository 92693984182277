<div class="card">
  <ng-container *ngIf="!loading">
    <h1 [innerHTML]="header"></h1>
    <span class="sub-title" i18n="@@tokenEntry.subTitle"
      >A verification code has been sent to:
      <strong class="black-text">{{ email }}</strong></span
    >

    <div>
      <zaver-token-input
        [showError]="showError"
        (valueChange)="inputValue = $event"
        [maxLength]="4"
      ></zaver-token-input>
      <app-page-error
        *ngIf="formError"
        [errorMessage]="formError"
      ></app-page-error>
    </div>

    <div class="resend-verification-container">
      <span (click)="resendCode()" class="link" i18n="@@tokenEntry.token.resend"
        >Send the code again</span
      >
    </div>

    <div class="button-wrapper">
      <zaver-primary-button
        text="Continue"
        i18n-text="@@tokenEntry.button.submit"
        [loading]="processingToken || showResendSpinner"
        [color]="'black'"
        (click)="submit()"
      ></zaver-primary-button>
    </div>
  </ng-container>
</div>

<zaver-details-modal
  *ngIf="!!modalActive"
  (onClose)="modalActive = false"
  [color]="modalColor"
>
  <div class="small-modal-wrapper">
    <span class="modal-text-heading" i18n="@@tokenEntry.modal.text.heading"
      >Do you want to trust this device? 🙂</span
    >
    <span i18n="@@tokenEntry.modal.text"
      >
      If you choose to trust this device, you will not be
      prompted for a verification code for 30 days.</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Not now"
        i18n-text="@@tokenEntry.modal.button.back"
        (click)="handleModalAction(false)"
        [size]="'x-small'"
      >
      </zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, trust"
        i18n-text="@@tokenEntry.modal.button.confirm"
        [color]="'black'"
        (click)="handleModalAction(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
