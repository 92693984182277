import { PrecheckStatus } from '@core/dto/precheck/PrecheckStatus';

export interface PrecheckRepresentation {
  statusText: string;
  statusColor: string;
  backgroundColor?: string;
}

export const PrecheckRepresentations: Map<string, PrecheckRepresentation> =
  new Map([
    [
      PrecheckStatus.PENDING,
      {
        statusText: $localize`:@@precheck.de.statusFilter.options.pending.headerText:SMS sent`,
        statusColor: '#4466EE',
        backgroundColor: '#DBE7FF'
      },
    ],
    [
      PrecheckStatus.GRANTED,
      {
        statusText: $localize`:@@precheck.de.statusFilter.options.granted.displayText:Approved`,
        statusColor: '#009773',
        backgroundColor: '#E5F5F2'
      },
    ],
    [
      PrecheckStatus.DENIED,
      {
        statusText: $localize`:@@precheck.de.statusFilter.options.denied.displayText:Denied`,
        statusColor: '#DB305E',
        backgroundColor: '#FCE8F2'
      },
    ],
    [
      PrecheckStatus.CANCELLED,
      {
        statusText: $localize`:@@precheck.de.statusFilter.options.cancelled.displayText:Cancelled`,
        statusColor: '#0A1018',
        backgroundColor: '#E6E9EF'
      },
    ],
  ]);
