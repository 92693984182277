<form [formGroup]="form" novalidate>
  <ng-container
    *ngIf="
      !displayingInCompanyAdminPage ||
      (displayingInCompanyAdminPage && displayingInCompanyInformationTab)
    "
  >
    <div class="card" [class.in-admin]="displayingInCompanyAdminPage">
      <div class="card-heading">
        <h2>
          <span i18n="@@companyForm.companyInformation.cardHeading"
            >Company information</span
          >
        </h2>
      </div>
      <div class="card-body">
        <div class="input-group-zfb">
          <div class="input-fields">
            <zaver-form-input
              title="Organization number"
              i18n-title="
                @@companyForm.companyInformation.organizationNumber.title"
              placeholder="Organization number"
              i18n-placeholder="@@companyForm.companyInformation.organizationNumber.placeholder"
              [error]="
                form.controls.organizationNumber.invalid &&
                (showError || startOrganizationNumberValidation)
              "
              errorMessage="Please enter a valid organization number"
              i18n-errorMessage="@@companyForm.companyInformation.organizationNumber.errorMessage"
              formControlName="organizationNumber"
              [inputMode]="'tel'"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
          <div class="input-fields" *ngIf="displayCompanyNotFoundError">
            <app-form-error
              errorText="
                We can not find a company tied to the entered organization number.
              "
              i18n-errorText="@@companyForm.companyInformation.error"
            >
            </app-form-error>
          </div>
          <div class="input-fields" [class.hide]="!correctOrganizationNumber">
            <zaver-form-input
              title="Company name"
              i18n-title="@@companyForm.companyInformation.companyName.title"
              [placeholder]="companyNamePlaceholder"
              formControlName="name"
              [error]="form.controls.name.invalid && showError"
              errorMessage="This field can not be empty"
              i18n-errorMessage="@@companyForm.companyInformation.companyName.errorMessage"
              [maxLength]="255"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
        </div>
        <div class="input-group-zfb">
          <div class="input-fields" [class.hide]="!correctOrganizationNumber">
            <zaver-form-input
              title="Homepage"
              i18n-title="@@companyForm.companyInformation.webiste.title"
              placeholder="Enter the company website"
              i18n-placeholder="
                @@companyForm.companyInformation.webiste.placeholder"
              formControlName="website"
              [maxLength]="255"
              [size]="'large'"
            >
            </zaver-form-input>
          </div>
          <div style="width: 100%">
            <app-agreement
              *ngIf="displayingInCompanyAdminPage && mayViewCompanyAgreement()"
              class="desktop-agreement"
              [displayingInCompanyAdminPage]="true"
            ></app-agreement>
          </div>
        </div>
        <div
          [class.industry-agreement-container]="displayingInCompanyAdminPage"
          [class.hide]="!correctOrganizationNumber"
        >
          <app-radio-select
            [value]="getIndustryValue()"
            (valueChange)="handleIndustryValueChange($event)"
            [options]="industryOptions"
            [allDisabled]="!mayEditCompanyInfo()"
            [showRequiredError]="
              showError && form.controls.industryOther.invalid
            "
            errorMessage="Please enter an industry"
            i18n-errorMessage="
              @@companyForm.companyInformation.industry.errorMessage"
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [seperateInTwoColumns]="displayingInCompanyAdminPage"
          >
          </app-radio-select>
          <app-agreement
            class="mobile-agreement"
            *ngIf="displayingInCompanyAdminPage && mayViewCompanyAgreement()"
            [displayingInCompanyAdminPage]="true"
          ></app-agreement>
        </div>
        <div id="admin-buttons" *ngIf="displayingInCompanyAdminPage">
          <zaver-secondary-button-outlined
            *ngIf="formChanged"
            style="width: 110px"
            (click)="promptCancelationConfirmation()"
            text="Cancel"
            i18n-text="@@companyForm.companyInformation.button.cancel"
          ></zaver-secondary-button-outlined>
          <zaver-primary-button
            style="width: 110px; margin-left: 12px"
            (click)="promptCompanyInfoSubmissionConfirmation()"
            [disabled]="!formChanged"
            text="Save"
            i18n-text="@@companyForm.companyInformation.button.save"
            [color]="'black'"
          ></zaver-primary-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      !displayingInCompanyAdminPage ||
      (mayUpdateCompanyOwnershipInfo() &&
        displayingInCompanyOwnerInformationTab)
    "
  >
    <div class="card" [class.hide]="!correctOrganizationNumber">
      <div class="card-heading">
        <h2 i18n="@@companyForm.companyInformation.owner.header">Owner information: Owner of {{ form.controls.name.value }}</h2>
      </div>
      <div class="card-body">
        <zaver-dropdown
          id="dropdown"
          title="
            Amount of owners (natural or legal person) with more than 25% of the voting/ownership shares
          "
          i18n-title="@@companyForm.companyInformation.owner.title"
          [inputModel]="selectedOwnerNumberOption"
          [selectOptions]="ownerNumberOptions"
          (inputModelChange)="nrOwnerChange($event)"
          [error]="showError && selectedOwnerNumberOption === null"
          errorMessage="Please enter an amount of owners"
          i18n-errorMessage="@@companyForm.companyInformation.owner.errorMessage"
        >
        </zaver-dropdown>
      </div>
    </div>
    <ng-container *ngIf="!!owners">
      <div
        class="card-accordion-container"
        *ngFor="let owner of owners.controls; let i = index"
      >
        <app-card-accordion #owner [heading]="getOwnerCardHeading(i)">
          <app-signup-company-owner
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [formGroup]="getOwner(i)"
            [showError]="showError"
          ></app-signup-company-owner>
        </app-card-accordion>
      </div>
    </ng-container>

    <div class="card" [class.hide]="!correctOrganizationNumber">
      <div class="card-heading">
        <h2 i18n="@@companyForm.companyInformation.cheif.header">Owner information: Real principal</h2>
      </div>
      <div class="card-body">
        <span class="label-text" i18n="@@companyForm.companyInformation.cheif.text"
          >Are there any other person beyond the current owners provided
          that in any way exerts control over the legal person, a.k.a. real
          principal?</span
        >
        <span class="label-help-text" i18n="@@companyForm.companyInformation.cheif.helpText"
          >For example a person that has rights to elect or dismiss more than half
          of the legal person's board members or equivalent
          executives, or due to other agreements can exercise such
          control.</span
        >
        <zaver-dropdown
          id="dropdown"
          [inputModel]="selectedNonOwnerNumberOption"
          [selectOptions]="nonOwnerNumberOptions"
          (inputModelChange)="nrNonOwnerChange($event)"
          [error]="showError && selectedNonOwnerNumberOption === null"
          errorMessage="Please enter a valid amount of real principals"
          i18n-errorMessage="@@companyForm.companyInformation.cheif.errorMessage"
        >
        </zaver-dropdown>
      </div>
    </div>
    <ng-container *ngIf="nonOwners">
      <div
        class="card-accordion-container"
        *ngFor="let owner of nonOwners.controls; let i = index"
      >
        <app-card-accordion #nonOwner [heading]="getNonOwnerCardHeading(i)">
          <app-signup-company-owner
            [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
            [formGroup]="getNonOwner(i)"
            [nonOwner]="true"
            [showError]="showError"
          ></app-signup-company-owner>
        </app-card-accordion>
      </div>
    </ng-container>
  </ng-container>
</form>

<app-page-error
  *ngIf="errorMessage"
  [class.in-admin]="displayingInCompanyAdminPage"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
  [errorMessage]="errorMessage"
></app-page-error>

<app-page-error
  *ngIf="
    showError &&
    (form.invalid ||
      selectedNonOwnerNumberOption === null ||
      selectedOwnerNumberOption === null ||
      (!!contactInformationForm && contactInformationForm.invalid))
  "
  [class.in-admin]="displayingInCompanyAdminPage"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
  [errorMessage]="formErrorText"
></app-page-error>

<div *ngIf="!displayingInCompanyAdminPage" class="next-button-container">
  <button
    class="next-button cerise-bg"
    (click)="submit()"
    i18n="@@companyForm.companyInformation.button.next"
  >
    Next
  </button>
</div>

<div
  id="admin-buttons"
  *ngIf="displayingInCompanyAdminPage && displayingInCompanyOwnerInformationTab"
  [class.in-owner-tab]="displayingInCompanyOwnerInformationTab"
>
  <zaver-secondary-button-outlined
    style="width: 110px"
    *ngIf="formChanged"
    (click)="promptCancelationConfirmation()"
    text="Cancel"
    i18n-text="@@companyForm.companyInformation.button.cancel"
  ></zaver-secondary-button-outlined>
  <zaver-primary-button
    style="width: 110px; margin-left: 12px"
    (click)="promptOwnerInfoSubmissionConfirmation()"
    [disabled]="!formChanged"
    text="Save"
    i18n-text="@@companyForm.companyInformation.button.save"
    [color]="'black'"
  ></zaver-primary-button>
</div>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="closeModal()"
  [title]="modalTitle"
  [color]="modalColor"
>
  <app-company-form-confirmation
    *ngIf="displaySubmissionConfirmaiton"
    [signupLegalEntityToCreate]="signupLegalEntityToCreate"
    (onAbort)="closeModal()"
    (onSubmit)="changesSaved($event)"
  >
  </app-company-form-confirmation>
  <div class="small-modal-wrapper" *ngIf="displayCancelConfirmation">
    <span i18n="@@companyForm.modal.cancelConfirm.text"
      >Are you sure you want to cancel? Your changes will not be saved.
      😯</span
    >

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@companyForm.modal.cancelConfirm.button.back"
        (click)="closeModal()"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, cancel"
        i18n-text="@@companyForm.modal.cancelConfirm.button.confirm"
        [color]="'black'"
        (click)="confirmCancelation()"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
