<div id="sub-header">
  <div id="menu-div">
    <a
      class="menu-item"
      [routerLink]="['users']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewUsers()"
    >
      <span i18n="@@subHeader.users">Users</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['stores']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewStores()"
    >
      {{ storeAliasName }}
    </a>
    <a
      class="menu-item"
      [routerLink]="['refunds']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewRefunds()"
    >
      <span i18n="@@subHeader.refunds">Refunds</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['report']"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewReport()"
    >
      <span i18n="@@subHeader.reports">Reports</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['company']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewCompanyGeneralInfo()"
    >
      <span i18n="@@subHeader.companySettings">Company settings</span>
    </a>
    <a
      class="menu-item"
      [routerLink]="['zfbMerchantSettings']"
      appHoverClass="cerise-text"
      routerLinkActive="cerise-text"
      [skipLocationChange]="false"
      *ngIf="mayViewZfbMerchantSettings()"
    >
      <span i18n="@@companyAdministration.tabs.general">Miscellaneous</span>
    </a>
  </div>
</div>
