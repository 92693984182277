<div class="card">
  <h1>
    <span i18n="@@userPendingAdminApproval.title"
      >Your account is about to be created!&nbsp;🎉</span
    >
  </h1>

  <p>
    <span i18n="@@userPendingAdminApproval.firstParagraph"
      >As soon as an administrator from <strong>{{ company }}</strong> confirms your
      account in Zaver will you be able to sign in with
      <a href="mailto:{{ email }}" class="link">{{ email }}</a> and start using
      our services.</span
    >
  </p>

  <p id="last-paragraph">
    <span i18n="@@userPendingAdminApproval.lastParagraph"
      >We will send you an email when an admin at your company has approved
      your user account.</span
    >
  </p>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Back"
      i18n-text="@@userPendingAdminApproval.button"
      (click)="backToLogin()"
      [color]="colorService.getCeriseColorCodeAsType()"
    ></zaver-primary-button>
  </div>
</div>
