import { Component, OnInit } from '@angular/core';
import { ColorService } from '@core/service/color.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {
  constructor(public colorService: ColorService) {}

  ngOnInit(): void {}
}
