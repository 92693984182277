import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyRepresentationRoleColorRepresentations } from '@assets/language/RoleColorRepresentation';
import { BaseComponent } from '@core/base.component';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { CompanyRepresentativeRole } from '@core/dto/CompanyRepresentativeRole';
import { Market } from '@core/dto/Market';
import { Store } from '@core/dto/Store';
import {
  PaymentRequestPermission,
  StoreRelation,
} from '@core/dto/StoreRelation';
import { Permission } from '@core/dto/user-details';
import { EMAIL_PATTERN } from '@core/patterns';
import { availableCountryCodes, setCountryFlag } from '@core/select-country';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CompanyRepresentativeService } from '@core/service/company-representative.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';
import { StoreService } from '@core/service/store.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { Subscription } from 'rxjs';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';
import { Item } from '../../../../../../../web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-create-company-representative-form',
  templateUrl: './create-company-representative-form.component.html',
  styleUrls: ['./create-company-representative-form.component.scss'],
})
export class CreateCompanyRepresentativeFormComponent
  extends BaseComponent
  implements OnInit
{
  @Input() selectedRole: CompanyRepresentativeRole;
  @Input() roles: CompanyRepresentativeRole[];

  roleSelectionValues: { value: string; label: string }[];

  form: FormGroup;
  showError = false;

  stores: Store[];
  storeOptions: { value: string; label: string }[] = [
    {
      value: '',
      label: $localize`:@@createCompanyRepresentativeForm.storeOptions.notConnected.label:Not connected`,
    },
  ];
  selectedStore: string;

  loading = true;
  handleAllActivities = true;
  canSeeStores: boolean;
  creationInProgress = false;

  showPhoneNumberInput = false;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item = this.AVAILABLECOUNTRYCODES[4];
  formSubscription: Subscription;
  market: Market;
  @Output() onDone = new EventEmitter();
  @Output() roleChanged = new EventEmitter<{
    roleText: string;
    roleColor: string;
  }>();

  constructor(
    private companyRepresentativeService: CompanyRepresentativeService,
    private appState: AppStateService,
    public access: AccessControlService,
    private storeService: StoreService,
    private customizationService: CustomizationService,
    auth: AuthenticationService,
    private toastService: ToastMessageService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.market = this.appState.getMarket();
    this.setup();
  }

  setup() {
    this.setShowPhoneNumberInput();
    this.form = new FormGroup({});
    this.form.addControl(
      'email',
      new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ])
    );
    if (this.market === Market.SE || this.market === Market.NO) {
      this.form.addControl(
        'personalNumber',
        new FormControl('', [
          Validators.required,
          swedishNationalIdentificationNumberValidator(),
          Validators.pattern(swedishNationalIdentificationRegex)
        ])
      );
    }
    switch (this.market) {
      case Market.SE:
      case Market.NO:
        this.form.addControl(
          'phone',
          new FormControl('', [
            Validators.required,
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      case Market.DE:
        this.form.addControl(
          'phone',
          new FormControl('+49 ', [
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      case Market.AT:
        this.form.addControl(
          'phone',
          new FormControl('+43 ', [
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      default:
        break;
    }

    this.formSubscription = this.form.controls.phone.valueChanges.subscribe(
      (value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      }
    );

    this.canSeeStoresCheck();

    this.roleSelectionValues = this.roles.map((role) => ({
      label: role.displayName,
      value: role.id,
    }));

    this.setIdentificationType(this.selectedRole);

    if (this.canSeeStores) {
      this.fetchStores();
    } else {
      this.loading = false;
    }
  }

  setCountry(selected) {
    this.form.controls.phone.setValue(selected.value);
  }

  setShowPhoneNumberInput() {
    switch (this.market) {
      case Market.SE:
        this.showPhoneNumberInput = this.selectedRole.identified;
        break;
      case Market.AT:
      case Market.DE:
        this.showPhoneNumberInput = true;
        break;
      default:
        break;
    }
  }

  onRoleChanged(roleId: string) {
    this.selectedRole = this.roles.find((role) => role.id === roleId);
    this.setIdentificationType(this.selectedRole);
    this.setShowPhoneNumberInput();
    this.canSeeStoresCheck();
    if (this.canSeeStores) {
      this.fetchStores();
    }
    let colorInfo = CompanyRepresentationRoleColorRepresentations.get(
      this.selectedRole.color
    );
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    this.roleChanged.emit({ roleText: null, roleColor: colorInfo.hexColor });
  }

  onStoreChanged(storeId: string) {
    this.selectedStore = storeId;
  }

  getStoreAlias(): string {
    return this.customizationService.getStoreAlias(false, true, true, true);
  }

  setStoreOptions() {
    if (!this.stores) {
      return;
    }
    this.stores.forEach((obj) =>
      this.storeOptions.push({
        value: obj.id,
        label: obj.name,
      })
    );
  }

  canSeeStoresCheck() {
    this.canSeeStores =
      this.access.userMay(Permission.GET_STORES) &&
      this.appState.merchantHasStoreAccess() &&
      this.selectedRole.mayHaveStoreRelation;
  }

  clickedStorePermissionOption(handleAllActivities: boolean) {
    this.handleAllActivities = handleAllActivities;
  }

  setIdentificationType(role: CompanyRepresentativeRole) {
    if (
      this.appState.getMarket() === Market.SE ||
      this.appState.getMarket() === Market.NO
    ) {
      if (role.identified) {
        this.form.controls.personalNumber.enable();
        this.form.controls.phone.enable();
      } else {
        this.form.controls.personalNumber.disable();
        this.form.controls.phone.disable();
      }
    }
  }

  submit() {
    this.errorMessage = '';
    if (this.form.valid) {
      this.creationInProgress = true;
      let newRepresentative: CompanyRepresentative;
      if (this.selectedRole.identified) {
        let personalNumber: string = this.form.controls['personalNumber'].value;
        personalNumber = personalNumber.replace(/-/g, '');
        let email: string = this.form.controls['email'].value;
        email = email.trim();
        newRepresentative = new CompanyRepresentative(
          null,
          personalNumber,
          formatPhoneNumber(this.form.controls['phone'].value),
          email,
          this.selectedRole
        );
      } else {
        let email: string = this.form.controls['email'].value;
        email = email.trim();
        newRepresentative = new CompanyRepresentative(
          null,
          null,
          null,
          email,
          this.selectedRole
        );
      }
      this.setStoreRelation(newRepresentative);
      this.companyRepresentativeService
        .create(newRepresentative)
        .then(() => {
          this.creationInProgress = false;
          this.toastService.displaySuccess(
            $localize`:@@createCompanyRepresentativeForm.toast.create.success:The new user has been added`
          );
          this.onDone.emit();
        })
        .catch((error) => {
          this.handleError(error);
          this.creationInProgress = false;
        });
    } else {
      this.showError = true;
    }
  }

  private setStoreRelation(newRepresentative: CompanyRepresentative) {
    if (this.canSeeStores) {
      const selectedStore = this.stores.find(
        (store) => store.id === this.selectedStore
      );
      let paymentRequestPermission: PaymentRequestPermission;
      if (this.handleAllActivities) {
        paymentRequestPermission =
          PaymentRequestPermission.MANAGE_ALL_PAYMENT_REQUESTS_FOR_STORE;
      } else {
        paymentRequestPermission =
          PaymentRequestPermission.MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE;
      }
      newRepresentative.storeRelation = new StoreRelation(
        selectedStore,
        paymentRequestPermission
      );
    }
  }

  fetchStores() {
    this.storeService
      .get()
      .then((result) => {
        this.stores = result;
        this.setStoreOptions();
        this.setIdentificationType(this.selectedRole);
        this.loading = false;
      })
      .catch((error) => {
        this.handleError(error);
        this.loading = false;
      });
  }

  getPhoneErrorMessage(): string {
    const errors = this.form.controls.phone.errors;
    if (!errors) {
      return null;
    }
    if (!!errors['required']) {
      return $localize`:@@createCompanyRepresentativeForm.form.phone.error.required:Please enter a mobile number`;
    } else if (!!errors['invalidFormat']) {
      return $localize`:@@createCompanyRepresentativeForm.form.phone.error.invalidFormat:Please check the mobile number`;
    } else if (!!errors['invalidNumber']) {
      return $localize`:@@createCompanyRepresentativeForm.form.phone.error.invalidNumber:Please check the mobile number`;
    } else if (!!errors['unsupportedCountryCode']) {
      return $localize`:@@createCompanyRepresentativeForm.form.phone.error.unsupportedCountryCode:The specified country code is not supported`;
    }
    return null;
  }
}
