import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-precheck',
  templateUrl: './precheck.component.html',
  styleUrls: ['./precheck.component.css', '../page-shared.scss'],
})
export class PrecheckComponent implements OnInit {
  constructor(public appState: AppStateService) {}

  ngOnInit(): void {}
}
