import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@core/base.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { Market } from '@core/dto/Market';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { Permission } from '@core/dto/user-details';
import {
  B2bPaymentStatus,
  PaymentRequestStatus,
} from '@core/params';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { ColorService } from '@core/service/color.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';
import {
  PartialMatch,
  PaymentRequestService,
} from '@core/service/payment-request.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { UserAgentService } from '@core/service/user-agent.service';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';

@Component({
  selector: 'app-view-payment-request',
  templateUrl: './view-payment-request.component.html',
  styleUrls: ['./view-payment-request.component.css'],
})
export class ViewPaymentRequestComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    auth: AuthenticationService,
    private paymentRequestService: PaymentRequestService,
    private customizationService: CustomizationService,
    private appStateService: AppStateService,
    private toastService: ToastMessageService,
    private userAgentService: UserAgentService,
    private access: AccessControlService,
    private colorService: ColorService,
    public localeService: LocaleService,
    private router: Router
  ) {
    super(auth);
  }

  quickActions: QuickAction<PaymentRequestResponse>[] = [
    {
      text: $localize`:@@viewPaymentRequest.quickActions.resendSms:Send SMS`,
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (pr) =>
        pr.status === PaymentRequestStatus.OPEN && pr.buyerType === 'CONSUMER',
      topBorder: false,
      function: () => this.resendRequestSms(),
    },
    {
      text: 'Skicka påminnelse',
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (pr) =>
        pr.buyerType === 'COMPANY' &&
        !!pr.b2bPaymentData &&
        pr.b2bPaymentData.status === B2bPaymentStatus.INVOICE_PAST_DUE,
      topBorder: false,
      function: (pr) => {
        this.openCreateReminderModal.emit(pr);
      },
    },
    {
      text: $localize`:@@viewPaymentRequest.quickActions.copyLink:Copy link`,
      iconUrl: 'assets/icons/Icon-Link.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (pr) =>
        pr.status === PaymentRequestStatus.OPEN &&
        pr.buyerType === 'CONSUMER' &&
        !!pr.checkoutLink,
      topBorder: false,
      function: (pr) => {
        navigator.clipboard.writeText(
          pr.checkoutLink
        );
        // Android 12 has a system toast message when texts are copied to the
        // clipboard. We do not want to make our own toasts in this scenario
        if (this.userAgentService.getAndroidVersion() !== '12') {
          this.toastService.displaySuccess(
            $localize`:@@toast.copyLink.success:The payment link has been copied`
          );
        }
      },
    },
    {
      text: $localize`:@@viewPaymentRequest.quickActions.cancel:Cancel the request`,
      iconUrl: 'assets/icons/Icon-Delete-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (pr) => pr.status === PaymentRequestStatus.OPEN,
      topBorder: true,
      function: () => {
        this.cancel();
      },
      buttonId: 'click_cancel_paymentrequest_modal_quick_action', // Used for Tag manager
    },
    {
      text: $localize`:@@viewPaymentRequest.quickActions.createRefund:Create refund`,
      iconUrl: 'assets/icons/Icon-Reset-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (pr) => {
        return (
          !this.appStateService.merchantHasAutomaticRefundEnabled() &&
          this.appStateService.merchantHasRefundAccess() &&
          (pr.status === PaymentRequestStatus.SETTLED ||
            pr.status === PaymentRequestStatus.OPEN_REFUND ||
            pr.status === PaymentRequestStatus.PARTLY_REFUNDED)
        );
      },
      topBorder: true,
      function: (pr) => {
        this.openCreateRefundModal.emit(pr);
      },
    },
  ];

  activeQuickActions: QuickAction<PaymentRequestResponse>[];
  activePage: 'overview' | 'events' | 'purchase' = 'overview';
  smsSent: boolean;
  smsError: string;
  partialMatch: PartialMatch;
  fetchingPartialMatches = false;
  displayDescription: string;
  descriptionShorted = false;
  displayDescriptionLengthToggle: boolean = false;
  descriptionButtonText: string;
  checkoutStateInfo = null;
  viewOrderButtonText: string;
  amountLeftToCapture: number;
  viban: string;
  currency: CurrencyCode;

  // This contraption is used to update the active quick actions if the modal
  // is open and the payment request is updated using websockets
  _paymentRequest: PaymentRequestResponse;
  get paymentRequest(): PaymentRequestResponse {
    return this._paymentRequest;
  }
  @Input() set paymentRequest(pr: PaymentRequestResponse) {
    this._paymentRequest = pr;
    this.setActiveQuickActions();
  }
  @Input() showGoBackButton: Boolean = false;
  @Output() onGoBack = new EventEmitter<any>();
  @Output() openCancelModal = new EventEmitter<PaymentRequestResponse>();
  @Output() openCreateRefundModal = new EventEmitter<PaymentRequestResponse>();
  @Output() openCreateReminderModal =
    new EventEmitter<PaymentRequestResponse>();

  ngOnInit(): void {
    if (this.paymentRequest.description) {
      this.setDisplayDescription();
    }
    if (this.paymentRequest.buyerType === 'COMPANY') {
      this.fetchPartialMatchPayments();
    }
    this.currency = this.paymentRequest.value.currencyCode;
    this.setActiveQuickActions();
    this.setViewOrderButtonText();
    this.calculateAmountLeftToCapture();
    this.isMarketDEAT() &&
      this.paymentRequest.settledLoanOfferId &&
      this.paymentRequestService
        .getVibanFromLoanOfferId(this.paymentRequest.settledLoanOfferId)
        .then((resp) => {
          resp.collectiveViban
            ? (this.viban = resp.collectiveViban)
            : (this.viban = resp.viban);
        });
  }

  setViewOrderButtonText(): void {
    switch (this.paymentRequest.status) {
      case PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF:
      case PaymentRequestStatus.PARTLY_REFUNDED:
      case PaymentRequestStatus.SETTLED:
      case PaymentRequestStatus.OPEN_REFUND:
      case PaymentRequestStatus.PARTIALLY_CAPTURED:
        this.viewOrderButtonText = 'View and manage order';
        break;
      default:
        this.viewOrderButtonText = 'View Order';
        break;
    }
  }

  setActiveQuickActions() {
    this.activeQuickActions = this.quickActions.filter((action) =>
      action.displayCondition(this.paymentRequest)
    );
  }

  activePageChange(page: 'overview' | 'events' | 'purchase') {
    this.activePage = page;
  }

  showCancelButton(): boolean {
    return this.paymentRequest.status === PaymentRequestStatus.OPEN;
  }

  cancel() {
    this.openCancelModal.emit(this.paymentRequest);
  }

  toCurrency(val): Currency {
    return { amount: val, currencyCode: CurrencyCode.EUR };
  }

  toPercentString(val: number): string {
    return val + ' %';
  }

  calculateAmountLeftToCapture() {
    if (
      this.paymentRequest.capturedAmount &&
      this.paymentRequest.status ===
        PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF
    ) {
      if (
        this.paymentRequest.capturedAmount['amount'] < this.paymentRequest.price
      ) {
        this.amountLeftToCapture =
          this.paymentRequest.price -
          this.paymentRequest.capturedAmount['amount'];
      }
    }
  }

  resendRequestSms() {
    this.errorMessage = null;
    if (!!this.paymentRequest.id) {
      this.paymentRequestService
        .resend(this.paymentRequest.id)
        .then((success: boolean) => {
          if (success) {
            this.toastService.displaySuccess(
              $localize`:@@toast.resendSms.success:A new SMS has been sent`
            );
          } else {
            this.toastService.displayError(
              $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
            );
          }
        })
        .catch(() => {
          this.toastService.displayError(
            $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
          );
        });
    }
    return;
  }

  isMarketDEAT(): boolean {
    return (
      this.appStateService.getMarket() === Market.DE ||
      this.appStateService.getMarket() === Market.AT
    );
  }

  setDisplayDescription() {
    if (this.paymentRequest.description.length > 105) {
      this.displayDescription =
        this.paymentRequest.description.substring(0, 105) + '...';
      this.descriptionShorted = true;
      this.displayDescriptionLengthToggle = true;
    } else {
      this.displayDescription = this.paymentRequest.description;
      this.displayDescriptionLengthToggle = false;
    }
  }

  showMoreDescription() {
    this.displayDescription = this.paymentRequest.description;
    this.descriptionShorted = false;
  }

  showLessDescription() {
    this.setDisplayDescription();
  }

  viewOrderDetails(): void {
    this.router.navigate([`/widget`], {
      queryParams: { paymentrequest: this.paymentRequest.id },
    });
  }

  getStoreAlias(): string {
    return this.customizationService.getStoreAlias(false, false, false, true);
  }

  fetchPartialMatchPayments() {
    this.fetchingPartialMatches = true;
    this.paymentRequestService
      .getPartialMatches(this.paymentRequest.id)
      .then((response: PartialMatch[]) => {
        if (response.length === 1) {
          this.partialMatch = response[0];
        }
        this.fetchingPartialMatches = false;
      })
      .catch((err) => {
        this.handleError(err);
        this.fetchingPartialMatches = false;
      });
  }

  showCreateRefundButton(): boolean {
    const hasWidgetRefundActivatedAndPRhasLineItems =
      this.access.userMay(Permission.CREATE_CAPTURE) &&
      this.paymentRequest?.lineItems.length > 0;
    return (
      !hasWidgetRefundActivatedAndPRhasLineItems &&
      !this.appStateService.merchantHasAutomaticRefundEnabled() &&
      this.appStateService.merchantHasRefundAccess() &&
      this.access.userMay(Permission.CREATE_REFUND) &&
      (this.paymentRequest.status === PaymentRequestStatus.SETTLED ||
        this.paymentRequest.status === PaymentRequestStatus.OPEN_REFUND ||
        this.paymentRequest.status === PaymentRequestStatus.PARTLY_REFUNDED)
    );
  }

  showViewAndManagePaymentRequestButton(): boolean {
    return (
      this.paymentRequest?.lineItems !== null &&
      this.paymentRequest?.lineItems?.length > 0
    );
  }

  onOpenCreateRefundModal() {
    this.openCreateRefundModal.emit(this.paymentRequest);
  }

  mayViewEvents() {
    return this.access.userMay(Permission.GET_EVENTS);
  }
}
