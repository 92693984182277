import { RadioButtonData } from 'app/zfb/ui/radio-select/radio-select.component';

export class SignupQuestions {
  static TRANSACTION_PURPOSE_VALUES: RadioButtonData[] = [
    {
      value: 'Försäljning av varor',
      label: $localize`:@@signupQuestions.transactionPurpose.goods:Sale of goods`,
    },
    {
      value: 'Försäljning av tjänster',
      label: $localize`:@@signupQuestions.transactionPurpose.services:Sale of services`,
    },
  ];

  static BUSINESS_PURPOSE_VALUES: RadioButtonData[] = [
    {
      value: 'Ökad omsättning',
      label: $localize`:@@signupQuestions.businessPurpose.turnover:Increased turnover`,
    },
    {
      value: 'Ökade betalningsalternativ mot kund',
      label: $localize`:@@signupQuestions.businessPurpose.payments:Increased payment options for customers`,
    },
  ];

  frequency: string;
  amount: string;
  transactionPurpose: string;
  businessPurpose: string;

  bank: string;
  clearingNumber: string;
  accountNumber: string;
  iban: string;
  bic: string;

  constructor(
    amount: string,
    frequency: string,
    transactionPurpose: string,
    businessPurpose: string,
    clearingNumber: string,
    accountNumber: string,
    bank: string,
    iban: string,
    bic: string
  ) {
    this.amount = amount;
    this.frequency = frequency;
    this.transactionPurpose = transactionPurpose;
    this.businessPurpose = businessPurpose;
    this.clearingNumber = clearingNumber;
    this.accountNumber = accountNumber;
    this.bank = bank;
    this.iban = iban;
    this.bic = bic;
  }
}
