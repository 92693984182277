import { Component, OnInit } from '@angular/core';
import { PageTab } from '@zfb/ui/page-tabs/page-tabs.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export enum ReportPage {
  SALES_REPORT = 'SALES_REPORT',
  ACCOUNTING_REPORT = 'ACCOUNTING_REPORT',
  ACCOUNTING_REPORT_V2 = 'ACCOUNTING_REPORT_V2',
}
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['../page-shared.scss', './report.component.scss'],
})
export class ReportComponent implements OnInit {
  ReportPage = ReportPage;

  limitDate: Date;
  initFromDate: Date = new Date();
  initToDate: Date = new Date();
  fromDate: Date = new Date();
  toDate: Date = new Date();
  today: Date = new Date();
  searchQuery: string = '';

  searchQueryChanged: Subject<string> = new Subject<string>();

  loading = false;

  activePage: ReportPage = ReportPage.ACCOUNTING_REPORT_V2;

  tabs: PageTab<ReportPage>[] = [
    {
      value: ReportPage.ACCOUNTING_REPORT_V2,
      label: $localize`:@@report.tabs.accountingReport:Restatement`,
    },
    {
      value: ReportPage.SALES_REPORT,
      label: $localize`:@@report.tabs.salesReport:Sales report`,
    },
  ];

  constructor() {
    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.searchQuery = query;
      });
  }

  getCorrectDates() {
    if (
      this.activePage === ReportPage.ACCOUNTING_REPORT ||
      this.activePage === ReportPage.ACCOUNTING_REPORT_V2
    ) {
      const yesterDay = new Date();
      yesterDay.setDate(yesterDay.getDate() - 1);
      this.limitDate = new Date(yesterDay);
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 7);
      this.initFromDate = fromDate;
      this.initToDate.setDate(this.today.getDate() - 1);
      const isBiggerThanMaxDate =
        this.toDate.getTime() > this.limitDate.getTime();
      this.toDate = isBiggerThanMaxDate ? this.initToDate : this.toDate;
    } else {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 6);
      this.initFromDate = fromDate;
      this.initToDate.setDate(this.today.getDate());
      this.limitDate = new Date();
    }
  }

  ngOnInit() {
    this.loading = true;
    this.getCorrectDates();
    this.fromDate = this.initFromDate;
    this.toDate = this.initToDate;
    this.loading = false;
  }
  searchUpdatedQuery(query: string) {
    this.searchQueryChanged.next(query);
  }

  setActivePage(page: ReportPage) {
    if (this.activePage === page) {
      return;
    }
    this.activePage = page;
    this.getCorrectDates();
  }

  setFromDate(input: Date) {
    this.fromDate = input;
  }

  setToDate(input: Date) {
    this.toDate = input;
  }
}
