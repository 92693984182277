<div id="form-wrapper" class="spacing-container">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="!loading">
    <div
      class="input-fields"
      [class.error]="form.controls.name.invalid && showError"
    >
      <zaver-form-input
        [title]="nameInputTitle"
        placeholder="Enter name"
        i18n-placeholder="@@editStore.form.name.placeholder"
        [error]="form.controls.name.invalid && showError"
        [errorMessage]="formNameErrorMessage"
        formControlName="name"
        [maxLength]="255"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div class="input-fields">
      <div class="input-section">
        <div class="input-heading">
          <span i18n="@@editStore.form.type.heading">Select type</span>
        </div>
        <div class="radio-button-group">
          <div
            class="radio-button-with-label"
            (click)="clickedStoreType('STORE')"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="selectedStoreType === 'STORE'"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label">{{ getStoreAliasName() }}</span>
          </div>
          <div
            class="radio-button-with-label"
            (click)="clickedStoreType('E_COMMERCE')"
          >
            <zaver-zfb-radio-button
              [color]="'cerise'"
              [selected]="selectedStoreType === 'E_COMMERCE'"
            ></zaver-zfb-radio-button>
            <span class="radio-button-label" i18n="@@editStore.form.eCommerce"
              >E-commerce</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="error-container">
      <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
      </app-form-error>
    </div>
  </form>
  <div class="two-cta-buttons">
    <zaver-primary-button
      text="Save"
      i18n-text="@@editStore.form.button.submit"
      [loading]="updatingStore"
      (click)="submit()"
    >
    </zaver-primary-button>

    <zaver-secondary-button-subtle
      text="Remove"
      i18n-text="@@editStore.form.button.remove"
      [disabled]="updatingStore"
      [iconUrl]="'assets/icons/Icon-Bin.svg'"
      (click)="promptCancel.emit(store)"
    ></zaver-secondary-button-subtle>
  </div>
</div>
