import {
  ChangeDetectorRef,
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { SignupLegalEntity } from '@core/dto/signup/owner/SignupLegalEntity';
import { Industry, IndustryOptions } from '@core/dto/signup/owner/Bransch';
import { LegalEntityService } from '@core/service/legal-entity.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { Owner } from '@core/dto/signup/owner/Owner';
import {
  b2bOrganizationNumberValidator,
  organizationNumberValidator,
  swedishNationalIdentificationNumberValidator,
  swedishNationalIdentificationRegex,
} from '@shared/validators/identification-number-validators';
import { AccessControlService } from '@core/service/access-control.service';
import { CardAccordionComponent } from 'app/zfb/ui/card-accordion/card-accordion.component';
import { Subject, Subscription } from 'rxjs';
import { CompanyInfoDto, SignupService } from '@core/service/signup.service';
import { FormValidators } from '@shared/validators/validators';
import { Permission } from '@core/dto/user-details';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { AppStateService } from '@core/service/app-state.service';
import { ColorService } from '@core/service/color.service';
import { formatPhoneNumber } from '../../../../../../web-component-library/projects/component-library/src/public-api';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['../signup-shared.css', './company-form.component.css'],
})
export class CompanyFormComponent
  extends BaseComponent
  implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit
{
  @Input() signupLegalEntity: SignupLegalEntity;
  @Input() companyRegistered: boolean;
  @Input() companyType: 'Aktiebolag' | 'Handelsbolag' | 'Kommanditbolag';
  @Input() displayingInCompanyAdminPage = false;
  @Input() displayingInCompanyInformationTab = false;
  @Input() displayingInCompanyOwnerInformationTab = false;
  @Input() contactInformationForm: FormGroup;

  initialForm: string;
  form: FormGroup;
  formChanged = false;
  formChangeSubscription: Subscription;
  showError = false;
  formErrorText = $localize`:@@companyForm.formErrorText:Please check your answers.`;
  startOrganizationNumberValidation = false;
  displayCompanyNotFoundError = false;
  submitting = false;
  correctOrganizationNumber = false;
  companyNamePlaceholder = $localize`:@@companyForm.companyInformation.companyName.placeholder:Collected from org.nr.`;

  modalActive = false;
  modalTitle = '';
  modalColor = this.colorService.getCeriseColorCode();
  displaySubmissionConfirmaiton = false;
  displayCancelConfirmation = false;
  confirmationSubject: Subject<boolean>;
  signupLegalEntityToCreate: SignupLegalEntity;

  @Output() onSaved = new EventEmitter();
  @Output() showContactFormError = new EventEmitter();

  @ViewChildren('owner') ownerAccordions!: QueryList<CardAccordionComponent>;
  @ViewChildren('nonOwner')
  nonOwnerAccordions!: QueryList<CardAccordionComponent>;

  validated = false;
  industryOptions = IndustryOptions.VALUES;

  selectedOwnerNumberOption = null;
  ownerNumberOptions = [
    {
      label: '0',
      value: 0,
    },
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
  ];

  selectedNonOwnerNumberOption = null;
  nonOwnerNumberOptions = [
    {
      label: 'Nej',
      value: 0,
    },
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '6',
      value: 6,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '8',
      value: 8,
    },
    {
      label: '9',
      value: 9,
    },
    {
      label: '10',
      value: 10,
    },
  ];

  constructor(
    private signupService: SignupService,
    private access: AccessControlService,
    private cdRef: ChangeDetectorRef,
    private legalEntityService: LegalEntityService,
    protected auth: AuthenticationService,
    private appStateService: AppStateService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.setupForm();
    if (this.displayingInCompanyAdminPage || this.companyRegistered) {
      this.correctOrganizationNumber = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.signupLegalEntity && this.displayingInCompanyAdminPage) {
      this.adminOnChanges();
    }
  }

  adminOnChanges(): void {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.formChangeSubscription = this.form.valueChanges.subscribe((val) => {
      if (JSON.stringify(this.form.value) === this.initialForm) {
        this.formChanged = false;
        this.showError = false;
        this.errorMessage = '';
      } else {
        this.formChanged = true;
      }
    });
  }

  onChanges(): void {
    this.form.controls['organizationNumber'].valueChanges.subscribe(() => {
      this.fetchCompanyInformationUsingOrgNumber();
    });
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  nrOwnerChange(event) {
    this.selectedOwnerNumberOption = event;
    if (this.selectedOwnerNumberOption < this.owners.length) {
      let controlsToRemove =
        this.owners.length - this.selectedOwnerNumberOption;
      while (controlsToRemove > 0) {
        this.owners.removeAt(this.owners.length - 1);
        controlsToRemove -= 1;
      }
    } else {
      for (
        let i = this.owners.length;
        i < this.selectedOwnerNumberOption;
        i++
      ) {
        this.owners.push(new FormGroup({}));
      }
    }
  }

  nrNonOwnerChange(event) {
    this.selectedNonOwnerNumberOption = event;
    if (this.selectedNonOwnerNumberOption < this.nonOwners.length) {
      let controlsToRemove =
        this.nonOwners.length - this.selectedNonOwnerNumberOption;
      while (controlsToRemove > 0) {
        this.nonOwners.removeAt(this.nonOwners.length - 1);
        controlsToRemove -= 1;
      }
    } else {
      for (
        let i = this.nonOwners.length;
        i < this.selectedNonOwnerNumberOption;
        i++
      ) {
        this.nonOwners.push(new FormGroup({}));
      }
    }
  }

  setupForm() {
    if (!this.signupLegalEntity) {
      // This is a normal signup
      this.form = new FormGroup({
        name: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        organizationNumber: new FormControl(
          { value: '', disabled: this.companyRegistered },
          [Validators.required, organizationNumberValidator()]
        ),
        website: new FormControl(''),
        industry: new FormControl(
          {
            value: this.industryOptions[0].value,
            disabled: !this.mayEditCompanyInfo(),
          },
          [Validators.required]
        ),
        industryOther: new FormControl(''),
      });
      if (this.mayUpdateCompanyOwnershipInfo()) {
        this.form.addControl('owners', new FormArray([]));
        this.form.addControl('nonOwners', new FormArray([]));
      }
      this.onChanges();
    } else if (!!this.signupLegalEntity && !this.companyRegistered) {
      // This is a partner signup
      this.form = new FormGroup({
        name: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        organizationNumber: new FormControl(
          {
            value: this.signupLegalEntity.organizationNumber,
            disabled: this.companyRegistered,
          },
          [Validators.required, organizationNumberValidator()]
        ),
        website: new FormControl(''),
        industry: new FormControl(
          {
            value: this.industryOptions[0].value,
            disabled: !this.mayEditCompanyInfo(),
          },
          [Validators.required]
        ),
        industryOther: new FormControl(''),
      });
      if (this.mayUpdateCompanyOwnershipInfo()) {
        this.form.addControl('owners', new FormArray([]));
        this.form.addControl('nonOwners', new FormArray([]));
      }
      this.onChanges();
      if (this.signupLegalEntity.organizationNumber) {
        // Company organization number was supplied on referal-link creation.
        // Try and fetch company name using the organization number
        this.fetchCompanyInformationUsingOrgNumber();
      }
    } else {
      // This is an onboarded merchant
      this.form = new FormGroup({
        name: new FormControl(
          { value: this.signupLegalEntity.name, disabled: true },
          [Validators.required]
        ),
        organizationNumber: new FormControl(
          {
            value: this.signupLegalEntity.organizationNumber,
            disabled: this.companyRegistered,
          },
          [Validators.required, organizationNumberValidator()]
        ),
        website: new FormControl({
          value: this.signupLegalEntity.website,
          disabled: !this.mayEditCompanyInfo(),
        }),
        industry: new FormControl(
          {
            value: this.signupLegalEntity.industry,
            disabled: !this.mayEditCompanyInfo(),
          },
          [Validators.required]
        ),
        industryOther: new FormControl(this.signupLegalEntity.industryOther),
      });
      this.form.addControl('owners', new FormArray([]));
      if (!!this.signupLegalEntity.owners) {
        this.selectedOwnerNumberOption = this.signupLegalEntity.owners.length;
        this.setupOwners(this.signupLegalEntity.owners, this.owners, false);
      } else {
        this.selectedOwnerNumberOption = 0;
      }
      this.form.addControl('nonOwners', new FormArray([]));
      if (!!this.signupLegalEntity.nonOwners) {
        this.selectedNonOwnerNumberOption =
          this.signupLegalEntity.nonOwners.length;
        this.setupOwners(
          this.signupLegalEntity.nonOwners,
          this.nonOwners,
          true
        );
      } else {
        this.selectedNonOwnerNumberOption = 0;
      }
      this.initialForm = JSON.stringify(this.form.value);
    }
  }

  setupOwners(owners: Owner[], formArray: FormArray, isNonOwners: boolean) {
    owners.forEach((owner) => {
      const formGroup = new FormGroup({
        name: new FormControl({ value: owner.name, disabled: true }, [
          Validators.required,
        ]),
        ownerType: new FormControl(owner.type, [Validators.required]),
        legalEntityId: new FormControl(owner.legalEntityId),
      });
      if (!isNonOwners) {
        formGroup.addControl(
          'holdingShare',
          new FormControl(owner.holdingShare, [
            Validators.required,
            FormValidators.numberMultipleDecimals(0, 100),
          ])
        );
        formGroup.addControl(
          'votesShare',
          new FormControl(owner.votesShare, [
            Validators.required,
            FormValidators.numberMultipleDecimals(0, 100),
          ])
        );
      }
      if (owner.type === 'legal') {
        formGroup.addControl(
          'organizationNumber',
          new FormControl(owner.organizationNumber, [
            Validators.required,
            b2bOrganizationNumberValidator(),
          ])
        );
        if (!isNonOwners) {
          formGroup.addControl(
            'numOwners',
            new FormControl(owner.owners.length, [Validators.required])
          );
          formGroup.addControl('owners', new FormArray([]));
          if (owner.owners.length > 0) {
            this.setupOwners(
              owner.owners,
              formGroup.get('owners') as FormArray,
              isNonOwners
            );
          }
        }
      } else {
        formGroup.addControl(
          'personalNumber',
          new FormControl(owner.personalNumber, [
            Validators.required,
            swedishNationalIdentificationNumberValidator(),
            Validators.pattern(
              swedishNationalIdentificationRegex
            ),
          ])
        );
        formGroup.addControl(
          'pep',
          new FormControl(owner.pep, [Validators.required])
        );
      }
      formArray.push(formGroup);
    });
  }

  get owners() {
    return this.form.get('owners') as FormArray;
  }

  getOwner(index: number): FormGroup {
    return this.owners.at(index) as FormGroup;
  }

  get nonOwners() {
    return this.form.get('nonOwners') as FormArray;
  }

  getNonOwner(index: number): FormGroup {
    return this.nonOwners.at(index) as FormGroup;
  }

  async submit() {
    this.clearError();
    this.validated = true;
    if (
      this.form.valid &&
      (!this.contactInformationForm ||
        this.contactInformationForm.valid ||
        this.contactInformationForm.disabled) &&
      (this.selectedOwnerNumberOption ||
        this.selectedOwnerNumberOption === 0) &&
      (this.selectedNonOwnerNumberOption ||
        this.selectedNonOwnerNumberOption === 0) &&
      this.mayUpdateCompanyOwnershipInfo()
    ) {
      this.errorMessage = '';
      this.formChanged = false;

      try {
        const activeRep =
          this.appStateService.getCurrentUser().activeRepresentation;

        const compRepUpdate: CompanyRepresentative = new CompanyRepresentative(
          activeRep.id,
          activeRep.personalNumber,
          formatPhoneNumber(this.contactInformationForm.controls.phone.value),
          activeRep.email,
          activeRep.role
        );
        // Save email och phone to the acting representative
        await this.signupService.updateSignupRepresentativeUserInfo(
          compRepUpdate
        );

        const signupLegalEntityToCreate =
          this.createSignupLegalEntityFromForm();
        const response = await this.legalEntityService.create(
          signupLegalEntityToCreate
        );

        if (this.displayingInCompanyAdminPage) {
          this.signupLegalEntity = response;
          this.setupForm();
          this.formChanged = false;
          this.showError = false;
        } else {
          this.onSaved.emit();
        }
      } catch (error: unknown) {
        this.handleError(error);
      }
    } else {
      this.ownerAccordions.forEach((component, index) => {
        const ownerFormGroup = this.owners.at(index) as FormGroup;
        if (ownerFormGroup.invalid) {
          component.forceOpen();
        }
      });
      this.nonOwnerAccordions.forEach((component, index) => {
        const nonOwnerFormGroup = this.nonOwners.at(index) as FormGroup;
        if (nonOwnerFormGroup.invalid) {
          component.forceOpen();
        }
      });
      this.showError = true;
      this.showContactFormError.emit();
    }
  }

  createSignupLegalEntityFromForm(): SignupLegalEntity {
    const signupLegalEntityToCreate = new SignupLegalEntity(this.companyType);
    signupLegalEntityToCreate.name = this.form.controls.name.value;
    signupLegalEntityToCreate.organizationNumber =
      this.form.controls.organizationNumber.value;
    signupLegalEntityToCreate.industry = this.form.controls.industry.value;
    signupLegalEntityToCreate.industryOther =
      signupLegalEntityToCreate.industry === Industry.OTHER
        ? this.form.controls.industryOther.value
        : null;
    signupLegalEntityToCreate.website = this.form.controls.website.value;
    if (this.selectedOwnerNumberOption > 0) {
      signupLegalEntityToCreate.owners = this.createOwners(this.owners, false);
    }
    if (this.selectedNonOwnerNumberOption > 0) {
      signupLegalEntityToCreate.nonOwners = this.createOwners(
        this.nonOwners,
        true
      );
    }

    return signupLegalEntityToCreate;
  }

  createOwners(formArray: FormArray, isNonOwners: boolean): Owner[] {
    const owners: Owner[] = [];
    formArray.controls.forEach((formGroup: FormGroup) => {
      const owner = new Owner();
      owner.type = formGroup.get('ownerType').value;
      owner.name = formGroup.get('name').value;
      if (!isNonOwners) {
        const holdingShare = formGroup.get('holdingShare').value;
        owner.holdingShare = holdingShare.toString().replace(/\s/g, '').replace(/,/g, '.');
        const votesShare = formGroup.get('votesShare').value;
        owner.votesShare = votesShare.toString().replace(/\s/g, '').replace(/,/g, '.');
      }
      if (owner.type === 'natural') {
        owner.personalNumber = formGroup.get('personalNumber').value.replace(/-/g, '');
        owner.pep = formGroup.get('pep').value;
      } else {
        owner.organizationNumber = formGroup.get('organizationNumber').value;
        if (!isNonOwners) {
          const numOwner = formGroup.get('numOwners').value;
          if (numOwner > 0) {
            owner.owners = this.createOwners(
              formGroup.get('owners') as FormArray,
              isNonOwners
            );
          }
        }
      }
      owner.legalEntityId = formGroup.get('legalEntityId').value;
      owners.push(owner);
    });
    return owners;
  }

  mayEditCompanyInfo(): boolean {
    if (this.displayingInCompanyAdminPage) {
      return this.access.userMay(Permission.EDIT_COMPANY_INFORMATION);
    }
    return true;
  }

  mayUpdateCompanyOwnershipInfo(): boolean {
    if (this.displayingInCompanyAdminPage) {
      return this.access.userMay(Permission.EDIT_OWNER_INFORMATION);
    }
    return true;
  }

  mayViewCompanyAgreement(): boolean {
    return this.access.userMay(Permission.GET_COMPANY_AGREEMENT);
  }

  getOwnerCardHeading(index: number) {
    const number = index + 1;
    const formGroup = this.owners.at(index) as FormGroup;
    if (formGroup.contains('name')) {
      return (
        'Ägare ' +
        number.toString() +
        ' av ' +
        this.form.controls.name.value +
        ': <span class="cerise-text">' +
        formGroup.controls.name.value +
        '</span>'
      );
    }
    return (
      'Ägare ' +
      number.toString() +
      ' av ' +
      this.form.controls.name.value +
      ':'
    );
  }

  getNonOwnerCardHeading(index: number) {
    const number = index + 1;
    const formGroup = this.nonOwners.at(index) as FormGroup;
    if (formGroup.contains('name')) {
      return (
        'Verklig huvudman ' +
        number.toString() +
        ': <span class="cerise-text">' +
        this.nonOwners.get([index, 'name']).value +
        '</span>'
      );
    } else {
      return 'Verklig huvudman ' + number.toString() + ':';
    }
  }

  promptCancelationConfirmation() {
    this.modalTitle = $localize`:@@companyForm.modal.cancel.title:Cancel`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.displayCancelConfirmation = true;
    this.openModal();
  }

  confirmCancelation() {
    this.cancel();
    this.closeModal();
  }

  cancel() {
    this.setupForm();
    this.adminOnChanges();
    this.formChanged = false;
    this.showError = false;
    this.errorMessage = '';
  }

  promptOwnerInfoSubmissionConfirmation() {
    this.clearError();
    this.validated = true;
    if (
      this.form.valid &&
      (this.selectedOwnerNumberOption ||
        this.selectedOwnerNumberOption === 0) &&
      (this.selectedNonOwnerNumberOption ||
        this.selectedNonOwnerNumberOption === 0) &&
      this.mayUpdateCompanyOwnershipInfo()
    ) {
      this.errorMessage = '';
      this.signupLegalEntityToCreate = this.createSignupLegalEntityFromForm();
      this.modalTitle = $localize`:@@companyForm.modal.updateOwner.title:Update owner information`;
      this.modalColor = '#00A880';
      this.displaySubmissionConfirmaiton = true;
      this.openModal();
    } else {
      this.ownerAccordions.forEach((component, index) => {
        const ownerFormGroup = this.owners.at(index) as FormGroup;
        if (ownerFormGroup.invalid) {
          component.forceOpen();
        }
      });
      this.nonOwnerAccordions.forEach((component, index) => {
        const nonOwnerFormGroup = this.nonOwners.at(index) as FormGroup;
        if (nonOwnerFormGroup.invalid) {
          component.forceOpen();
        }
      });
      this.showError = true;
    }
  }

  promptCompanyInfoSubmissionConfirmation() {
    if (!this.formChanged) {
      return;
    }
    this.clearError();
    this.validated = true;
    if (this.form.valid) {
      this.errorMessage = '';
      this.signupLegalEntityToCreate = this.createSignupLegalEntityFromForm();
      this.modalTitle = $localize`:@@companyForm.modal.update.title:Update company information`;
      this.modalColor = '#00A880';
      this.displaySubmissionConfirmaiton = true;
      this.openModal();
    } else {
      this.showError = true;
    }
  }

  closeModal() {
    this.modalActive = false;
    this.displaySubmissionConfirmaiton = false;
    this.displayCancelConfirmation = false;
  }

  openModal() {
    this.modalActive = true;
  }

  changesSaved(signupLegalEntity: SignupLegalEntity) {
    this.signupLegalEntity = signupLegalEntity;
    this.setupForm();
    this.formChanged = false;
    this.showError = false;
    this.errorMessage = '';
    this.closeModal();
    this.adminOnChanges();
  }

  fetchCompanyInformationUsingOrgNumber() {
    let organizationNumber: string =
      this.form.controls['organizationNumber'].value;
    organizationNumber = organizationNumber.replace(/-/g, '');
    if (organizationNumber.length >= 10) {
      this.startOrganizationNumberValidation = true;
    }
    if (this.form.controls['organizationNumber'].valid) {
      let companyNumberToSend = this.form.controls['organizationNumber'].value;
      companyNumberToSend = companyNumberToSend.replace(/-/g, '');
      this.signupService
        .getCompanyInfo(companyNumberToSend)
        .then((res) => {
          this.handleGetCompanyInfoResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          this.form.controls['name'].enable();
          this.companyNamePlaceholder = $localize`:@@companyForm.companyInformation.companyName.altPlaceholder:Enter company name`;
          this.correctOrganizationNumber = true;
        });
    } else {
      this.form.controls['name'].setValue('', { emitEvent: false });
      this.displayCompanyNotFoundError = false;
      this.form.controls['name'].disable();
      this.companyNamePlaceholder = $localize`:@@companyForm.companyInformation.companyName.placeholder:Collected from org.nr.`;
    }
  }

  handleGetCompanyInfoResponse(response: CompanyInfoDto): void {
    if (!!response.error) {
      if (response.error === 'COMPANY_NOT_FOUND') {
        this.displayCompanyNotFoundError = true;
      } else if (response.error === 'AWAITING_LOOKUP_RESPONSE') {
        return;
      }
      this.form.controls['name'].setValue('', { emitEvent: false });
      this.form.controls['name'].enable();
      this.companyNamePlaceholder = $localize`:@@companyForm.companyInformation.companyName.altPlaceholder:Enter company name`;
    } else {
      this.form.controls['name'].disable();
      this.companyNamePlaceholder = $localize`:@@companyForm.companyInformation.companyName.placeholder:Collected from org.nr.`;
      this.form.controls['name'].setValue(response.companyName, {
        emitEvent: false,
      });
    }
    this.correctOrganizationNumber = true;
  }

  getIndustryValue(): string {
    if (this.form.controls.industry.value === Industry.OTHER) {
      return this.form.controls.industryOther.value;
    } else {
      return this.form.controls.industry.value;
    }
  }

  handleIndustryValueChange(value: string) {
    if (Object.values<string>(Industry).includes(value)) {
      this.form.controls.industry.setValue(Industry[value]);
      this.form.controls.industryOther.clearValidators();
    } else {
      this.form.controls.industry.setValue(Industry.OTHER);
      this.form.controls.industryOther.setValue(value);
      this.form.controls.industryOther.setValidators([Validators.required]);
    }
    this.form.controls.industry.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    this.form.controls.industryOther.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }
}
