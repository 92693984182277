import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { InputSliderComponent } from '../input-slider/input-slider.component';

@Component({
  selector: 'zaver-list-slider',
  templateUrl: './list-slider.component.html',
  styleUrls: ['./list-slider.component.css']
})
export class ListSliderComponent implements OnInit {

  @Input() listOptions : ListOption[];
  @Input() initialValue: ListOption;
  @Input() color: string = '#DB305E';
  @Input() ariaLabel: string;
  @Input() shouldShowPresentedValue: boolean = true;
  @Input() shouldHideControllerButtons: boolean = true;

  min: number;
  max: number;

  initialValueIndex: number;
  presentedValue: string;
  setupReady: boolean;

  val: number;

  @Input() value : number;
  @Output() valueChange = new EventEmitter();
  @Output() inputChange = new EventEmitter();

  @Output() completedChange = new EventEmitter();

  @ViewChild('inputSlider') inputSlider: InputSliderComponent;

  constructor() { }

  ngOnInit(): void {
    if (!this.listOptions) {
      console.error('List options required as component input');
      return;
    }

    this.min = 0;
    this.max = this.listOptions.length - 1;
    if (this.value || this.value === 0) {
      this.initialValueIndex = this.listOptions.indexOf(this.listOptions.find(el => el.value === this.value));
    } else if (this.initialValue) {
      this.initialValueIndex = this.listOptions.indexOf(this.initialValue);
      if (this.initialValueIndex === -1) {
        this.initialValueIndex = 0;
      }
    }

    this.setupReady = true;

  }

  onValueChange(): void {
    this.presentedValue = this.listOptions[this.val].label;
    this.valueChange.emit(this.listOptions[this.val].value);
  }

  onFinishedChange(): void {
    this.completedChange.emit();
  }

  onButtonClicked(isPlus: boolean): void {
    if (isPlus && this.val < this.max) {
      this.val++;
    }

    if (!isPlus && this.val > 0) {
      this.val--;
    }

    this.onValueChange();
    this.onFinishedChange();
  }

}

export class ListOption {
  label: string;
  value: number;
}
