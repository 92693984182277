<div class="card" [class.in-admin]="displayingInCompanyAdminPage">
  <div class="card-heading">
    <h2 i18n="@@soleTraderForm.header">Company information</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="form" novalidate>
      <div class="input-group-zfb">
        <div class="input-fields">
          <zaver-form-input
            title="Personal number"
            i18n-title="@@soleTraderForm.personalNumber.title"
            placeholder="Enter personal number"
            i18n-placeholder="@@soleTraderForm.personalNumber.placeholder"
            [error]="
              form.controls.personalNumber.invalid &&
              (showError || startOrganizationNumberValidation)
            "
            errorMessage="
              Please enter a valid personal number (yyyymmddxxxx)
            "
            i18n-errorMessage="@@soleTraderForm.personalNumber.errorMessage"
            formControlName="personalNumber"
            [inputMode]="'tel'"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
        <div class="input-fields" *ngIf="displayCompanyNotFoundError">
          <app-form-error
            [errorText]="
              'We can not find a company tied to the entered personal number.'
            "
            i18n-errorText="@@soleTraderForm.error"
          >
          </app-form-error>
        </div>
        <div class="input-fields" [class.hide]="!correctOrganizationNumber">
          <zaver-form-input
            title="Company name"
            i18n-title="@@soleTraderForm.company.title"
            [placeholder]="companyNamePlaceholder"
            formControlName="name"
            [error]="form.controls.name.invalid && showError"
            errorMessage="This field can not be empty"
            i18n-errorMessage="@@soleTraderForm.company.errorMessage"
            [maxLength]="255"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
      </div>
      <div class="input-group-zfb" [class.hide]="!correctOrganizationNumber">
        <div class="input-fields">
          <zaver-form-input
            title="Homepage"
            i18n-title="@@soleTraderForm.homepage.title"
            placeholder="Enter the company's homepage"
            i18n-placeholder="@@soleTraderForm.homepage.placeholder"
            formControlName="website"
            [inputMode]="'url'"
            [inputType]="'url'"
            [size]="'large'"
          >
          </zaver-form-input>
        </div>
        <div style="width: 100%">
          <app-agreement
            *ngIf="displayingInCompanyAdminPage"
            class="desktop-agreement"
            [displayingInCompanyAdminPage]="true"
          ></app-agreement>
        </div>
      </div>
      <div
        [class.industry-agreement-container]="displayingInCompanyAdminPage"
        [class.hide]="!correctOrganizationNumber"
      >
        <app-radio-select
          [value]="getIndustryValue()"
          (valueChange)="handleIndustryValueChange($event)"
          [options]="industryOptions"
          [showRequiredError]="showError && form.controls.industryOther.invalid"
          errorMessage="Please enter an industry"
          i18n-errorMessage="@@soleTraderForm.industryOption.errorMessage"
          [displayingInCompanyAdminPage]="displayingInCompanyAdminPage"
          [seperateInTwoColumns]="displayingInCompanyAdminPage"
        >
        </app-radio-select>
        <app-agreement
          *ngIf="displayingInCompanyAdminPage"
          class="mobile-agreement"
          [displayingInCompanyAdminPage]="true"
        ></app-agreement>
      </div>

      <hr class="divider" [class.hide]="!correctOrganizationNumber" />
      <div
        class="label"
        [class.hide]="!correctOrganizationNumber"
        i18n="@@soleTraderForm.questionOrganization"
      >
        Are you or have you during the last couple of months been a person
        of politically exposed position or has/is anyone in relation to
        you a person of politically exposed position?
      </div>
      <app-radio-select
        [class.hide]="!correctOrganizationNumber"
        [value]="form.controls.pep.value"
        (valueChange)="form.controls.pep.setValue($event)"
        [options]="pepOptions"
        errorMessage="Please enter an option"
        i18n-errorMessage="@@soleTraderForm.questionOrganization.errorMessage"
        [showRequiredError]="showError && form.controls.pep.invalid"
        [displayOtherOption]="false"
      >
      </app-radio-select>
      <div id="admin-buttons" *ngIf="displayingInCompanyAdminPage">
        <zaver-secondary-button-outlined
          *ngIf="formChanged"
          style="width: 110px"
          (click)="promptCancelationConfirmation()"
          text="Cancel"
          i18n-text="@@soleTraderForm.button.cancel"
        ></zaver-secondary-button-outlined>
        <zaver-primary-button
          style="width: 110px; margin-left: 12px"
          (click)="promptCompanyInfoSubmissionConfirmation()"
          [disabled]="!formChanged"
          text="Save"
          i18n-text="@@soleTraderForm.button.save"
          [color]="'cerise'"
        ></zaver-primary-button>
      </div>
    </form>
  </div>
</div>
<app-page-error
  *ngIf="errorMessage"
  [errorMessage]="errorMessage"
></app-page-error>
<app-page-error
  *ngIf="
    showError &&
    (form.invalid ||
      (contactInformationForm && contactInformationForm.invalid))
  "
  [errorMessage]="formErrorText"
></app-page-error>

<div *ngIf="!displayingInCompanyAdminPage" class="next-button-container">
  <button
    class="next-button cerise-bg"
    i18n="@@soleTraderForm.button.Next"
    (click)="submit()"
  >
    Next
  </button>
</div>

<zaver-details-modal
  *ngIf="modalActive"
  (onClose)="action(false)"
  [title]="modalTitle"
  [color]="modalColor"
>
  <div *ngIf="displaySubmissionConfirmaiton" class="small-modal-wrapper">
    <div i18n="@@soleTraderForm.confirmationText">
      Are you sure you want to save these changes? 👋
    </div>
    <app-form-error *ngIf="errorMessage" [errorText]="errorMessage">
    </app-form-error>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@soleTraderForm.button.back"
        [disabled]="submitting"
        (click)="action(false)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, save"
        i18n-text="@@soleTraderForm.button.confirm"
        [color]="'black'"
        [loading]="submitting"
        (click)="action(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
  <div *ngIf="displayCancelConfirmation" class="small-modal-wrapper">
    <div i18n="@@soleTraderForm.cancellationText">
      Are you sure you want to cancel? The changes will not be saved. 😯
    </div>

    <div class="button-container">
      <zaver-secondary-button-subtle
        style="width: auto"
        text="Back"
        i18n-text="@@soleTraderForm.button.back"
        (click)="action(false)"
        [size]="'x-small'"
      ></zaver-secondary-button-subtle>
      <zaver-primary-button
        style="width: auto"
        text="Yes, cancel"
        i18n-text="@@soleTraderForm.button.altCancel"
        [color]="'black'"
        (click)="action(true)"
        [size]="'x-small'"
      ></zaver-primary-button>
    </div>
  </div>
</zaver-details-modal>
