import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { SignupQuestions } from '@core/dto/signup/SignupQuestions';
import { QuestionsService } from '@core/service/questions.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { NUMBER_PATTERN } from '@core/patterns';
import { AccessControlService } from '@core/service/access-control.service';
import { Subscription } from 'rxjs';
import { CashoutTaskService } from '@core/service/cashout-task.service';
import { Permission } from '@core/dto/user-details';
import { CompanyAdministrationService } from '@core/service/company-administration.service';
import { CompanyKyc } from '@core/dto/administration/CompanyKyc';
import { CompanyBankDetails } from '@core/dto/administration/CompanyBankDetails';
import { Market } from '@core/dto/Market';
import { AppStateService } from '@core/service/app-state.service';

@Component({
  selector: 'app-signup-questions',
  templateUrl: './detailed-questions-form.component.html',
  styleUrls: [
    '../signup-shared.css',
    './detailed-questions-form.component.css',
  ],
})
export class DetailedQuestionsFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() displayingInCompanyAdminPage: boolean = false;

  signupQuestions: SignupQuestions;
  bankForm: FormGroup;
  kycForm: FormGroup;
  showError: boolean = false;
  fetchingResponse: boolean = true;
  formErrorText: string = $localize`:@@detailedQuestions.formErrorText:Please check your answers.`;

  // Admin page specifics
  kycFormChangedSubscription: Subscription;
  bankFormChangedSubscription: Subscription;
  kycFormChanged: boolean = false;
  bankFormChanged: boolean = false;
  initialKycForm: string;
  initialBankForm: string;

  // Variables related to the bank data submission confirmation modal
  modalActive: boolean = false;
  modalTitle: string = '';
  modalColor: string = '#DB305E';
  displaySubmissionConfirmaiton: boolean = false;
  displayCancelConfirmation: boolean = false;

  activeMarket: Market;
  market: Market;

  signupQuestionsToCreate: SignupQuestions;

  @Output() onSaved = new EventEmitter<SignupQuestions>();
  @Output() back = new EventEmitter();
  @Output() doneFetchingResponse = new EventEmitter();

  amountOptions = [
    {
      label: 'Mellan SEK 0 - 1 000 000',
      value: 'Mellan SEK 0 - 1 000 000',
    },
    {
      label: 'Mer än SEK 1 000 000',
      value: 'Mer än SEK 1 000 000',
    },
  ];

  frequencyOptions = [
    {
      label: '1 - 12 betalningar per år',
      value: '1 - 12 betalningar per år',
    },
    {
      label: 'Fler än 1 betalningar per månad',
      value: 'Fler än 1 betalningar per månad',
    },
  ];

  transactionPurposeValues = SignupQuestions.TRANSACTION_PURPOSE_VALUES;
  businessPurposeValues = SignupQuestions.BUSINESS_PURPOSE_VALUES;

  constructor(
    private access: AccessControlService,
    private questionsService: QuestionsService,
    protected auth: AuthenticationService,
    private cashoutTaskService: CashoutTaskService,
    private companyAdministrationService: CompanyAdministrationService,
    private appStateService: AppStateService
  ) {
    super(auth);
  }

  ngOnInit() {
    this.activeMarket = this.appStateService.getMarket();

    if (this.displayingInCompanyAdminPage) {
      this.fetchQuestionsAdmin();
    } else {
      this.fetchQuestions();
    }
  }

  fetchQuestions() {
    this.fetchingResponse = true;
    this.questionsService
      .get()
      .then((response) => {
        this.signupQuestions = response;
        this.setupKycForm();
        this.setupBankForm();
        this.onChanges();
        this.fetchingResponse = false;
        this.doneFetchingResponse.emit();
      })
      .catch((error) => {
        if (error.status && error.status === 404) {
          this.setupKycForm();
          this.setupBankForm();
          this.onChanges();
        } else {
          super.handleError(error);
        }
        this.fetchingResponse = false;
        this.doneFetchingResponse.emit();
      });
  }

  async fetchQuestionsAdmin() {
    this.fetchingResponse = true;
    let kyc: CompanyKyc;
    if (this.mayGetKyc()) {
      kyc = await this.companyAdministrationService.getKyc();
    }
    let bankDetails: CompanyBankDetails;
    if (this.mayGetBankDetails()) {
      bankDetails = await this.companyAdministrationService.getBankDetails();
    }

    this.signupQuestions = new SignupQuestions(
      kyc ? kyc.amount : null,
      kyc ? kyc.frequency : null,
      kyc ? kyc.transactionPurpose : null,
      kyc ? kyc.businessPurpose : null,
      bankDetails ? bankDetails.clearingNumber : null,
      bankDetails ? bankDetails.accountNumber : null,
      bankDetails ? bankDetails.bank : null,
      bankDetails ? bankDetails.iban : null,
      bankDetails ? bankDetails.bic : null
    );

    this.setupKycForm();
    this.setupBankForm();
    this.onChanges();
    this.fetchingResponse = false;
    this.doneFetchingResponse.emit();
  }

  onChanges(): void {
    if (this.activeMarket === Market.SE &&
      !this.displayingInCompanyAdminPage
    ) {
      this.bankForm.controls['clearingNumber'].valueChanges.subscribe((val) => {
        if (this.bankForm.controls['clearingNumber'].valid) {
          this.cashoutTaskService
            .getBankName(this.bankForm.controls['clearingNumber'].value)
            .subscribe((res) => {
              this.bankForm.controls['bank'].setValue(res.bankName, {
                emitEvent: false,
              });
            });
        } else {
          this.bankForm.controls['bank'].setValue('', { emitEvent: false });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.kycFormChangedSubscription) {
      this.kycFormChangedSubscription.unsubscribe();
    }
  }

  setupKycForm() {
    if (this.signupQuestions) {
      this.kycForm = new FormGroup({
        amount: new FormControl(
          {
            value: this.signupQuestions.amount,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
        frequency: new FormControl(
          {
            value: this.signupQuestions.frequency,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
        transactionPurpose: new FormControl(
          {
            value: this.signupQuestions.transactionPurpose,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
        businessPurpose: new FormControl(
          {
            value: this.signupQuestions.businessPurpose,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
      });
    } else {
      this.kycForm = new FormGroup({
        amount: new FormControl(
          { value: null, disabled: !this.mayChangeKYC() },
          [Validators.required]
        ),
        frequency: new FormControl(
          { value: null, disabled: !this.mayChangeKYC() },
          [Validators.required]
        ),
        transactionPurpose: new FormControl(
          {
            value: this.transactionPurposeValues[0].value,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
        businessPurpose: new FormControl(
          {
            value: this.businessPurposeValues[0].value,
            disabled: !this.mayChangeKYC(),
          },
          [Validators.required]
        ),
      });
    }
    if (this.displayingInCompanyAdminPage) {
      this.initialKycForm = JSON.stringify(this.kycForm.value);
      if (this.kycFormChangedSubscription) {
        this.kycFormChangedSubscription.unsubscribe();
      }
      this.kycFormChangedSubscription = this.kycForm.valueChanges.subscribe(
        (val) => {
          // Compare the current KYC form with the initial form KYC
          if (JSON.stringify(this.kycForm.value) === this.initialKycForm) {
            this.kycFormChanged = false;
            this.showError = false;
            this.errorMessage = '';
          } else {
            this.kycFormChanged = true;
          }
        }
      );
    }
  }

  setupBankForm() {
    if (this.signupQuestions) {
      this.bankForm = new FormGroup({
        bank: new FormControl(
          { value: this.signupQuestions.bank, disabled: true },
          [Validators.required]
        ),
      });
      if (this.activeMarket === Market.SE) {
        // SE
        this.bankForm.addControl(
          'clearingNumber',
          new FormControl(
            {
              value: this.signupQuestions.clearingNumber,
              disabled: this.displayingInCompanyAdminPage,
            },
            [
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(5),
              Validators.pattern(NUMBER_PATTERN),
            ]
          )
        );
        this.bankForm.addControl(
          'accountNumber',
          new FormControl(
            {
              value: this.signupQuestions.accountNumber,
              disabled: this.displayingInCompanyAdminPage,
            },
            [
              Validators.required,
              Validators.minLength(7),
              Validators.maxLength(12),
              Validators.pattern(NUMBER_PATTERN),
            ]
          )
        );
      } else if (this.activeMarket === Market.AT ||
        this.activeMarket === Market.DE
      ) {
        // DE
        this.bankForm.addControl(
          'iban',
          new FormControl(
            {
              value: this.signupQuestions.iban,
              disabled: true,
            },
            [Validators.required]
          )
        );
        this.bankForm.addControl(
          'bic',
          new FormControl(
            {
              value: this.signupQuestions.bic,
              disabled: true,
            },
            [Validators.required]
          )
        );
      }
    } else {
      // This is a signup. Signups are currently only available in SE.
      // Todo: update this when signups are not SE only
      this.bankForm = new FormGroup({
        bank: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        clearingNumber: new FormControl(
          { value: '', disabled: this.displayingInCompanyAdminPage },
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(5),
            Validators.pattern(NUMBER_PATTERN),
          ]
        ),
        accountNumber: new FormControl(
          { value: '', disabled: this.displayingInCompanyAdminPage },
          [
            Validators.required,
            Validators.minLength(7),
            Validators.maxLength(12),
            Validators.pattern(NUMBER_PATTERN),
          ]
        ),
      });
    }
  }

  getSignupQuestionsFromForm(): SignupQuestions {
    return new SignupQuestions(
      this.kycForm.controls.amount.value,
      this.kycForm.controls.frequency.value,
      this.kycForm.controls.transactionPurpose.value,
      this.kycForm.controls.businessPurpose.value,
      this.activeMarket === Market.SE
        ? this.bankForm.controls.clearingNumber.value
        : null,
      this.activeMarket === Market.SE
        ? this.bankForm.controls.accountNumber.value
        : null,
      this.bankForm.controls.bank.value,
        this.activeMarket === Market.AT ||
        this.activeMarket === Market.DE
          ? this.bankForm.controls.iban.value
          : null,
        this.activeMarket === Market.AT ||
        this.activeMarket === Market.DE
          ? this.bankForm.controls.bic.value
          : null
    );
  }

  submit() {
    this.clearError();
    if (this.kycForm.valid && this.bankForm.valid) {
      this.showError = false;
      this.errorMessage = '';
      this.signupQuestionsToCreate = this.getSignupQuestionsFromForm();
      this.questionsService
        .create(this.signupQuestionsToCreate)
        .then((response) => {
          this.onSaved.emit(response);
        })
        .catch((error) => {
          if (error.error === 'Invalid bank information given') {
            this.errorMessage =
              'Det bankkontonummer du angivit är inte giltigt.';
          } else {
            super.handleError(error);
          }
        });
    } else {
      this.showError = true;
    }
  }

  mayChangeKYC(): boolean {
    if (this.displayingInCompanyAdminPage) {
      return this.access.userMay(Permission.EDIT_KYC);
    }
    return true;
  }

  promptKycCancelationConfirmation() {
    this.modalTitle = $localize`:@@detailedQuestionsForm.modal.title.cancel:Cancel`;
    this.modalColor = '#DB305E';
    this.displayCancelConfirmation = true;
    this.modalActive = true;
  }

  promptKycSubmissionConfirmation() {
    this.clearError();
    if (this.kycForm.valid) {
      this.signupQuestionsToCreate = this.getSignupQuestionsFromForm();
      this.modalTitle = $localize`:@@detailedQuestionsForm.modal.title.updateKyc:Update KYC`;
      this.modalColor = '#00A880';
      this.displaySubmissionConfirmaiton = true;
      this.modalActive = true;
    } else {
      this.showError = true;
    }
  }

  changesSaved(signupQuestions: SignupQuestions) {
    this.showError = false;
    this.errorMessage = '';
    this.kycFormChanged = false;
    this.signupQuestions = signupQuestions;
    this.setupKycForm();
    this.closeModal();
    this.onSaved.emit(signupQuestions);
  }

  cancelKycForm() {
    this.closeModal();
    this.setupKycForm();
    this.kycFormChanged = false;
    this.showError = false;
    this.errorMessage = '';
  }

  closeModal() {
    this.modalActive = false;
    this.displaySubmissionConfirmaiton = false;
    this.displayCancelConfirmation = false;
  }

  mayGetKyc(): boolean {
    return this.access.userMay(Permission.GET_KYC);
  }

  mayGetBankDetails(): boolean {
    return this.access.userMay(Permission.GET_BANK_INFORMATION);
  }
}
