import {Component} from '@angular/core';
import {BidProgress} from '@core/dto/Progress';
import {Router} from '@angular/router';
import {BaseComponent} from '@core/base.component';
import {AuthenticationService} from '@core/service/authentication.service';
import {AppStateService} from '@core/service/app-state.service';
import {CompanyStatus, UserDetails} from '@core/dto/user-details';

@Component({
  selector: 'app-login',
  templateUrl: './bank-id-login.component.html',
  styleUrls: ['../login-pages.scss', './bank-id-login.component.css'],
})
export class BankIdLoginComponent
    extends BaseComponent {

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      protected auth: AuthenticationService,
      private appState: AppStateService,
  ) {
    super(auth);
  }

  handleSuccess(progress: BidProgress<any>) {
    this.appState.updateCurrentUser(progress.payload);

    // find representation of companies that are approved
    const approved = this.appState
    .getAvailableRepresentations()
    .filter((rep) =>
        rep.company.status.find(
            (status) => status === CompanyStatus.APPROVED
        )
    );

    let result: Promise<UserDetails>;
    if (approved.length === 1) {
      // if there is only one representation then select it automatically
      result = this.authenticationService.switchProfile(approved[0]);
      result.then(() => this.router.navigate(['/'], { skipLocationChange: false }));
    } else {
      this.router.navigate(['/'], { skipLocationChange: false });
    }
  }

  goBackClick() {
    history.back();
  }
}
