import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  PasswordLoginError,
  PasswordLoginResponse,
} from '@core/service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from '@core/service/app-state.service';
import { CompanyStatus, UserDetails } from '@core/dto/user-details';
import { BaseComponent } from '@core/base.component';
import { VerificationTokenService } from '@core/service/verification-token.service';
import { EMAIL_PATTERN } from '@core/patterns';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-password-entry',
  templateUrl: './password-entry.component.html',
  styleUrls: ['../login-pages.scss', './password-entry.component.css'],
})
export class PasswordEntryComponent
  extends BaseComponent
  implements OnInit
{
  form: FormGroup;
  formError = '';
  defaultFormError = $localize`:@@passwordEntry.error.default:Incorrect password`;
  email: string;
  EMAIL_KEY = 'email';
  TWO_FACTOR_KEY = 'two_factor_authentication';

  loginMsg = '';
  processingLogin = false;
  resetError: string;

  showError = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: VerificationTokenService,
    protected auth: AuthenticationService,
    private appState: AppStateService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit() {
    let storedEmail = null;
    try {
      storedEmail = localStorage.getItem(this.EMAIL_KEY);
      if (storedEmail === 'undefined' || storedEmail === 'null') {
        storedEmail = null;
      }
    } catch (e) {}
    const shouldRememberMe = storedEmail !== '';
    this.email = decodeURIComponent(
      this.route.snapshot.queryParamMap.get('email') || ''
    );
    this.form = new FormGroup({
      email: new FormControl(this.email, [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(shouldRememberMe),
    });
  }

  submit() {
    if (this.form.disabled || this.processingLogin) {
      return;
    }
    super.clearError();
    if (this.form.valid) {
      this.showError = false;
      this.disableForm();
      this.formError = null;
      this.loginMsg = $localize`:@@passwordEntry.loginMsg.starting:Starting...`;
      // this.form.markAsUntouched();

      const password = this.form.value.password;
      const rememberMe = this.form.value.rememberMe;
      this.email = this.form.value.email.trim();
      const authSessionId = localStorage.getItem(this.TWO_FACTOR_KEY);

      this.authenticationService
        .initializePasswordLogin(this.email, password, authSessionId)
        .then((progress: PasswordLoginResponse) => {
          if (rememberMe && this.email) {
            localStorage.setItem(this.EMAIL_KEY, this.email);
          }
          if (progress.success) {
            // successful login
            this.appState.updateCurrentUser(progress.userDetails);

            // find representation of companies that are approved
            const approved = this.appState
              .getAvailableRepresentations()
              .filter((rep) =>
                rep.company.status.find(
                  (status) => status === CompanyStatus.APPROVED
                )
              );

            let result: Promise<UserDetails>;
            if (approved.length === 1) {
              // if there is only one representation then select it automatically
              result = this.authenticationService.switchProfile(approved[0]);
              result.then((details) =>
                this.router.navigate(['/'], { skipLocationChange: false })
              );
            } else {
              this.router.navigate(['/'], { skipLocationChange: false });
            }
          } else if (!progress.success) {
            if (progress.error === PasswordLoginError.IDENTIFIED_USER) {
              this.router.navigate(['/bankid-login'], {
                queryParams: { email: this.email },
              });
            } else if (
              progress.error === PasswordLoginError.TWO_FACTOR_AUTHENTICATION
            ) {
              // TwoFactorAuthentication token has been sent to the user, navigate to token-entry
              this.tokenService.email = this.email;
              this.router.navigate(['token-entry-two-factor']);
            }
            this.enableForm();
            this.loginMsg = '';
            this.formError = this.getError(progress.error);
          }
        })
        .catch((error) => {
          this.enableForm();
          super.handleError(error);
        });
    } else {
      this.showError = true;
      this.enableForm();
    }
  }

  private getError(error: PasswordLoginError) {
    switch (error) {
      case PasswordLoginError.IDENTIFIED_USER:
        break;
      case PasswordLoginError.REFRESH_EMAIL_SENT:
        break;

      case PasswordLoginError.NO_ERROR:
      case PasswordLoginError.UNKNOWN_ERROR:
        return $localize`:@@passwordEntry.error.other:Something went wrong`;
      case PasswordLoginError.INVALID_CREDENTIALS:
        return $localize`:@@passwordEntry.error.invalidPassword:Incorrect password`;
    }
  }

  setFormError(error: string) {
    this.formError = error;
    this.form.get('email').setErrors({ key: error });
  }

  private enableForm() {
    this.form.enable();
    this.processingLogin = false;
  }

  private disableForm() {
    this.processingLogin = true;
    this.form.disable();
  }

  rememberMeClicked(): void {
    if (!this.form.value.rememberMe) {
      // if the user un-checks the remember-me-checkbox we will set the value to '' (in contrast to just undefined);
      // this indicates that the user does not want to be remembered
      localStorage.setItem(this.EMAIL_KEY, '');
    }
  }

  resetPassword() {
    if (this.form.value.email && this.form.controls['email'].valid) {
      this.tokenService.email = this.email;
      this.tokenService.resetPassword(this.email);
      this.router.navigate(['password-reset-token']);
    } else {
      this.formError = $localize`:@@passwordEntry.error.resetPassword.invalidEmail:Please enter a valid email address above to reset your password`;
      this.enableForm();
    }
  }
}
