<div *ngIf="showCompanyInfoForm" class="create-container spacing-container">
  <form [formGroup]="companyInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="
        companyInfoForm.controls.companyNumber.invalid && showError
      "
    >
      <zaver-form-input
        [showContent]="true"
        title="Organization number"
        i18n-title="@@createPaymentRequestCompany.companyNumber.title"
        placeholder="Enter organization number"
        i18n-placeholder="@@createPaymentRequestCompany.companyNumber.placeholder"
        [error]="companyInfoForm.controls.companyNumber.invalid && showError"
        errorMessage="Please enter a valid organization number"
        i18n-errorMessage="@@createPaymentRequestCompany.companyNumber.errorMessage"
        formControlName="companyNumber"
        [inputMode]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Office-Business-Card.svg" />
      </zaver-form-input>
    </div>
    <div
      *ngIf="companyInfoResponse && companyInfoResponse.companyName"
      id="company-name"
    >
      {{ companyInfoResponse.companyName }}
    </div>
    <div
      class="input-fields"
      [class.error]="companyInfoForm.controls.phone.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        title="Mobile number"
        i18n-title="@@createPaymentRequestCompany.phone.title"
        placeholder="Enter mobile number"
        i18n-placeholder="@@createPaymentRequestCompany.phone.placeholder"
        [error]="companyInfoForm.controls.phone.invalid && showError"
        errorMessage="Please enter a valid mobile number"
        i18n-errorMessage="@@createPaymentRequestCompany.phone.errorMessage"
        formControlName="phone"
        [inputMode]="'tel'"
        [inputType]="'tel'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-Phone.svg" />
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="companyInfoForm.controls.email.invalid && showError"
    >
      <zaver-form-input
        title="Email address"
        i18n-title="@@createPaymentRequestCompany.email.title"
        [showContent]="true"
        placeholder="Enter email address"
        i18n-placeholder="@@createPaymentRequestCompany.email.placeholder"
        [error]="companyInfoForm.controls.email.invalid && showError"
        errorMessage="Please enter a valid email address"
        i18n-errorMessage="@@createPaymentRequestCompany.email.errorMessage"
        formControlName="email"
        [maxLength]="255"
        [inputMode]="'email'"
        [inputType]="'email'"
        [size]="'large'"
      >
        <img src="assets/icons/Icon-E-mail.svg" />
      </zaver-form-input>
    </div>
    <div *ngIf="formError" class="input-fields">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>
  <div *ngIf="!processing" class="continue-back-buttons-container">
    <zaver-primary-button
      text="Continue"
      i18n-text="@@createPaymentRequestCompany.button.continue"
      (click)="continueToInvoiceInfoForm()"
      style="margin-bottom: 16px"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      text="Back"
      i18n-text="@@createPaymentRequestCompany.button.back"
      (click)="returnToSelection.emit()"
    ></zaver-secondary-button-subtle>
  </div>
  <app-page-loader *ngIf="processing"></app-page-loader>
</div>
<div class="create-container spacing-container" *ngIf="showInvoiceInfoForm">
  <form [formGroup]="invoiceInfoForm" novalidate>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.invalid && showError"
    >
      <app-date-picker
        title="Due date"
        i18n-title="@@createPaymentRequestCompany.dueDate.title"
        placeholder="yyyy-mm-dd"
        i18n-placeholder="@@createPaymentRequestCompany.dueDate.placeholder"
        [minDateInput]="minDueDate"
        [maxDateInput]="maxDueDate"
        [formGroup]="invoiceInfoForm"
        [showError]="showError"
        [formName]="'dueDate'"
        [initialDate]="initialDate"
      >
      </app-date-picker>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.price.invalid && showError"
    >
      <zaver-form-input
        [showContent]="true"
        title="Amount"
        i18n-title="@@createPaymentRequestCompany.amount.title"
        placeholder="Enter amount"
        i18n-placeholder="@@createPaymentRequestCompany.amount.placeholder"
        [error]="invoiceInfoForm.controls.price.invalid && showError"
        [errorMessage]="
          'Enter an amount between 1,00 - 999 999,00 '
          + currencyCode
          + ' (up to two decimals)'
        "
        i18n-errorMessage="@@createPaymentRequestCompany.amount.errorMessage"
        formControlName="price"
        [inputMode]="'decimal'"
        [size]="'large'"
      >
        <span style="text-align: center; font-weight: 400; font-size: 14px">
          {{ currencyCode }}
        </span>
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.title.invalid && showError"
    >
      <zaver-form-input
        [title]="prFormTitleAlias"
        [placeholder]="prFormTitleAlias"
        formControlName="title"
        [error]="invoiceInfoForm.controls.title.invalid && showError"
        errorMessage="This field can not be empty (max 255 characters)"
        i18n-errorMessage="@@createPaymentRequestCompany.title.errorMessage"
        [maxLength]="255"
        [lengthOfValue]="invoiceInfoForm.controls.title.value.length"
        [showCounter]="true"
        [size]="'large'"
      >
      </zaver-form-input>
    </div>
    <div
      class="input-fields"
      [class.error]="invoiceInfoForm.controls.description.invalid && showError"
    >
      <zaver-zfb-textarea
        title="Description"
        i18n-title="@@createPaymentRequestCompany.description.title"
        placeholder="Description"
        i18n-placeholder="@@createPaymentRequestCompany.description.placeholder"
        formControlName="description"
        [error]="invoiceInfoForm.controls.description.invalid && showError"
        errorMessage="Enter a description (max 50000 characters)"
        i18n-errorMessage="@@createPaymentRequestCompany.description.errorMessage"
      >
      </zaver-zfb-textarea>
    </div>
    <div *ngIf="formError" class="input-fields">
      <app-form-error [errorText]="formError"> </app-form-error>
    </div>
  </form>
  <div *ngIf="!processing" class="continue-back-buttons-container">
    <zaver-primary-button
      style="margin-bottom: 16px"
      text="Request has been sent"
      i18n-text="@@createPaymentRequestCompany.button.requestSent"
      [loading]="processing"
      (click)="submit()"
    ></zaver-primary-button>
    <zaver-secondary-button-subtle
      text="Back"
      i18n-text="@@createPaymentRequestCompany.button.back"
      [disabled]="processing"
      (click)="returnToCompanyInfoForm()"
    ></zaver-secondary-button-subtle>
  </div>
</div>
