import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  public getCeriseColorCode(): string {
    // we are switching to only use the "german" color now so no need for the if check here anymore
      return '#DB305E';
  }

  public getCeriseColorCodeAsType(): 'cerise' {
      return 'cerise';
  }
}
