import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyRepresentationRoleColorRepresentations } from '@assets/language/RoleColorRepresentation';
import { BaseComponent } from '@core/base.component';
import { CompanyRepresentative } from '@core/dto/CompanyRepresentative';
import { CompanyRepresentativeRole } from '@core/dto/CompanyRepresentativeRole';
import { Market } from '@core/dto/Market';
import { Store } from '@core/dto/Store';
import {
  PaymentRequestPermission,
  StoreRelation,
} from '@core/dto/StoreRelation';
import { Permission } from '@core/dto/user-details';
import { EMAIL_PATTERN } from '@core/patterns';
import { availableCountryCodes, setCountryFlag } from '@core/select-country';
import { AccessControlService } from '@core/service/access-control.service';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { CompanyRepresentativeService } from '@core/service/company-representative.service';
import { CustomizationService } from '@core/service/customization.service';
import { LocaleService } from '@core/service/locale.service';
import { StoreService } from '@core/service/store.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { swedishNationalIdentificationNumberValidator, swedishNationalIdentificationRegex } from '@shared/validators/identification-number-validators';
import { Subscription } from 'rxjs';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';
import { Item } from '../../../../../../web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-edit-company-representative',
  templateUrl: './edit-company-representative.component.html',
  styleUrls: ['./edit-company-representative.component.scss'],
})
export class EditCompanyRepresentativeComponent
  extends BaseComponent
  implements OnInit
{
  @Input() representative: CompanyRepresentative;

  form: FormGroup;
  showError = false;
  defaultEditUserErrorMessage = $localize`:@@base.error.defaultEditUserError: Oops! Something went wrong. Please try again. If the problem persists, <a class="link" href="mailto:merchants@zaver.se" target="_blank">contact&nbsp;us.</a>`;

  stores: Store[] = null;
  storeOptions: { value: string; label: string }[] = [
    {
      value: '',
      label: $localize`:@@editCompanyRepresentative.storeOptions.notConnected.label:Not connected`,
    },
  ];
  selectedStore = '';
  selectedRole: CompanyRepresentativeRole;

  loading = true;
  // inProgress = false;
  handleAllActivities = true;
  canSeeStores: boolean;
  showPhoneNumberInput = false;

  submitting = false;
  activating = false;
  inactivating = false;

  roleSelectionValues: { value: string; label: string }[];

  @Output() onUpdated = new EventEmitter();
  @Output() roleChanged = new EventEmitter<{
    roleText: string;
    roleColor: string;
  }>();

  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item = this.AVAILABLECOUNTRYCODES[4];
  formSubscription: Subscription;
  market: Market;

  constructor(
    private customizationService: CustomizationService,
    public access: AccessControlService,
    private storeService: StoreService,
    private appState: AppStateService,
    auth: AuthenticationService,
    private companyRepresentativeService: CompanyRepresentativeService,
    private toastService: ToastMessageService,
    public localeService: LocaleService
  ) {
    super(auth);
  }

  ngOnInit(): void {
    this.market = this.appState.getMarket();
    this.setup();
  }

  setup() {
    this.loading = true;
    this.selectedRole = this.representative.role;

    switch (this.market) {
      case Market.SE:
        this.showPhoneNumberInput = this.selectedRole.identified;
        break;
      case Market.DE:
      case Market.AT:
      case Market.NO:
        this.showPhoneNumberInput = true;
        break;
      default:
        break;
    }

    this.form = new FormGroup({});
    this.form.addControl(
      'email',
      new FormControl(this.representative.email, [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ])
    );
    if (this.market === Market.SE) {
      if (this.representative.personalNumber) {
        this.form.addControl(
          'personalNumber',
          new FormControl(this.representative.personalNumber, [
            Validators.required,
            swedishNationalIdentificationNumberValidator(),
            Validators.pattern(swedishNationalIdentificationRegex)
          ])
        );
        this.form.controls.personalNumber.disable();
      } else {
        this.form.addControl(
          'personalNumber',
          new FormControl('', [
            Validators.required,
            swedishNationalIdentificationNumberValidator(),
            Validators.pattern(swedishNationalIdentificationRegex)
          ])
        );
      }
    }
    switch (this.market) {
      case Market.SE:
        this.form.addControl(
          'phone',
          new FormControl(this.representative.phone || '', [
            Validators.required,
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      case Market.DE:
      case Market.AT:
        this.form.addControl(
          'phone',
          new FormControl(this.representative.phone || '+49 ', [
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      case Market.NO:
        this.form.addControl(
          'phone',
          new FormControl(this.representative.phone || '+47 ', [
            phoneNumberValidator(this.appState.getSupportedCountryCodes()),
          ])
        );
        break;
      default:
        break;
    }

    this.formSubscription = this.form.controls.phone.valueChanges.subscribe(
      (value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      }
    );

    if (this.representative.storeRelation) {
      this.selectedStore = this.representative.storeRelation.store.id;
      if (
        this.representative.storeRelation.paymentRequestPermission ===
        PaymentRequestPermission.MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE
      ) {
        this.handleAllActivities = false;
      } else {
        this.handleAllActivities = true;
      }
    }
    this.canSeeStoresCheck();
    this.setIdentificationType(this.selectedRole);

    this.roleSelectionValues = this.access
      .getAvailableRolesForEditing(this.representative)
      .map((merchantRole) => merchantRole.role)
      .map((role) => ({ label: role.displayName, value: role.id }));

    if (this.canSeeStores) {
      this.fetchStores();
    } else {
      this.loading = false;
    }
  }

  setCountry(selected) {
    this.form.controls.phone.setValue(selected.value);
  }

  setShowPhoneNumberInput() {
    switch (this.market) {
      case Market.SE:
        this.showPhoneNumberInput = this.selectedRole.identified;
        break;
      case Market.DE:
      case Market.AT:
      case Market.NO:
        this.showPhoneNumberInput = true;
        break;
      default:
        break;
    }
  }

  onRoleChanged(roleId: string) {
    this.errorMessage = '';
    this.selectedRole = this.access
      .getAvailableRolesForEditing(this.representative)
      .find((merchantRole) => merchantRole.role.id === roleId).role;
    this.setIdentificationType(this.selectedRole);
    this.setShowPhoneNumberInput();
    this.canSeeStoresCheck();
    if (this.canSeeStores) {
      this.fetchStores();
    }

    let colorInfo = CompanyRepresentationRoleColorRepresentations.get(
      this.selectedRole.color
    );
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    this.roleChanged.emit({
      roleText: this.selectedRole.displayName,
      roleColor: colorInfo.hexColor,
    });
  }

  onStoreChanged(store) {
    this.selectedStore = store;
  }

  getStoreAlias(): string {
    return this.customizationService.getStoreAlias(false, true, true, true);
  }

  setStoreOptions() {
    if (!this.stores) {
      return;
    }
    this.stores.forEach((obj) =>
      this.storeOptions.push({
        value: obj.id,
        label: obj.name,
      })
    );
  }

  canSeeStoresCheck() {
    this.canSeeStores =
      this.access.userMay(Permission.GET_STORES) &&
      this.appState.merchantHasStoreAccess() &&
      this.selectedRole.mayHaveStoreRelation;
  }

  clickedStorePermissionOption(handleAllActivities: boolean) {
    this.handleAllActivities = handleAllActivities;
  }

  setIdentificationType(role: CompanyRepresentativeRole) {
    if (this.appState.getMarket() === Market.SE) {
      if (role.identified) {
        if (!!this.representative.personalNumber) {
          this.form.controls.personalNumber.disable();
        } else {
          this.form.controls.personalNumber.enable();
        }
        this.form.controls.phone.enable();
      } else {
        this.form.controls.personalNumber.disable();
        this.form.controls.phone.disable();
      }
    }
  }

  submit() {
    this.errorMessage = '';
    if (this.form.valid) {
      this.submitting = true;
      let newRepresentative: CompanyRepresentative;
      if (this.selectedRole.identified) {
        let personalNumber: string = this.form.controls['personalNumber'].value;
        personalNumber = personalNumber.replace(/-/g, '');
        let email: string = this.form.controls['email'].value;
        email = email.trim();
        newRepresentative = new CompanyRepresentative(
          this.representative.id,
          personalNumber,
          formatPhoneNumber(this.form.controls['phone'].value),
          email,
          this.selectedRole
        );
      } else {
        let email: string = this.form.controls['email'].value;
        email = email.trim();
        newRepresentative = new CompanyRepresentative(
          this.representative.id,
          null,
          null,
          email,
          this.selectedRole
        );
      }
      this.setStoreRelation(newRepresentative);
      this.companyRepresentativeService
        .update(newRepresentative)
        .then(() => {
          this.submitting = false;
          this.toastService.displaySuccess(
            $localize`:@@editCompanyRepresentative.toast.update.success:The user has been updated`
          );
          this.onUpdated.emit();
        })
        .catch((error) => {
          this.handleError(error);
          this.submitting = false;
        });
    } else {
      this.showError = true;
    }
  }

  private setStoreRelation(newRepresentative: CompanyRepresentative) {
    if (this.canSeeStores) {
      const selectedStore = this.stores.find(
        (x) => x.id === this.selectedStore
      );
      let paymentRequestPermission: PaymentRequestPermission;
      if (this.handleAllActivities) {
        paymentRequestPermission =
          PaymentRequestPermission.MANAGE_ALL_PAYMENT_REQUESTS_FOR_STORE;
      } else {
        paymentRequestPermission =
          PaymentRequestPermission.MANAGE_OWN_PAYMENT_REQUESTS_FOR_STORE;
      }
      newRepresentative.storeRelation = new StoreRelation(
        selectedStore,
        paymentRequestPermission
      );
    }
  }

  inactivate() {
    this.inactivating = true;
    this.companyRepresentativeService
      .setInactive(this.representative.id)
      .then(() => {
        this.inactivating = false;
        this.toastService.displaySuccess(
          $localize`:@@editCompanyRepresentative.toast.inactivate.success:The user has been deactivated`
        );
        this.onUpdated.emit();
      })
      .catch((error) => {
        this.handleError(error);
        this.inactivating = false;
        this.toastService.displayError(
          $localize`:@@editCompanyRepresentative.toast.inactivate.fail:The user could not be deactivated`
        );
      });
  }

  activate() {
    this.activating = true;
    this.companyRepresentativeService
      .setActive(this.representative.id)
      .then(() => {
        this.activating = false;
        this.toastService.displaySuccess(
          $localize`:@@editCompanyRepresentative.toast.activate.success:The user has been activated`
        );
        this.onUpdated.emit();
      })
      .catch((error) => {
        this.handleError(error);
        this.activating = false;
        this.toastService.displayError(
          $localize`:@@editCompanyRepresentative.toast.activate.fail:The user could not be activated`
        );
      });
  }

  mayEditUser() {
    return (
      this.access.userMay(Permission.EDIT_USER) &&
      this.access.mayEditUser(this.representative)
    );
  }

  fetchStores(): void {
    this.storeService
      .get()
      .then((result) => {
        this.stores = result;
        this.setStoreOptions();
        this.loading = false;
      })
      .catch((error) => {
        this.handleError(error);
        this.loading = false;
      });
  }

  getPhoneErrorMessage(): string {
    const errors = this.form.controls.phone.errors;
    if (!errors) {
      return null;
    }
    if (!!errors['required']) {
      return $localize`:@@editCompanyRepresentative.form.phone.error.required:Please enter a mobile number`;
    } else if (!!errors['invalidFormat']) {
      return $localize`:@@editCompanyRepresentative.form.phone.error.invalidFormat:Please check the mobile number`;
    } else if (!!errors['invalidNumber']) {
      return $localize`:@@editCompanyRepresentative.form.phone.error.invalidNumber:Please check the mobile number`;
    } else if (!!errors['unsupportedCountryCode']) {
      return $localize`:@@editCompanyRepresentative.form.phone.error.unsupportedCountryCode:The specified country code is not supported`;
    }
    return null;
  }
}
