import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  LoginType,
} from '@core/service/authentication.service';
import { Router } from '@angular/router';
import { VerificationTokenService } from '@core/service/verification-token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { captureMessage } from '@sentry/angular';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['../login-pages.scss', './create-user.component.css'],
})
export class CreateUserComponent extends BaseComponent implements OnInit {
  private resetToken: any;
  form: FormGroup;

  formError = '';
  defaultFormError = $localize`:@@createUser.confirmPassword.error.default:Incorrect password`;

  processingLogin = false;
  validationErrors: string[];
  public email: string;
  showError = false;
  passwordError: string;
  confirmPasswordError: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private tokenService: VerificationTokenService,
    public localeService: LocaleService
  ) {
    super(authenticationService);
  }

  ngOnInit() {
    this.email = this.tokenService.email;
    this.resetToken = this.tokenService.getToken();
    this.form = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
        passwordConfirm: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
      },
      this.checkPasswords
    );
  }

  submit() {
    if (this.form.disabled || this.processingLogin) {
      return;
    }
    super.clearError();
    this.validationErrors = [];
    if (this.form.valid) {
      this.processingLogin = true;
      this.formError = null;
      this.showError = false;

      const password = this.form.value.password;

      this.authenticationService
        .createUser(this.resetToken, this.email, password)
        .then((response) => {
          switch (response) {
            case LoginType.PASSWORD:
              this.router.navigate(['/login']);
              break;
            case LoginType.PENDING_ADMIN_APPROVAL:
              this.router.navigate(['/pending-admin-approval']);
              break;
            default:
              captureMessage(
                'Unrecognized login type was returned from IDAU when auto-creating a user. LoginType: ' +
                  response
              );
              this.router.navigate(['/login']);
              break;
          }
        })
        .catch((error) => {
          this.processingLogin = false;
          const httpError: HttpErrorResponse = JSON.parse(
            JSON.stringify(error)
          );
          if (httpError.status === 400) {
            this.handleError(error);
          } else {
            super.handleError(error);
          }
        });
    } else {
      if (
        !this.form.controls['password'].value ||
        !this.form.controls['password'].valid
      ) {
        this.passwordError = $localize`:@@createUser.password.error.notValid:Enter a valid password`;
      } else {
        this.passwordError = null;
      }
      if (
        !this.form.controls['passwordConfirm'].value ||
        !this.form.controls['passwordConfirm'].valid
      ) {
        this.confirmPasswordError = $localize`:@@createUser.confirmPassword.error.notValid:Enter a valid password`;
      } else {
        this.confirmPasswordError = null;
      }
      if (this.passwordError || this.confirmPasswordError) {
        this.showError = true;
      } else {
        this.showError = false;
      }
      if (this.form.errors && !this.showError) {
        this.formError = this.form.errors.notSame
          ? $localize`:@@createUser.password.error.notSame:The passwords do not match. Try again.`
          : '';
      }
      this.processingLogin = false;
    }
  }

  handleError(error: Error) {
    const httpError: HttpErrorResponse = JSON.parse(JSON.stringify(error));
    if (httpError.status === 400) {
      this.validationErrors = httpError.error
        .filter(function (item, pos) {
          return httpError.error.indexOf(item) === pos;
        })
        .map((err) => this.getErrorDescription(err));
    } else {
      // should not happen, this should only handle validation errors sent as a list from the back end.
      return;
    }
  }

  getErrorDescription(message: string) {
    switch (message) {
      case 'TOO_SHORT':
        return $localize`:@@createUser.password.error.tooShort:The password must be at least 8 characters long`;
      case 'TOO_LONG':
        return $localize`:@@createUser.password.error.tooLong:The password may be a maximum of 30 characters`;
      case 'INSUFFICIENT_UPPERCASE':
        return $localize`:@@createUser.password.error.insufficientUppercase:The password must contain at least one capital letter`;
      case 'TOKEN_EXPIRED':
        return $localize`:@@createUser.token.error.tokenExpired:The verification code has become too old. Close the browser and try again.`;
      case 'TOKEN_INVALID':
        return $localize`:@@createUser.token.error.tokenInvalid:The verification code is invalid. Close the browser and try again.`;
    }
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true };
  }
}
