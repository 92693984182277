import { CashoutTaskStatus } from '@core/params';

export class CashoutTaskRepresentation {
  friendlyStatus: string;
  textColor: string;
  backgroundColor?: string;
}

export const CashoutTaskRepresentations: Map<string, CashoutTaskRepresentation> = new Map([
  [CashoutTaskStatus.PENDING_PAYEE_APPROVAL, {
    friendlyStatus: $localize`:@@cashoutStatus.PENDING_PAYEE_APPROVAL:Initiated`,
    textColor: '#4466EE',
    backgroundColor: '#DBE7FF'
  }],
  [CashoutTaskStatus.PENDING_ADMIN_APPROVAL, {
    friendlyStatus: $localize`:@@cashoutStatus.PENDING_ADMIN_APPROVAL:Ready for attestation`,
    textColor: '#DF5809',
    backgroundColor: '#FCEEE6'
  }],
  [CashoutTaskStatus.PENDING_EXECUTION, {
    friendlyStatus: $localize`:@@cashoutStatus.PENDING_EXECUTION:Confirm payout`,
    textColor: '#CC7A00',
    backgroundColor: '#FEF4E5'
  }],
  [CashoutTaskStatus.EXECUTED, {
    friendlyStatus: $localize`:@@cashoutStatus.EXECUTED:Paid out`,
    textColor: '#009773',
    backgroundColor: '#E5F5F2'
  }],
  [CashoutTaskStatus.BOUNCED, {
    friendlyStatus: $localize`:@@cashoutStatus.FAILED:Payout failed`,
    textColor: '#BD0F3D',
    backgroundColor: '#F8E7EC'
  }],
  [CashoutTaskStatus.FAILED, {
    friendlyStatus: $localize`:@@cashoutStatus.FAILED:Payout failed`,
    textColor: '#BD0F3D',
    backgroundColor: '#F8E7EC'

  }],
  [CashoutTaskStatus.CANCELLED, {
    friendlyStatus: $localize`:@@cashoutStatus.CANCELLED:Cancelled`,
    textColor: '#343B45',
    backgroundColor: '#E6E9EF'
  }],
  ['_DEFAULT_', {
    friendlyStatus: $localize`:@@cashoutStatus.DEFAULT:Being Processed`,
    textColor: '#393939',
  }],
]);
