import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LocaleOption } from '../../../../web-component-library/projects/component-library/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public getCurrentLocale(): string {
    return this.locale;
  }

  public setCurrentLocale(newLocale: string): void {
    this.locale = newLocale;
  }

  public getCurrentLocaleBaseCode(): string {
    switch (this.locale) {
      case 'en-US':
        return 'en';
      case 'sv':
        return 'se';
      case 'de':
        return 'de';
      case 'de-AT':
        return 'at';
      default:
        console.error(
          'Unknown locale not supported in Zaver for business: ',
          this.locale
        );
        return;
    }
  }

  public getLocales(): LocaleOption[] {
    return [
      {
        text: 'English (US)',
        locale: 'en-US',
        flagLink: 'assets/flags/us.svg',
        subPath: 'en',
      },
      {
        text: 'Deutsch',
        locale: 'de',
        flagLink: 'assets/flags/de.svg',
        subPath: 'de',
      },
      {
        text: 'Deutsch',
        locale: 'de-AT',
        flagLink: 'assets/flags/at.svg',
        subPath: 'at',
      },
      {
        text: 'Swedish',
        locale: 'sv',
        flagLink: 'assets/flags/se.svg',
        subPath: 'se',
      },
    ]
  }

  public getLocaleRegistrationLink(): string {
    switch (this.locale) {
      case 'en-US':
        return 'https://zaver.com/en/get-started';
      case 'sv':
        return 'https://zaver.com/se/boka-demo';
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/los-gehts';
      default:
        console.error(
          'Unknown locale not supported in Zaver for business: ',
          this.locale
        );
        return 'https://zaver.com/se/boka-demo';
    }
  }
}
